export const AuthWrapper = {
  flexDirection: { xs: 'column', md: 'row' },
  flexWrap: 'nowrap',
  gap: { xs: 4, md: 0 },
  p: { xs: '0px 24px', md: 0 },
}

export const FormWrapper = {
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  gap: 5,
  zIndex: 5,
  p: { xs: '74px 0px 50px', md: '70px 0px' },
  width: { xs: '100%', sm: '100%', md: '55%', lg: '45%' },
}

export const AuthInputError = {
  padding: '5px 0px 0px 10px',
  position: 'absolute',
  color: '#d32f2f',
  fontSize: '14px',
}

export const AuthPhotoWrapper = {
  backgroundColor: { xs: 'white', md: '#F7F4FF' },
  width: { xs: '45%', md: '60%', lg: '55%' },
  p: { xs: '0px', md: '70px 0px 80px' },
  justifyContent: 'center',
  display: { xs: 'none', md: 'flex' },
  height: 'inherit',
}

export const LogInText = {
  color: 'var(--main)',
  textDecoration: 'underline',
  cursor: 'pointer',
  fontWeight: 600,
}

export const AuthButton = {
  mt: '25px',
  backgroundColor: 'var(--black)',
  p: '14px 35px',
  fontWeight: 600,
  width: '100%',
  '&:hover': {
    background: 'var(--main)',
  },
}

export const AuthFieldsWrapper = {
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  width: {
    xs: '100%',
    sm: '100%',
    md: '380px',
    lg: '410px',
  },
  gap: 2.5,
  textAlign: 'left',
}
