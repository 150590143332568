import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import { DateFieldPropTypes } from '../utils/types'

import { Label } from './EditField/EditFieldStyles'

import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

export const DateField = ({ value, label, handleChange }: DateFieldPropTypes): JSX.Element => {
  return (
    <Grid container gap={0.6}>
      <Typography variant="body2" sx={Label}>
        {label}
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          inputFormat="MM/DD/YYYY"
          value={value}
          onChange={handleChange}
          renderInput={(params) => <TextField fullWidth {...params} />}
        />
      </LocalizationProvider>
    </Grid>
  )
}
