export const ResetPasswordWrapper = {
  p: '48px 24px 32px',
  height: { xs: '70vh', md: '100vh' },
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  display: 'flex',
}

export const ResetPasswordContent = {
  width: { xs: '100%', sm: '55%', md: '40%', lg: '30%' },
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: 3,
  textAlign: 'center',
}
