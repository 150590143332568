import { Link } from 'react-router-dom'

import useDeviceStore from 'store/deviceDetect.store'

import './index.scss'

import Breadcrumbs from '@mui/material/Breadcrumbs'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'

interface PropsType {
  firsTitle: string
  firstLocation: string
  secondTitle: string
  firstIcon: any
  secondIcon: any
}

export const BreadCrumbs = ({
  firsTitle,
  firstLocation,
  secondTitle,
  firstIcon,
  secondIcon,
}: PropsType): JSX.Element => {
  const { isMobile } = useDeviceStore()

  const breadcrumbs = [
    {
      link: `/${firstLocation}`,
      icon: firstIcon,
      title: firsTitle,
      LinkClassName: 'bread-crumbs-text-container',
      TextClassName: 'text',
    },
    {
      link: '/',
      icon: secondIcon,
      title: secondTitle,
      LinkClassName: 'bread-crumbs-text-container',
      TextClassName: 'text',
    },
  ]

  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize={isMobile ? 'small' : 'medium'} sx={{ color: 'var(--main)' }} />}
      aria-label="breadcrumb"
      sx={{ p: '0px', height: '100%' }}
    >
      {breadcrumbs.map(({ link, icon, title, LinkClassName, TextClassName }, index: number) => (
        <Link to={link} className={LinkClassName} key={`${index}${firsTitle}`}>
          {icon}
          <span className={TextClassName}>{title}</span>
        </Link>
      ))}
    </Breadcrumbs>
  )
}
