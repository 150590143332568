import { useEffect, useState } from 'react'
import { CounterTimerPropTypes } from '../utils/types'

export const CounterTimer = ({ finishValue, time, defaultValue }: CounterTimerPropTypes): JSX.Element => {
  const [currentValue, setCurrentValue] = useState<number>(defaultValue)

  useEffect(() => {
    currentValue !== finishValue && setTimeout(setCurrentValue, time, currentValue + 1)
  }, [currentValue, finishValue, time])
  return <>{currentValue}</>
}
