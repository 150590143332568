import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode, Navigation, Autoplay } from 'swiper'

import { CourseCard } from '../CourseCard'

import './CourseSlider.scss'
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'

import Grid from '@mui/material/Grid'

export const CourseSlider = ({ courseTypes }: any): JSX.Element => {
  return (
    <>
      {Array.isArray(courseTypes) && (
        <Swiper
          freeMode={true}
          navigation={true}
          modules={[FreeMode, Navigation, Autoplay]}
          className="mySwiper custom-slide"
          autoplay={{
            delay: 6000,
            disableOnInteraction: false,
          }}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            480: {
              slidesPerView: 1.2,
            },
            540: {
              slidesPerView: 1.5,
            },
            720: {
              slidesPerView: 1.7,
            },
            860: {
              slidesPerView: 2.2,
            },
            1100: {
              slidesPerView: 2.4,
            },
            1200: {
              slidesPerView: 2.5,
            },
            1280: {
              slidesPerView: 3,
            },
            1350: {
              slidesPerView: 3.2,
            },
            1550: {
              slidesPerView: 3.6,
            },
            1750: {
              slidesPerView: 4.2,
            },
          }}
        >
          {courseTypes.map((item, index: number) => {
            return (
              <SwiperSlide key={index} className="custom-slide">
                <Grid sx={{ margin: '20px' }}>
                  <CourseCard courseTypes={item} />
                </Grid>
              </SwiperSlide>
            )
          })}
        </Swiper>
      )}
    </>
  )
}
