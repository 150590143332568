import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import { CardActionArea } from '@mui/material'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'

export const TestCard = ({ image, level, title }: any): JSX.Element => {
  return (
    <Grid container flexDirection="column" gap={2}>
      <Card sx={{ width: '100%' }}>
        <CardActionArea>
          <CardContent
            style={{
              display: 'flex',
              gap: '25px',
              alignItems: 'center',
              padding: '24px',
            }}
          >
            <img src={image} alt={image} width="50px" />
            <Grid container gap={1} flexWrap="wrap">
              <Typography variant="h6">{title}</Typography>
              <Typography variant="h6" color="var(--main)">
                {level}
              </Typography>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  )
}
