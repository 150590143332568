import { ButtonProps } from 'types/button'

import './index.scss'

export const GradientButton = ({ onClick, title }: ButtonProps): JSX.Element => {
  return (
    <button className="gradient-btn" onClick={onClick}>
      {title}
    </button>
  )
}
