import { AvatarPropTypes } from 'utils/types'

import { ImageStyle } from './AvatarStyles'

import { BASE_AWS_URL } from 'utils/constants'

export const Avatar = ({ handleUserAvatar, url, alt, id, active }: AvatarPropTypes): JSX.Element => {
  return (
    <span style={{ border: `3px solid ${active ? '#2e7d32' : 'var(--main)'}`, borderRadius: '50px' }}>
      <img src={`${BASE_AWS_URL}${url}`} alt={alt} style={ImageStyle} onClick={() => handleUserAvatar(id)} />
    </span>
  )
}
