import { create } from 'zustand'

import { PopUpStoreProps } from 'utils/types'

const useStore = create<PopUpStoreProps>((set) => ({
  modal: false,
  isShow: false,
  courseModal: false,
  handleSetShowPopUp: (isShow: boolean) => set({ isShow }),
  handleSetModal: () => set((state) => ({ modal: !state.modal })),
  handleSetCourseModal: (courseModal: boolean) => set({ courseModal }),
}))

export default useStore
