import { QuestionAccordion } from '../QuestionAccordion'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

export const FrequentlyQuestions = (): JSX.Element => {
  return (
    <Grid container flexDirection="column" gap={4} sx={{ p: { xs: '0px 24px', md: '0px 120px' } }}>
      <Grid>
        <Typography variant="h1" gutterBottom>
          Найчастіші
          <span style={{ color: 'var(--main)' }}> запитання </span>
        </Typography>
        <Typography variant="h6" color="var(--black)">
          Тут ви зможете знайти відповіді на свої запитання, або просто зателефонуйте нам і ми радо відповімо
          на кожне 😊
        </Typography>
      </Grid>
      <QuestionAccordion />
    </Grid>
  )
}
