import check from 'assets/LanguageTest/checkmark.png'

import './index.scss'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

export const SelectLanguageItem = ({
  name,
  value,
  icon,
  title,
  handleChange,
  state,
  disabled,
}: any): JSX.Element => {
  return (
    <Grid className="language-wrapper" onClick={() => !disabled && handleChange(name, value)}>
      <Grid className="language-info-block">
        <img src={icon} alt="language icon" className="language-icon" />
        <Typography variant="h6" fontWeight={600}>
          {title}
        </Typography>
      </Grid>
      {state === value && <img src={check} className="check-icon" alt="checked-icon" />}
    </Grid>
  )
}
