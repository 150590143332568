import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

export const TermsAndPolicy = ({ title, text }: any): JSX.Element => {
  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="center"
      flexDirection="column"
      sx={{ py: '50px', minHeight: '100vh' }}
    >
      <Typography variant="h6" color="var(--black)" lineHeight="30px" gutterBottom fontWeight={600}>
        {title}
      </Typography>
      <Grid width="90%" container flexDirection="column" gap={2}>
        <Typography variant="body2" color="var(--black)">
          Ваша конфіденційність дуже важлива для нас. Ми хочемо, щоб Ваша робота в Інтернет по можливості була
          максимально приємною і корисною, і Ви абсолютно спокійно використовували широкий спектр інформації,
          інструментів і можливостей, які пропонує Інтернет. Особиста інформація Членів, зібрана при
          реєстрації (або в будь-який інший час) переважно використовується для підготовки Продуктів або
          Послуг у відповідності з Вашими потребами. Ваша інформація не буде передана або продана стороннім
          сторонам. Однак, ми можемо частково розкривати особисту інформацію в особливих випадках, описаних в
          "Згідно з розсилкою".
        </Typography>
        <Typography variant="h6" color="var(--black)" lineHeight="30px" gutterBottom fontWeight={600}>
          Які дані збираються на сайті
        </Typography>
        <Typography variant="body2" color="var(--black)">
          При добровільній реєстрації Ви відправляєте своє Ім'я, телефон та E-mail через форму реєстрації.
        </Typography>
        <Typography variant="h6" color="var(--black)" lineHeight="30px" gutterBottom fontWeight={600}>
          З якою метою збираються ці дані
        </Typography>
        <Typography variant="body2" color="var(--black)">
          Ім'я використовується для звернення особисто до Вас, а ваш e-mail та телефон – для того,щоб належно
          протестувати Ваш рівень і зв’язатися з Вами для подальшої співпраці, а також для відправки Вам
          розсилок листів, новин, корисних матеріалів, комерційних пропозицій. Ваші ім'я, телефон та e-mail не
          передаються третім особам, ні за яких умовах крім випадків, пов'язаних з виконанням вимог
          законодавства. Ваше ім'я, телефон і e-mail на захищених серверах сервісу getresponse.com і
          використовуються згідно з його політикою конфіденційності. Ви можете відмовитися від отримання
          листів розсилки і видалити з бази даних свої контактні дані у будь-який момент, натиснувши на
          посилання для відписки, що є в кожному листі.
        </Typography>
        <Typography variant="h6" color="var(--black)" lineHeight="30px" gutterBottom fontWeight={600}>
          Як ці дані використовуються
        </Typography>
        <Typography variant="body2" color="var(--black)">
          На сайті leader-language-school.com використовуються куки (Cookies) і дані про користувачів сервісу
          Google Analytics. За допомогою цих даних збирається інформація про дії відвідувачів на сайті з метою
          покращення його змісту, покращення функціональних можливостей сайту і, як наслідок, створення
          якісного контенту і сервісів для відвідувачів. Ви можете в будь-який момент змінити налаштування
          свого браузера так, щоб браузер блокував усі файли cookie або оповіщав про відправку цих файлів.
          Врахуйте при цьому, що деякі функції та сервіси не зможуть працювати належним чином.
        </Typography>
        <Typography variant="h6" color="var(--black)" lineHeight="30px" gutterBottom fontWeight={600}>
          Ці дані захищено
        </Typography>
        <Typography variant="body2" color="var(--black)">
          Для захисту Вашої особистої інформації ми використовуємо різноманітні адміністративні, управлінські
          та технічні заходи безпеки. Наша Компанія дотримується різних міжнародних стандартів контролю,
          спрямованих на операції з особистою інформацією, які включають певні заходи контролю щодо захисту
          інформації, зібраної в Інтернет. Наших співробітників навчають розуміти і виконувати ці заходи
          контролю, вони ознайомлені з нашим Повідомленням про конфіденційність, нормами та інструкціями. Тим
          не менш, незважаючи на те, що ми прагнемо захистити Вашу особисту інформацію, Ви теж повинні вживати
          заходів, щоб захистити її. Ми наполегливо рекомендуємо Вам приймати всі можливі заходи безпеки під
          час перебування в Інтернеті. Організовані нами послуги і веб-сайти передбачають заходи щодо захисту
          від несанкціонованого використання та зміни інформації, яку ми контролюємо. Незважаючи на те, що ми
          робимо все можливе, щоб забезпечити цілісність і безпеку своєї мережі і систем, ми не можемо
          гарантувати, що наші заходи безпеки застрахують незаконний доступ до цієї інформації хакерів
          сторонніх організацій. У разі зміни даної політики конфіденційності Ви зможете прочитати про ці
          зміни на цій сторінці або, в особливих випадках, отримати повідомлення на свій e-mail. Для зв'язку з
          адміністратором сайту з будь-яких питань Ви можете написати листа на e-mail:
          leaderschooluz@gmail.com
        </Typography>
      </Grid>
    </Grid>
  )
}
