import { useState } from 'react'

import * as Yup from 'yup'
import { useMutation } from 'react-query'
import { Formik, Form, FormikErrors, FormikTouched } from 'formik'

import { EditField } from 'components/EditField'
import { circleLoading, closeIcon, doneIcon } from 'components/ContactUsForm/styles'

import { FormState } from 'utils/types'
import { InputChangeEvent } from 'types/common'

import useNotificationStore from 'store/notification.store'

import userApi from 'api/userApi'

import { DeleteProfile, FormWrapper } from '../UserEdit/styles'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Close, Done } from '@mui/icons-material'
import CircularProgress from '@mui/material/CircularProgress'

const initialValues = {
  oldPassword: '',
  newPassword: '',
}

export const PasswordChange = (): JSX.Element => {
  const { handleSetBanner, handleSetMessage } = useNotificationStore()

  const [userData, setUserData] = useState<any>(initialValues)
  const [formState, setFormState] = useState<FormState>({
    buttonState: '',
    errors: {},
  })

  const { mutate: changePassword } = useMutation(userApi.changePassword, {
    onSuccess: ({ data }) => {
      handleSetMessage(data.message)
      handleSetBanner(true)
      setUserData(initialValues)
    },
    onError: () => {
      handleSetBanner(true)
      handleSetMessage('Введіть коректний пароль')
    },
  })

  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required("Обов'язкове поле для заповнення"),
    newPassword: Yup.string()
      .required("Обов'язкове поле для заповнення")
      .min(8, 'Пароль має містити мінімум 8 символів')
      .matches(/[0-9]/, 'Пароль має містити хоча б 1 цифру')
      .matches(/[a-z]/, 'Пароль має містити хоча б 1 літеру з великої букви')
      .matches(/[A-Z]/, 'Пароль має містити хоча б 1 літеру з малої букви'),
  })

  const handleChange = (event: InputChangeEvent): void => {
    const { name, value } = event.target
    setUserData({ ...userData, [name]: value })
  }

  const handlePasswordReset = (): void => {}

  const handleClick = (errors: FormikErrors<any>, touched: FormikTouched<any>): void => {
    if (Object.keys(errors).length > 0 || Object.keys(touched).length === 0)
      setFormState({ ...formState, buttonState: 'error' })
    setTimeout(() => {
      setFormState({ ...formState, buttonState: '' })
    }, 3500)
  }

  return (
    <Grid>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={() => changePassword(userData)}
      >
        {({ errors, touched, setFieldValue, setFieldTouched }) => (
          <Form>
            <Grid sx={{ ...FormWrapper, gap: '15px' }}>
              <EditField
                name="oldPassword"
                label="Теперішній пароль"
                formValue={userData.oldPassword}
                onChange={handleChange}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
              />

              <EditField
                name="newPassword"
                label="Новий пароль"
                formValue={userData.newPassword}
                onChange={handleChange}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
              />

              <Grid container justifyContent="center" sx={{ mb: '10px', mt: '10px' }}>
                <button
                  type="submit"
                  style={{ padding: '0px 20px', width: '210px' }}
                  className={formState.buttonState === 'error' ? 'send-button error' : 'send-button'}
                  onClick={() => handleClick(errors, touched)}
                >
                  {formState.buttonState === '' && `Оновити інформацію`}
                  {formState.buttonState === 'loading' && <CircularProgress size={24} sx={circleLoading} />}
                  {formState.buttonState === 'error' && <Close sx={closeIcon} />}
                  {formState.buttonState === 'success' && <Done sx={doneIcon} />}
                </button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <Typography variant="body2" sx={DeleteProfile} onClick={handlePasswordReset}>
        Відновити пароль
      </Typography>
    </Grid>
  )
}
