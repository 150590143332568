import { apiClient, authClient } from './apiClient'
import ENDPOINTS from './endpoints'

const endpoint = ENDPOINTS.USER
const authEndpoint = ENDPOINTS.AUTH

const getUserDetails = (id: string): any => apiClient.get(`${endpoint}/${id}`).then((res) => res.data)

const updateUser = ({ userId, userData }): Promise<any> =>
  apiClient.put(`${endpoint}/${userId}/update`, userData).then((res) => res)

const deleteUser = (id: string): any => apiClient.delete(`${endpoint}/${id}/delete`).then((res) => res)

const createUser = (userData: any): any =>
  authClient.post(`${authEndpoint}/register`, { ...userData }).then((res) => res)

const changePassword = (userData: any): any =>
  apiClient.post(`${authEndpoint}/password/change`, { ...userData }).then((res) => res)

const userLogin = (userData: any): any =>
  authClient.post(`${authEndpoint}/login`, { ...userData }).then((res) => res)

const googleLogin = (userToken: any): any =>
  authClient.post(`${authEndpoint}/loginGoogle`, { userToken }).then((res) => res)

const forgotPassword = (email: string): any =>
  authClient.post(`${authEndpoint}/password/forgot`, { email }).then((res) => res)

const sendPasswordRequest = ({ token, newPassword }): any =>
  authClient
    .put(`${authEndpoint}/password/forgot`, {
      token,
      newPassword,
    })
    .then((res) => res)

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getUserDetails,
  updateUser,
  deleteUser,
  createUser,
  changePassword,
  userLogin,
  googleLogin,
  forgotPassword,
  sendPasswordRequest,
}
