import { QuizTest } from 'components/QuizTest'
import { SelectLanguageItem } from '../components/SelectLanguageItem'

import de from 'assets/LanguageTest/germany.png'
import eng from 'assets/LanguageTest/english.png'
import man from 'assets/LanguageTest/languageTestImage.jpg'
import firstPlace from 'assets/LanguageTest/firstPlace.png'
import secondPlace from 'assets/LanguageTest/secondPlace.png'

import {
  DesktopCardWrapper,
  DesktopLeftSideWrapper,
  DesktopRightSideWrapper,
  DesktopTestButton,
} from '../LanguageTestStyles'
import '../LanguageTest.scss'

import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

export const DesktopLanguageTest = ({
  startTest,
  handleChange,
  handleStartTest,
  language,
  level,
  startButtonStatus,
}: any): JSX.Element => {
  return (
    <>
      {!startTest && (
        <Grid sx={DesktopLeftSideWrapper}>
          <img src={man} alt="img" className="language-test-img" />
          <Grid>
            <Typography variant="h1" gutterBottom>
              Всього кілька хвилин і визначимо ваш <span style={{ color: '#DF623A' }}> рівень </span>
            </Typography>
            <Typography variant="h6">
              Спочатку оберіть мову (англійську чи німецьку), а далі свій приблизний рівень, щоб вже точніше
              його визначити за допомогою тесту. А після цього можна домовлятися про ідеальний для вас варіант
              навчання. Успіхів на тесті!
            </Typography>
          </Grid>
        </Grid>
      )}

      <Grid sx={DesktopRightSideWrapper} style={{ width: startTest && '100%' }}>
        {!startTest ? (
          <Grid container flexDirection="column" gap={4} justifyContent="center" alignItems="center">
            <Grid width="100%" container gap={3.2} textAlign="start">
              <Typography variant="h3" fontWeight={500} fontSize="20px">
                Оберіть мову, будь ласка
              </Typography>
              <SelectLanguageItem
                name="language"
                value="eng"
                icon={eng}
                title="English"
                state={language}
                handleChange={handleChange}
              />
              <SelectLanguageItem
                name="language"
                value="de"
                icon={de}
                title="Deutsch"
                state={language}
                handleChange={handleChange}
              />
            </Grid>

            {language && (
              <Grid width="100%" container gap={3.2} textAlign="start">
                <Typography variant="h3" fontWeight={500} fontSize="20px">
                  Оберіть приблизний рівень (Elementary - ПОЧАТКОВИЙ; Intermediate - СЕРЕДНІЙ)
                </Typography>
                <SelectLanguageItem
                  name="level"
                  value="elementary"
                  icon={secondPlace}
                  title="Elementary"
                  state={level}
                  handleChange={handleChange}
                />
                {language !== 'de' && (
                  <SelectLanguageItem
                    name="level"
                    value="intermediate"
                    icon={firstPlace}
                    title="Intermediate"
                    state={level}
                    handleChange={handleChange}
                    language={language}
                    disabled={language === 'de'}
                  />
                )}
              </Grid>
            )}
            <Button
              disabled={startButtonStatus}
              variant="outlined"
              sx={DesktopTestButton}
              size="medium"
              onClick={handleStartTest}
            >
              Почати тестування
            </Button>
          </Grid>
        ) : (
          <Grid sx={DesktopCardWrapper}>
            <QuizTest language={language} level={level} />
          </Grid>
        )}
      </Grid>
    </>
  )
}
