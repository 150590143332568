import { CircularProgress, Divider, Grid, TextField, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { EMAIL_JS_PUBLIC_KEY, EMAIL_JS_SERVICE_ID, EMAIL_JS_TEMPLATE_ID } from 'utils/constants'
import { ContactUsDataPropTypes, FormState } from 'utils/types'
import {
  ContactsFormWrapper,
  ContactsUserContainer,
  ContactsUserInfo,
  ContactsBackgroundWrapper,
} from './ContactsStyles'
import * as Yup from 'yup'
import { Formik, Form, Field, FormikTouched, FormikErrors } from 'formik'
import { circleLoading, closeIcon, doneIcon, tableImage } from 'components/ContactUsForm/styles'
import { Close, Done } from '@mui/icons-material'
import { FormField } from 'components/FormField'
import { FiPhone, FiAtSign, FiMapPin } from 'react-icons/fi'
import Table from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import { CustomMetaTitle } from 'utils/customMetaTitle'
import useNotificationStore from 'store/notification.store'
import { handleSendFeedback } from 'api/emailApi'
import { InputChangeEvent } from 'types/common'

const initialValues = {
  email: '',
  phone: '',
  message: '',
  name: '',
}

export const Contacts = (): JSX.Element => {
  const [userData, setUserData] = useState<ContactUsDataPropTypes>(initialValues)
  const [formState, setFormState] = useState<FormState>({
    buttonState: '',
    errors: {},
  })

  const { handleSetMessage, handleSetBanner } = useNotificationStore()

  const handlerChange = (event: InputChangeEvent): void => {
    const { name, value } = event.target
    setUserData({ ...userData, [name]: value })
  }

  const onSubmit = () => {
    setFormState({ ...formState, buttonState: 'loading' })
    handleSendFeedback(EMAIL_JS_SERVICE_ID, EMAIL_JS_TEMPLATE_ID, userData, EMAIL_JS_PUBLIC_KEY)
      .then((): void => {
        setFormState({ ...formState, buttonState: 'success' })
        setUserData(initialValues)
        handleSetBanner(true)
        handleSetMessage('Дякуємо за ваше повідомлення ❤️')
      })
      .catch(() => {
        setFormState({ ...formState, buttonState: 'error' })
      })
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Обов'язкове поле"),
    phone: Yup.string(),
    email: Yup.string().email('Введіть правильний email').required("Обов'язкове поле"),
    message: Yup.string().required("Обов'язкове поле"),
  })

  const handleClick = (
    errors: FormikErrors<ContactUsDataPropTypes>,
    touched: FormikTouched<ContactUsDataPropTypes>
  ) => {
    if (Object.keys(errors).length > 0 || Object.keys(touched).length === 0)
      setFormState({ ...formState, buttonState: 'error' })
    setTimeout(() => {
      setFormState({ ...formState, buttonState: '' })
    }, 3000)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    CustomMetaTitle('LS • Контакти')
  }, [])

  return (
    <Grid
      className="background-wrapper"
      sx={ContactsBackgroundWrapper}
      container
      justifyContent="center"
      alignItems="center"
    >
      <Grid sx={ContactsFormWrapper}>
        <Grid sx={ContactsUserContainer}>
          <Typography variant="h1" sx={{ fontSize: { xs: '32px', md: '38px' } }} color="var(--black)">
            Запитайте нас. <br />
            <span style={{ color: 'var(--main)' }}> Заповніть форму. </span>
          </Typography>
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ errors, touched, setFieldValue, setFieldTouched }) => (
              <Form>
                <Grid container gap={3}>
                  <Grid container flexWrap={{ xs: 'wrap', sm: 'nowrap' }} gap={{ xs: 3, sm: 5 }}>
                    <FormField
                      name="name"
                      label="Ім'я"
                      variant="standard"
                      formValue={userData.name}
                      onChange={handlerChange}
                      setFieldTouched={setFieldTouched}
                      setFieldValue={setFieldValue}
                    />
                    <FormField
                      name="phone"
                      label="Номер телефону"
                      variant="standard"
                      formValue={userData.phone}
                      onChange={handlerChange}
                      setFieldTouched={setFieldTouched}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                  <FormField
                    name="email"
                    label="Email"
                    variant="standard"
                    formValue={userData.email}
                    onChange={handlerChange}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                  />
                  <Field name="message">
                    {({ meta }) => (
                      <Grid sx={{ width: '100%' }}>
                        <TextField
                          variant="standard"
                          label="Повідомлення"
                          multiline
                          value={userData.message}
                          rows={5}
                          name="message"
                          sx={{ width: '100%' }}
                          onBlur={() => setFieldTouched('message', true)}
                          error={!!meta.error && !!meta.touched}
                          onChange={(event: InputChangeEvent) => {
                            handlerChange(event)
                            setFieldValue('message', event.target.value)
                          }}
                        />
                      </Grid>
                    )}
                  </Field>
                  <Grid
                    container
                    justifyContent={{
                      xs: 'center',
                      sx: 'flex-start',
                      md: 'flex-start',
                      lg: 'flex-start',
                    }}
                  >
                    <button
                      type="submit"
                      className={formState.buttonState === 'error' ? 'send-button error' : 'send-button'}
                      onClick={() => handleClick(errors, touched)}
                    >
                      {formState.buttonState === '' && 'Надіслати'}
                      {formState.buttonState === 'loading' && (
                        <CircularProgress size={24} sx={circleLoading} />
                      )}
                      {formState.buttonState === 'error' && <Close sx={closeIcon} />}
                      {formState.buttonState === 'success' && <Done sx={doneIcon} />}
                    </button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
        <Divider orientation="vertical" variant="middle" flexItem sx={{ opacity: 0.5 }} />
        <Grid sx={ContactsUserInfo}>
          <Grid container gap={2} sx={{ pl: '16px' }}>
            <Typography variant="h3" color="var(--black)">
              Потрібна наша допомога?
            </Typography>
            <Typography variant="h6" color="var(--black)">
              Напишіть нам чи зателефонуйте, і ми радо про все детально розповімо 🤗
            </Typography>
          </Grid>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  <Grid style={{ width: '32px', height: '32px' }} sx={tableImage}>
                    <FiAtSign color="var(--main)" size="30px" />
                  </Grid>
                </TableCell>
                <TableCell align="left" sx={{ px: 0 }}>
                  <Typography variant="h6" color="var(--black)">
                    leaderschooluz@gmail.com
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row" style={{ width: '28px' }}>
                  <Grid
                    sx={tableImage}
                    style={{
                      width: '28px',
                      height: '28px',
                      textAlign: 'center',
                    }}
                  >
                    <FiPhone color="var(--main)" size="28px" />
                  </Grid>
                </TableCell>
                <TableCell align="left" sx={{ px: 0 }}>
                  <Typography variant="h6" color="var(--black)">
                    +380 (95) 380 41 60
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  <Grid sx={tableImage} style={{ marginRight: '11px' }}>
                    <FiMapPin color="var(--main)" size="30px" />
                  </Grid>
                </TableCell>
                <TableCell align="left" sx={{ px: 0 }}>
                  <Typography variant="h6" color="var(--black)">
                    вул. Льва Толстого, 3в <br /> Ужгород, Україна 🇺🇦
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </Grid>
  )
}
