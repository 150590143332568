import { useNavigate } from 'react-router-dom'

import { authUser } from 'utils/userService'

import { GradientButton } from 'components/GradientButton'

import {
  BlobImage,
  ImageWrapper,
  TextContainer,
  HowToStartWrapper,
  TitleTextContainer,
  HowToStartTextContainer,
} from './HowToStartStyles'

import blob from 'assets/HowToStart/blob.svg'
import saly from 'assets/HowToStart/saly.svg'

import './index.scss'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

export const HowToStart = (): JSX.Element => {
  const navigate = useNavigate()

  const handleGetStart = (url: string): void => {
    navigate(url)
  }

  return (
    <Grid container sx={HowToStartWrapper}>
      <Grid container sx={ImageWrapper}>
        <Grid sx={BlobImage}>
          <img src={blob} alt="blob" />
        </Grid>
        <img src={saly} alt="blob" className="hts-icon" />
      </Grid>

      <Grid container sx={TitleTextContainer}>
        <Typography variant="h1" color="white">
          А далі все дуже<span style={{ color: 'var(--main)' }}> просто </span>
        </Typography>
        <Typography variant="h6" color="var(--white)">
          Ось покрокова інструкція, як приєднатися до нашої студентської ком‘юніті 🤩
        </Typography>
      </Grid>

      <Grid container sx={TextContainer}>
        <Grid sx={HowToStartTextContainer}>
          <Grid container alignItems="flex-start" gap={1} flexDirection="column">
            <Typography variant="h3" color="var(--main)">
              • Оберіть підходящий для себе курс 🇺🇸 або 🇩🇪
            </Typography>
            <Typography variant="h6" color="var(--white)">
              чи то індивідуальні, чи групові заняття, для дорослого, або дитини, онлайн чи наживо в м.
              Ужгород
            </Typography>
          </Grid>
        </Grid>

        <Grid sx={HowToStartTextContainer}>
          <Grid container alignItems="flex-start" gap={1} flexDirection="column">
            <Typography variant="h3" color="var(--main)">
              • Вкажіть свій приблизний рівень
            </Typography>
            <Typography variant="h6" color="var(--white)">
              Оберіть його відповідно до міжнародних стандартів CEFR. <br />А ще краще - визначте його за
              допомогою нашого простого тесту
            </Typography>
          </Grid>
        </Grid>

        <Grid sx={HowToStartTextContainer}>
          <Grid container alignItems="flex-start" gap={1} flexDirection="column">
            <Typography variant="h3" color="var(--main)">
              • Зареєструйтеся на нашому сайті
            </Typography>
            <Typography variant="h6" color="var(--white)">
              Щоб пройти тест для визначення рівня потрібно швиденько зареєструватися, щоб ми могли зв’язатися
              з вами для того, щоб провести усну частину тесту (максимум 10хв)
            </Typography>
          </Grid>
        </Grid>

        <Grid sx={HowToStartTextContainer}>
          <Grid container alignItems="flex-start" gap={1} flexDirection="column">
            <Typography variant="h3" color="var(--main)">
              • І на кінець ми домовляємося про найбільш підходящий варіант навчання та розклад
            </Typography>
            <Typography variant="h6" color="var(--white)">
              І ваша захоплююча подорож до вільного володіння іноземною розпочинається 🎊
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <GradientButton
            onClick={() => handleGetStart(authUser() ? 'courses' : 'sign-up')}
            title={authUser() ? 'До курсів' : 'Зареєструватись'}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
