import { UserAvatar } from 'components/UserAvatar'
import robot from 'assets/robot.svg'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

export const DesktopUserInfo = ({ userDetails }: any): JSX.Element => {
  return (
    <Grid
      container
      sx={{
        height: '270px',
        width: '100%',
        borderRadius: '20px',
        backgroundColor: 'var(--black)',
        opacity: 1,
        backgroundImage:
          'repeating-radial-gradient( circle at 0 0, transparent 0, #1c252b 50px ), repeating-linear-gradient( #232e3555, #232e35 )',
        p: '0px 64px 0px 64px',
        alignItems: 'flex-end',
        mb: '70px',
      }}
    >
      <Grid container flexWrap="nowrap" gap={3} alignItems="center" sx={{ mb: '-70px' }}>
        <UserAvatar userName={userDetails?.name} avatar={userDetails?.avatar} size="large" />
        <Grid container flexDirection="column" gap={3}>
          <Typography variant="h5" fontSize="24px" color="var(--white)">
            {userDetails?.name}
          </Typography>
          <Typography variant="h5" fontSize="18px" color="var(--main)">
            {userDetails?.email}
          </Typography>
        </Grid>
      </Grid>
      <Grid width="80%" container justifyContent="flex-end" sx={{ position: 'absolute', mb: '-135px' }}>
        <img src={robot} alt="robot" />
      </Grid>
    </Grid>
  )
}
