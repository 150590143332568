import { useMemo } from 'react'
import { useLocation, Link } from 'react-router-dom'
import useDeviceStore from 'store/deviceDetect.store'

import { NavLinkProps } from 'types/header'

import './index.scss'

import ListItem from '@mui/material/ListItem'

export const NavLink = ({ to, children, onClick = () => {} }: NavLinkProps): JSX.Element => {
  const location = useLocation()
  const { isMobile } = useDeviceStore()

  const isActive = useMemo(() => location.pathname === to, [location.pathname, to])
  const mobileStyles = useMemo(
    () => (isMobile && isActive ? 'mobile-active' : isActive ? 'active' : ''),
    [isActive, isMobile]
  )

  return (
    <ListItem className={`${isMobile ? 'mobile-link' : 'link'} ${mobileStyles}`} onClick={onClick}>
      <Link to={to}>{children}</Link>
    </ListItem>
  )
}
