import { create } from 'zustand'

import { UserPropTypes } from '../utils/types'

export const initialUserState = {
  avatar: '',
  birthday: '',
  createdAt: '',
  email: '',
  languageLevel: {},
  name: '',
  password: '',
  phone: 0,
  role: '',
  __v: 0,
  _id: '',
}

interface UserStore {
  userDetails: any
  handleSetUserDetails: (userDetails: UserPropTypes) => void
}

const useStore = create<UserStore>((set) => ({
  userDetails: {},
  handleSetUserDetails: (userDetails) => set({ userDetails }),
}))

export default useStore
