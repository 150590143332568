import ArrowRight from 'icons/ArrowRight'

import { InfoPaperProps } from 'types/courseDetails'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'

export const InfoPaper = ({ title, infoData }: InfoPaperProps): JSX.Element => {
  return (
    <>
      {infoData && (
        <Paper
          sx={{
            p: 3,
            borderRadius: '16px',
            width: { xs: '100%', lg: '44%', xl: '45%' },
            filter: 'drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.08))',
          }}
        >
          <Typography variant="body1" gutterBottom>
            {title}
          </Typography>
          {infoData.map((text: string, index: number) => {
            return (
              <Grid container gap={1} flexWrap="nowrap" key={index}>
                <Box width="2%" sx={{ mt: '4px' }}>
                  <ArrowRight />
                </Box>
                <Box width="95%">
                  <Typography variant="h6" sx={{ mb: '10px' }}>
                    {text}
                  </Typography>
                </Box>
              </Grid>
            )
          })}
        </Paper>
      )}
    </>
  )
}
