import { useEffect } from 'react'
import { Link } from 'react-router-dom'

import { CustomMetaTitle } from 'utils/customMetaTitle'

import notFoundImage from 'assets/NotFound/notFound.jpg'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

export const NotFound = (): JSX.Element => {
  useEffect(() => {
    window.scrollTo(0, 0)
    CustomMetaTitle('404')
  }, [])

  return (
    <Grid container flexDirection="column" justifyContent="center" alignItems="center" height="100vh" gap={2}>
      <img src={notFoundImage} alt="notFoundImage" style={{ width: '300px' }} />
      <Typography variant="h1" color="var(--main)" lineHeight="30px">
        404
      </Typography>
      <Typography variant="h3" color="var(--black)" lineHeight="30px">
        Сторінку не знайдено
      </Typography>
      <Link to="/">Головна сторінка</Link>
    </Grid>
  )
}
