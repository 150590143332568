export const CourseTypeWrapper = {
  p: { xs: '0px', md: '0px 80px 0px', lg: '0px 100px 0px' },
  gap: '20px',
  justifyContent: 'center',
  alignItems: 'center',
}

export const CourseTypeButton = {
  p: '12px 30px',
  height: '100%',
  borderRadius: '26px',
  transition: 'transform 0.2s ease-in-out',
  background:
    'linear-gradient(white, white) padding-box, linear-gradient(to right, #529bdb, var(--main)) border-box',
  border: '2px solid transparent',
  color: '#574ec8',

  ':hover': {
    transition: 'transform 0.2s ease-in-out',
    transform: 'scale(1.04, 1.04)',
    border: '2px solid transparent',
  },
}
