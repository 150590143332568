import { useNavigate } from 'react-router-dom'

import useDeviceStore from 'store/deviceDetect.store'

import { authUser } from 'utils/userService'
import { FaStar } from 'react-icons/fa'

import Grid from '@mui/material/Grid'
import Rating from '@mui/material/Rating'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import { CounterTimer } from 'components/CounterTimer'

export const InfoBar = (): JSX.Element => {
  const navigate = useNavigate()
  const { isMobile } = useDeviceStore()

  const handleStartButton = (url: string): void => {
    navigate(url)
  }

  return (
    <Grid
      width="100%"
      container
      justifyContent={{ xs: 'space-between', md: 'space-evenly' }}
      alignItems="center"
      textAlign="center"
      flexWrap="nowrap"
      sx={{
        backgroundColor: 'white',
        boxShadow: '0 4px 30px rgba(0, 0, 0, 0.2)',
        borderRadius: '14px',
        p: { xs: '14px', sm: '18px' },
        maxWidth: isMobile ? '450px' : '670px',
      }}
    >
      <Grid width={{ xs: '27%', md: '15%' }} container flexDirection="column" gap={{ xs: 0.5, sm: 1 }}>
        <Typography variant="h6" color="var(--light-gray)" fontSize={{ xs: '15px', sm: '16px' }}>
          Cтудентів
        </Typography>
        <Typography variant="h5">
          {<CounterTimer finishValue={2500} time={50} defaultValue={2480} />} +
        </Typography>
      </Grid>
      <Divider orientation="vertical" variant="middle" flexItem sx={{ opacity: 0.3 }} />
      <Grid
        width={{ xs: '45%', md: '25%' }}
        container
        flexDirection="column"
        gap={{ xs: 0.5, sm: 1 }}
        alignItems="center"
      >
        <Typography variant="h6" color="var(--light-gray)" fontSize={{ xs: '15px', sm: '16px' }}>
          Оцінка Google
        </Typography>
        <a
          href="https://www.google.com/search?q=%D0%BC%D0%BE%D0%B2%D0%BD%D0%B0+%D1%88%D0%BA%D0%BE%D0%BB%D0%B0+%22%D0%BB%D1%96%D0%B4%D0%B5%D1%80%22&oq=%D0%BC%D0%BE%D0%B2%D0%BD%D0%B0+%D1%88%D0%BA&aqs=chrome.1.69i57j35i39j0i512j46i175i199i512j0i512l2j69i60j69i61.4794j0j7&sourceid=chrome&ie=UTF-8#lrd=0x473919b6edecd51d:0xcc4babbe16405e16,1,,,,"
          target="_blank"
          rel="noreferrer"
        >
          <Rating
            value={5}
            readOnly
            size="medium"
            precision={0.5}
            defaultValue={5}
            sx={{
              fontSize: '1.30rem',
            }}
            icon={<FaStar style={{ opacity: 1, padding: '0px 3px' }} />}
            emptyIcon={<FaStar style={{ opacity: 0.5, padding: '0px 3px' }} />}
          />
        </a>
      </Grid>
      <Divider orientation="vertical" variant="middle" flexItem sx={{ opacity: 0.3 }} />
      <Grid width={{ xs: '20%', md: '15%' }} container flexDirection="column" gap={{ xs: 0.5, sm: 1 }}>
        <Typography variant="h6" fontSize={{ xs: '15px', sm: '16px' }} color="var(--light-gray)">
          Років
        </Typography>
        <Typography variant="h5">{<CounterTimer finishValue={6} time={80} defaultValue={0} />} +</Typography>
      </Grid>
      {!isMobile && (
        <Grid>
          <Button
            variant="contained"
            sx={{ backgroundColor: 'var(--black)', p: '12px 35px' }}
            size="medium"
            onClick={() => handleStartButton(authUser() ? 'courses' : 'sign-up')}
          >
            {authUser() ? 'До курсів' : 'Почати'}
          </Button>
        </Grid>
      )}
    </Grid>
  )
}
