import { CourseCard } from '../../../CourseType/CourseCard'

import Grid from '@mui/material/Grid'

export const TabSection = ({ courseData }): JSX.Element => {
  return (
    <>
      {courseData?.map((item, index: number) => {
        return (
          <Grid key={index} item xs={12} sm={6} md={4} lg={4}>
            <CourseCard courseTypes={item} key={index} />
          </Grid>
        )
      })}
    </>
  )
}
