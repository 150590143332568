export const SuccessWindowWrapper = {
  padding: 0,
  boxShadow: 'none',
  width: '100%',
  borderRadius: '16px',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
}
