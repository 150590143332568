import { useCallback, useMemo } from 'react'
import classnames from 'classnames'

import { UserAvatarProps } from 'types/profile'

import './index.scss'
import { DefaultAvatar } from 'components/DefaultAvatar'

export const UserAvatar = ({ avatar, userName, size }: UserAvatarProps): JSX.Element => {
  const imageSize = useMemo(
    () => ({
      small: '120',
      medium: '256',
      large: '500',
      comment: '',
    }),
    []
  )

  const changeImageUrlSize = useCallback((url: string, newSize: string): string => {
    if (url?.includes('google')) {
      const size = newSize ? `=s${newSize}` : ''
      const newUrl = url.replace(/=.*/, size)
      return newUrl
    }
    return url
  }, [])

  const userImage = useMemo(
    () =>
      avatar?.includes('google') ? (
        <img
          alt="user-avatar"
          crossOrigin="anonymous"
          className={classnames('user-avatar', {
            [size]: [size],
          })}
          src={changeImageUrlSize(avatar, imageSize[size])}
        />
      ) : (
        <img
          alt="user-avatar"
          className={classnames('user-avatar', {
            [size]: size,
          })}
          src={avatar}
        />
      ),
    [avatar, changeImageUrlSize, imageSize, size]
  )

  return (
    <span
      className={classnames('avatar-border', {
        [`${size}-border`]: `${size}-border`,
      })}
    >
      {avatar ? userImage : <DefaultAvatar name={userName?.slice(0, 1)} size={size} />}
    </span>
  )
}
