import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useMutation } from 'react-query'
import { Formik, Form, Field } from 'formik'

import { InputChangeEvent } from 'types/common'
import useNotificationStore from 'store/notification.store'

import ENDPOINTS from 'api/endpoints'
import testApi from 'api/testApi'

import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

const initialValues = {
  task: '',
  question: '',
  correct_answer: '',
  incorrect_answers: [],
  language: '',
  level: '',
}

export const AddTestQuestion = (): JSX.Element => {
  const { handleSetMessage, handleSetBanner } = useNotificationStore()

  const [incorrectAnswers, setIncorrectAnswers] = useState('')
  const [testData, setTestData] = useState<any>(initialValues)

  const { mutate: createTestQuestion } = useMutation(ENDPOINTS.TEST, testApi.createTestQuestion, {
    onSuccess: ({ data }) => {
      setTestData(initialValues)
      setIncorrectAnswers('')
      handleSetBanner(true)
      handleSetMessage(data.message)
    },
    onError: ({ response }) => {
      handleSetBanner(true)
      handleSetMessage(response.data.message)
    },
  })

  const validationSchema = Yup.object().shape({
    task: Yup.string().required("Обов'язкове поле"),
    question: Yup.string().required("Обов'язкове поле"),
    correct_answer: Yup.string().required("Обов'язкове поле"),
    language: Yup.string().required("Обов'язкове поле"),
    level: Yup.string().required("Обов'язкове поле"),
  })

  const handlerChange = (event: InputChangeEvent): void => {
    const { name, value } = event.target

    setTestData({ ...testData, [name]: value })
  }

  const handleAddIncorrectAnswers = (): void => {
    setTestData({ ...testData, incorrect_answers: [...testData.incorrect_answers, incorrectAnswers] })
    setIncorrectAnswers('')
  }

  const handleIncorrectAnswers = (event: InputChangeEvent): void => {
    const { value } = event.target
    setIncorrectAnswers(value)
  }

  const onSubmit = () => {
    createTestQuestion(testData)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Grid
      container
      alignItems="center"
      sx={{ p: '48px 24px' }}
      justifyContent="center"
      flexDirection="column"
      gap={3}
    >
      <Typography variant="h1" sx={{ fontSize: { xs: '32px', md: '38px' } }} color="var(--black)">
        Додавання питання до тесту
      </Typography>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ setFieldValue, setFieldTouched }) => (
          <Form>
            <Grid container gap={2} flexDirection="column">
              <Field name="task">
                {({ meta }) => (
                  <Grid sx={{ width: '350px' }}>
                    <TextField
                      sx={{ width: '100%' }}
                      variant="standard"
                      label="task"
                      name="task"
                      onBlur={() => setFieldTouched('task', true)}
                      value={testData.task}
                      error={!!meta.error && !!meta.touched}
                      onChange={(event: InputChangeEvent) => {
                        handlerChange(event)
                        setFieldValue('task', event.target.value)
                      }}
                    />
                    <Grid>{!!meta.error && !!meta.touched ? meta.error : ''}</Grid>
                  </Grid>
                )}
              </Field>
              <Field name="level">
                {({ meta }) => (
                  <Grid>
                    <TextField
                      sx={{ width: '100%' }}
                      variant="standard"
                      label="level"
                      name="level"
                      onBlur={() => setFieldTouched('level', true)}
                      value={testData.level}
                      error={!!meta.error && !!meta.touched}
                      onChange={(event: InputChangeEvent) => {
                        handlerChange(event)
                        setFieldValue('level', event.target.value)
                      }}
                    />
                    <Grid>{!!meta.error && !!meta.touched ? meta.error : ''}</Grid>
                  </Grid>
                )}
              </Field>
              <Field name="question">
                {({ meta }) => (
                  <Grid>
                    <TextField
                      sx={{ width: '100%' }}
                      variant="standard"
                      label="question"
                      name="question"
                      onBlur={() => setFieldTouched('question', true)}
                      value={testData.question}
                      error={!!meta.error && !!meta.touched}
                      onChange={(event: InputChangeEvent) => {
                        handlerChange(event)
                        setFieldValue('question', event.target.value)
                      }}
                    />
                    <Grid>{!!meta.error && !!meta.touched ? meta.error : ''}</Grid>
                  </Grid>
                )}
              </Field>
              <Field name="language">
                {({ meta }) => (
                  <Grid>
                    <TextField
                      sx={{ width: '100%' }}
                      variant="standard"
                      label="language"
                      name="language"
                      onBlur={() => setFieldTouched('language', true)}
                      value={testData.language}
                      error={!!meta.error && !!meta.touched}
                      onChange={(event: InputChangeEvent) => {
                        handlerChange(event)
                        setFieldValue('language', event.target.value)
                      }}
                    />
                    <Grid>{!!meta.error && !!meta.touched ? meta.error : ''}</Grid>
                  </Grid>
                )}
              </Field>
              <Field name="correct_answery">
                {({ meta }) => (
                  <Grid>
                    <TextField
                      sx={{ width: '100%' }}
                      variant="standard"
                      label="correct_answer"
                      name="correct_answer"
                      onBlur={() => setFieldTouched('correct_answer', true)}
                      value={testData.correct_answer}
                      error={!!meta.error && !!meta.touched}
                      onChange={(event: InputChangeEvent) => {
                        handlerChange(event)
                        setFieldValue('correct_answer', event.target.value)
                      }}
                    />
                    <Grid>{!!meta.error && !!meta.touched ? meta.error : ''}</Grid>
                  </Grid>
                )}
              </Field>

              <Grid container flexWrap="nowrap">
                <Field name="incorrect_answers">
                  {({ meta }) => (
                    <Grid width="100%">
                      <TextField
                        sx={{ width: '100%' }}
                        variant="standard"
                        label="incorrect_answers"
                        name="incorrect_answers"
                        onBlur={() => setFieldTouched('incorrect_answers', true)}
                        value={incorrectAnswers}
                        error={!!meta.error && !!meta.touched}
                        onChange={(event: InputChangeEvent) => {
                          handleIncorrectAnswers(event)
                          setFieldValue('incorrect_answers', event.target.value)
                        }}
                      />
                      <Grid>{!!meta.error && !!meta.touched ? meta.error : ''}</Grid>
                    </Grid>
                  )}
                </Field>
                <button type="button" onClick={handleAddIncorrectAnswers}>
                  add
                </button>
              </Grid>
            </Grid>
            <Grid width="100%" container justifyContent="center" sx={{ p: '20px 0px' }}>
              <Button variant="outlined" type="submit" sx={{ p: '8px 32px' }}>
                Відправити
              </Button>
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  )
}
