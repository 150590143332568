import { UploadImagePropTypes } from '../../utils/types'
import './index.scss'

import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary'
import { Grid } from '@mui/material'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'

export const UploadImage = ({
  handleAddAvatar,
  deleteUploadAvatar,
  active,
}: UploadImagePropTypes): JSX.Element => {
  return (
    <>
      <Grid className="upload-wrapper" sx={{ backgroundColor: active ? '#b5d1c4' : 'var(--main)' }}>
        {active && (
          <HighlightOffIcon
            onClick={deleteUploadAvatar}
            sx={{ position: 'absolute', top: 0, right: 3, width: '28px', height: '28px', color: 'red' }}
          />
        )}
        <input
          name="avatar"
          type="file"
          className="upload-input"
          onChange={(event) => {
            handleAddAvatar(event)
          }}
        />
        {active ? (
          <TaskAltIcon sx={{ color: 'white' }} fontSize="large" />
        ) : (
          <PhotoLibraryIcon sx={{ color: 'white' }} fontSize="large" />
        )}
      </Grid>
    </>
  )
}
