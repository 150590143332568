import { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { FeedbackCardPage } from './FeedbackCard'

import { UserAvatar } from 'components/UserAvatar'

import useUserStore from 'store/user.store'
import { FeedbackCardProps } from 'types/feedbacks'

import { FaStar } from 'react-icons/fa'
import './FeedbackCard.scss'

import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Rating from '@mui/material/Rating'
import Typography from '@mui/material/Typography'
import DeleteSweepRoundedIcon from '@mui/icons-material/DeleteSweepRounded'

export const FeedbackCard = ({
  data,
  handleCommentDelete,
  activeSlide,
  index,
}: FeedbackCardProps): JSX.Element => {
  const { userDetails } = useUserStore()
  const [showComment, setShowComment] = useState(false)
  const { authorName, rating, comment, _id, authorAvatar, authorId } = data
  const handleDeleteComment = userDetails?.role === 'admin' || userDetails?._id === authorId
  const { pathname } = useLocation()
  const id = pathname.split('/')[1]

  useEffect(() => {
    if (showComment && index) {
      if (activeSlide !== index) setShowComment(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSlide])

  const handleShowFullComment = useCallback(() => {
    setShowComment(comment.length > 96 ? !showComment : false)
  }, [comment.length, showComment])

  return (
    <>
      {data && (
        <Paper sx={{ ...FeedbackCardPage, margin: id === 'feedbacks' ? 0 : '20px' }}>
          <Grid container flexDirection="column">
            <Grid container flexWrap="nowrap" gap={1.2} alignItems="center">
              <UserAvatar userName={authorName} avatar={authorAvatar} size="comment" />
              <Grid container flexDirection="column" textAlign="start" gap="4px">
                <Typography variant="h6" fontWeight="600" lineHeight="16px">
                  {authorName}
                </Typography>
                <Rating
                  value={rating}
                  readOnly
                  size="medium"
                  defaultValue={5}
                  sx={{
                    fontSize: '1.25rem',
                    marginLeft: '-2px',
                  }}
                  icon={<FaStar style={{ opacity: 1, padding: '0px 2px' }} fontSize="inherit" />}
                  emptyIcon={<FaStar style={{ opacity: 0.5, padding: '0px 2px' }} fontSize="inherit" />}
                />
              </Grid>
              {handleDeleteComment && (
                <DeleteSweepRoundedIcon
                  sx={{ color: '#d32f2f', width: '30px', height: '30px' }}
                  onClick={() => handleCommentDelete(_id)}
                />
              )}
            </Grid>
          </Grid>
          <Typography
            variant="h6"
            component="div"
            color="#515257"
            fontSize="15px"
            onClick={handleShowFullComment}
            className={showComment ? 'feedback-card-text-open' : 'feedback-card-text'}
          >
            {comment}
          </Typography>
        </Paper>
      )}
    </>
  )
}
