import { useEffect, useState } from 'react'

import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'

import * as Yup from 'yup'
import { Formik, Form } from 'formik'

import { FormField } from 'components/FormField'

import useLoaderStoreStore from 'store/loader.store'
import useNotificationStore from 'store/notification.store'

import { InputChangeEvent } from 'types/common'
import { BASE_FRONTEND_URL } from 'utils/constants'
import { ResetPasswordInitialState } from 'utils/InitialValues'

import userApi from 'api/userApi'
import { handleSendPasswordToken } from 'api/emailApi'

import '../NewPassword/index.scss'
import { AuthButton } from '../Auth/AuthStyles'
import { ResetPasswordContent, ResetPasswordWrapper } from './ResetPasswordStyles'

import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

export const ResetPassword = (): JSX.Element => {
  const navigate = useNavigate()
  const { handleSetLoading } = useLoaderStoreStore()
  const { handleSetBanner, handleSetMessage } = useNotificationStore()

  const [userData, setUserData] = useState<any>(ResetPasswordInitialState)

  const { mutate: forgotPassword } = useMutation(userApi.forgotPassword, {
    onSuccess: ({ data }) => {
      handleSendPasswordToken(
        'service_n5z5r8y',
        'template_lzvwd58',
        {
          url: `${BASE_FRONTEND_URL}/user-token/${data.token}`,
          email: userData.email,
        },
        'zyoS4xzeXDanqd6Kt'
      )
        .then(() => {
          handleSetMessage('Повідомлення відправлено на пошту')
          handleSetBanner(true)
          navigate('/')
        })
        .catch(({ response }) => {
          handleSetBanner(true)
          handleSetMessage(response.text)
        })
        .finally(() => handleSetLoading(false))
    },
    onError: ({ response }) => {
      handleSetBanner(true)
      handleSetMessage(response.data.message)
      handleSetLoading(false)
    },
  })

  const handleChange = (event: InputChangeEvent): void => {
    const { name, value } = event.target
    setUserData({ [name]: value })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Введіть валідний email').required("Обов'язкове поле"),
  })

  return (
    <Grid sx={ResetPasswordWrapper}>
      <Grid sx={ResetPasswordContent}>
        <Typography variant="h5" fontWeight={500}>
          Hello 👋, Введіть ваш email і ми надішлемо вам лист для відновлення пароля
        </Typography>

        <Formik
          onSubmit={() => forgotPassword(userData.email)}
          validationSchema={validationSchema}
          initialValues={ResetPasswordInitialState}
        >
          {({ setFieldValue, setFieldTouched }) => (
            <Form className="password-form-wrapper">
              <FormField
                variant="outlined"
                name="email"
                label="Email"
                formValue={userData.email}
                onChange={handleChange}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
              />
              <Button variant="contained" sx={{ ...AuthButton, mt: 0 }} size="medium" type="submit">
                Відправити
              </Button>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  )
}
