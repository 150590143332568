export const FeedbackCardPage = {
  p: '30px 20px',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  borderRadius: '18px',
  minWidth: '320px',
  boxShadow: '0px 0px 15px 10px rgb(28, 37, 43, 0.035)',
  cursor: 'pointer',
  transition: 'transform 0.2s ease-in-out',
  '& img': {
    width: '50px !important',
  },
}
