import { InfoBarProps } from 'types/courseDetails'

import { FaStar } from 'react-icons/fa'

import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'

export const InfoBar = ({ rating, location }: InfoBarProps): JSX.Element => {
  return (
    <Paper
      sx={{
        borderRadius: '14px',
        boxSizing: 'border-box',
        p: { xs: '14px', sm: '18px' },
        maxWidth: '500px',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        textAlign: 'center',
        flexWrap: 'nowrap',
        filter: 'drop-shadow(0px 0px 20px rgb(166, 140, 241, 0.09))',
      }}
    >
      <Grid
        width={{ xs: '27%', md: '25%' }}
        container
        flexDirection="column"
        gap={{ xs: 0.3, sm: 1 }}
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h6" color="var(--light-gray)" fontSize={{ xs: '14px', sm: '16px' }}>
          Рейтинг
        </Typography>
        <Grid container alignItems="center" justifyContent="center" gap={0.5}>
          <Typography variant="h5" fontSize={{ xs: '15px' }}>
            {rating}.0
          </Typography>
          <FaStar style={{ opacity: 1, color: 'var(--gold)', marginBottom: '2px' }} size={18} />
        </Grid>
      </Grid>
      <Divider orientation="vertical" variant="middle" flexItem style={{ opacity: 0.3 }} />
      <Grid width={{ xs: '45%', md: '15%' }} container flexDirection="column" gap={{ xs: 0.5, sm: 1 }}>
        <Typography variant="h6" color="var(--light-gray)" fontSize={{ xs: '14px', sm: '16px' }}>
          Локація
        </Typography>
        <Grid container alignItems="center" justifyContent="center" gap={0.5}>
          <Typography variant="h5" fontSize={{ xs: '15px' }}>
            {location}
          </Typography>
        </Grid>
      </Grid>
      <Divider orientation="vertical" variant="middle" flexItem style={{ opacity: 0.3 }} />

      <Grid width={{ xs: '27%', md: '15%' }} container flexDirection="column" gap={{ xs: 0.5, sm: 1 }}>
        <Typography variant="h6" fontSize={{ xs: '15px', sm: '16px' }} color="var(--light-gray)">
          Тип
        </Typography>
        <Grid container alignItems="center" justifyContent="center" gap={0.5}>
          <Typography variant="h5" fontSize={{ xs: '15px' }}>
            Офлайн
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  )
}
