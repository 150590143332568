import { useState } from 'react'
import { MenuContent } from './MenuContent'
import logo from 'assets/Logo/logo.svg'

import './index.scss'

import Grid from '@mui/material/Grid'
import { Link } from 'react-router-dom'

export const MobileHeader = (): JSX.Element => {
  const [isOpen, setOpen] = useState<boolean>(false)

  const handleIsOpen = (): void => {
    setOpen(!isOpen)
  }

  return (
    <Grid className="wrapper">
      <nav>
        <Grid className="burger-menu">
          <Grid className={isOpen ? 'nav-icon open' : 'nav-icon'} onClick={handleIsOpen}>
            <span></span>
            <span></span>
            <span></span>
          </Grid>
        </Grid>
        <Link to="/" style={{ textDecoration: 'none', height: '34px', marginTop: '8px', userSelect: 'none' }}>
          <img src={logo} alt="logo" style={{ height: '34px' }} />
        </Link>
      </nav>
      <MenuContent open={isOpen} setOpen={setOpen} />
    </Grid>
  )
}
