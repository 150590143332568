import { useState } from 'react'

import * as Yup from 'yup'
import { Formik, Form, Field, FormikTouched, FormikErrors } from 'formik'

import { FormField } from '../FormField'
import useNotificationStore from 'store/notification.store'

import { InputChangeEvent } from 'types/common'
import { FormState, ContactUsDataPropTypes } from 'utils/types'
import { EMAIL_JS_SERVICE_ID, EMAIL_JS_PUBLIC_KEY, EMAIL_JS_TEMPLATE_ID } from 'utils/constants'

import { handleSendFeedback } from 'api/emailApi'

import {
  doneIcon,
  closeIcon,
  tableImage,
  circleLoading,
  ContactUsWrapper,
  ContactUsFieldsWrapper,
  ContactUsFormContainer,
  ContactUsInfoContainer,
} from './styles'
import './ContactUsForm.scss'
import man from 'assets/VisitUs/handsImage.jpg'

import { FiPhone, FiAtSign, FiMapPin } from 'react-icons/fi'

import Grid from '@mui/material/Grid'
import Table from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TextField from '@mui/material/TextField'
import { Done, Close } from '@mui/icons-material'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'

const initialValues = {
  email: '',
  phone: '',
  message: '',
  name: '',
}

export const ContactUsForm = (): JSX.Element => {
  const [userData, setUserData] = useState<ContactUsDataPropTypes>(initialValues)
  const [formState, setFormState] = useState<FormState>({
    buttonState: '',
    errors: {},
  })

  const { handleSetMessage, handleSetBanner } = useNotificationStore()

  const handlerChange = (event: InputChangeEvent): void => {
    const { name, value } = event.target
    setUserData({ ...userData, [name]: value })
  }

  const onSubmit = (): void => {
    setFormState({ ...formState, buttonState: 'loading' })
    handleSendFeedback(EMAIL_JS_SERVICE_ID, EMAIL_JS_TEMPLATE_ID, userData, EMAIL_JS_PUBLIC_KEY)
      .then(({ status }): void => {
        if (status === 200) {
          setFormState({ ...formState, buttonState: 'success' })
          setUserData(initialValues)
        } else {
          setFormState({ ...formState, buttonState: 'error' })
        }
        handleSetBanner(true)
        handleSetMessage('Дякуємо за ваше повідомлення ❤️')
      })
      .catch(() => {
        setFormState({ ...formState, buttonState: 'error' })
      })
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Обов'язкове поле"),
    phone: Yup.string(),
    email: Yup.string().email('Введіть правильний email').required("Обов'язкове поле"),
    message: Yup.string().required("Обов'язкове поле"),
  })

  const handleClick = (
    errors: FormikErrors<ContactUsDataPropTypes>,
    touched: FormikTouched<ContactUsDataPropTypes>
  ) => {
    if (Object.keys(errors).length > 0 || Object.keys(touched).length === 0)
      setFormState({ ...formState, buttonState: 'error' })
    setTimeout(() => {
      setFormState({ ...formState, buttonState: '' })
    }, 3000)
  }

  return (
    <Grid sx={ContactUsWrapper}>
      <Grid sx={ContactUsFormContainer}>
        <Typography variant="h5" color="#252525">
          Місце для ваших запитань, пропозицій і побажань 😉
        </Typography>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
          {({ errors, touched, setFieldValue, setFieldTouched }) => (
            <Form>
              <Grid container sx={ContactUsFieldsWrapper}>
                <FormField
                  name="name"
                  label="Ім'я"
                  variant="standard"
                  formValue={userData.name}
                  onChange={handlerChange}
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                />
                <FormField
                  name="email"
                  label="Email"
                  variant="standard"
                  formValue={userData.email}
                  onChange={handlerChange}
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                />
                <FormField
                  name="phone"
                  label="Номер телефону"
                  variant="standard"
                  formValue={userData.phone}
                  onChange={handlerChange}
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                />
                <Field name="message">
                  {({ meta }) => (
                    <Grid sx={{ width: '100%' }}>
                      <TextField
                        variant="standard"
                        label="Повідомлення"
                        multiline
                        value={userData.message}
                        rows={5}
                        name="message"
                        sx={{ width: '100%' }}
                        onBlur={() => setFieldTouched('message', true)}
                        error={!!meta.error && !!meta.touched}
                        onChange={(event: InputChangeEvent) => {
                          handlerChange(event)
                          setFieldValue('message', event.target.value)
                        }}
                      />
                    </Grid>
                  )}
                </Field>
                <Grid
                  container
                  justifyContent={{
                    xs: 'center',
                    sx: 'flex-start',
                    md: 'flex-start',
                    lg: 'flex-start',
                  }}
                >
                  <button
                    type="submit"
                    className={formState.buttonState === 'error' ? 'send-button error' : 'send-button'}
                    onClick={() => handleClick(errors, touched)}
                  >
                    {formState.buttonState === '' && 'Надіслати'}
                    {formState.buttonState === 'loading' && <CircularProgress size={24} sx={circleLoading} />}
                    {formState.buttonState === 'error' && <Close sx={closeIcon} />}
                    {formState.buttonState === 'success' && <Done sx={doneIcon} />}
                  </button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
      <Grid sx={ContactUsInfoContainer}>
        <img src={man} alt="man" style={{ width: '250px' }} />
        <Table>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                <Grid style={{ width: '32px', height: '32px' }} sx={tableImage}>
                  <FiAtSign color="var(--main)" size="30px" />
                </Grid>
              </TableCell>
              <TableCell align="left" sx={{ px: 0 }}>
                <Typography variant="h6" color="var(--black)">
                  leaderschooluz@gmail.com
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row" style={{ width: '28px' }}>
                <Grid
                  sx={tableImage}
                  style={{
                    width: '28px',
                    height: '28px',
                    textAlign: 'center',
                  }}
                >
                  <FiPhone color="var(--main)" size="28px" />
                </Grid>
              </TableCell>
              <TableCell align="left" sx={{ px: 0 }}>
                <Typography variant="h6" color="var(--black)">
                  +380 (95) 380 41 60
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                <Grid sx={tableImage} style={{ marginRight: '11px' }}>
                  <FiMapPin color="var(--main)" size="30px" />
                </Grid>
              </TableCell>
              <TableCell align="left" sx={{ px: 0 }}>
                <Typography variant="h6" color="var(--black)">
                  вул. Льва Толстого, 3в <br /> Ужгород, Україна 🇺🇦
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  )
}
