import Typewriter from 'typewriter-effect'

import { InfoBar } from './InfoBar'
import useDeviceStore from 'store/deviceDetect.store'

import backgroundPhoto from 'assets/WelcomeScreen/Saly.jpg'

import './WelcomeScreen.scss'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

export const WelcomeScreen = (): JSX.Element => {
  const { isMobile, isTablet } = useDeviceStore()
  return (
    <Grid
      height="100vh"
      width="100%"
      sx={{
        // backgroundColor: 'var(--main)',
        backgroundColor: '#a68cf1',
        opacity: 1,
        // backgroundImage:
        //   'repeating-radial-gradient( circle at 0 0, transparent 0, #a68cf1 40px ), repeating-linear-gradient( #c8b6ff55, #c8b6ff )',
        p: isMobile ? '30px 25px 0px' : '0px 100px',
        position: 'relative',
        overflow: 'hidden',
      }}
      container
      flexDirection="column"
      justifyContent="center"
    >
      <Grid
        container
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
        gap={{ xs: 5, md: 8 }}
        sx={{ zIndex: 10, mt: '-78px' }}
      >
        <Grid container flexDirection="column" gap={{ xs: 2, sm: 3 }} width={isMobile ? '100%' : '60%'}>
          <Grid className="title-container">
            <Typewriter
              options={{
                delay: 60,
                autoStart: true,
                strings: 'Привіт, ви потрапили за адресою',
              }}
            />
          </Grid>
          {!isMobile && !isTablet ? (
            <>
              <Typography variant="h5" color="white" lineHeight="30px">
                Якщо шукаєте рецепт успішного вивчення іноземної, або просто хочете її ефективно «прокачати»
                💪🏻
              </Typography>
              <Typography variant="h5" color="white" lineHeight="30px">
                В нас ви знайдете відповідний курс з англійської та німецької для себе або свого чада 😇
              </Typography>
            </>
          ) : (
            <Typography variant="h5" color="white" lineHeight="30px">
              Якщо шукаєте рецепт успішного вивчення англійської або німецької! Ласкаво просимо на наш курс ❤️
            </Typography>
          )}
        </Grid>
        <Grid width="100%" container justifyContent={{ xs: 'center', sm: 'flex-start' }}>
          <InfoBar />
        </Grid>
      </Grid>
      <Grid
        sx={{
          position: 'absolute',
          right: { xs: -57, sm: 20 },
          opacity: isMobile ? 0.4 : 1,
          userSelect: 'none',
        }}
      >
        <img src={backgroundPhoto} alt="backgroundPhoto" className="back-image" />
      </Grid>
    </Grid>
  )
}
