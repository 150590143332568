import { apiClient } from './apiClient'
import ENDPOINTS from './endpoints'

const endpoint = ENDPOINTS.USER

const getAvatars = (): any => apiClient.get(`${endpoint}/avatar`).then((res) => res.data)

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAvatars,
}
