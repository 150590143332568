import { create } from 'zustand'

import { NotificationStoreProps } from '../utils/types'

const useStore = create<NotificationStoreProps>((set) => ({
  message: '',
  showBanner: false,
  handleSetMessage: (message: string) => set({ message }),
  handleSetBanner: (show: boolean) => {
    set({ showBanner: show })
    setTimeout(() => {
      set({ showBanner: false })
    }, 5000)
  },
}))

export default useStore
