import { Link, useLocation, useNavigate } from 'react-router-dom'

import { MobileHeader } from './MobileHeader'
import { NavLink } from './components/NavLink'
import { UserMenu } from 'components/UserMenu'
import { UserAvatar } from 'components/UserAvatar'

import { authUser } from 'utils/userService'

import useUserStore from 'store/user.store'
import useDeviceStore from 'store/deviceDetect.store'

import logo from 'assets/Logo/logo.svg'

import { FaUser } from 'react-icons/fa'
import { AuthContainer, HeaderWrapper, LinkContainer, RegistrationButton } from './HeaderStyles'

import List from '@mui/material/List'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

export const Header = (): JSX.Element => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { userDetails } = useUserStore()
  const { isMobile } = useDeviceStore()

  const isAuth: boolean = authUser()
  const isHomeRout: boolean = pathname === '/'
  const isAdmin: boolean = userDetails?.role === 'admin'

  const handleRedirectUser = (): void => {
    navigate('sign-up')
  }

  const LIST_ITEMS = [
    {
      title: 'Головна',
      path: '/',
    },
    {
      title: 'Курси',
      path: '/courses',
    },
    {
      title: 'Контакти',
      path: '/contacts',
    },
    {
      title: 'Відгуки',
      path: '/feedbacks',
    },
  ]

  return (
    <>
      {!isMobile ? (
        <Grid
          sx={{
            ...HeaderWrapper,
            position: !isHomeRout && 'sticky',
            borderRadius: !isHomeRout && '0px 0px 20px 20px',
          }}
        >
          <Link to="/" style={{ textDecoration: 'none', marginTop: '5px', width: '125px' }}>
            <img src={logo} alt="logo" />
          </Link>
          <List sx={LinkContainer}>
            {LIST_ITEMS.map(({ title, path }, index) => (
              <NavLink to={path} key={index}>
                {title}
              </NavLink>
            ))}
            {isAuth && <NavLink to={`/profile/${userDetails?._id}`}>Профіль</NavLink>}
          </List>

          <Grid sx={AuthContainer}>
            {!isAuth ? (
              <>
                <Link
                  to="/sign-in"
                  style={{
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '5px',
                    textDecoration: 'none',
                    fontSize: '16px',
                    fontWeight: 600,
                  }}
                >
                  Ввійти
                  <FaUser size={17} style={{ marginBottom: '2px' }} />
                </Link>
                <Button
                  variant="contained"
                  sx={RegistrationButton}
                  size="medium"
                  onClick={handleRedirectUser}
                >
                  Зареєструватись
                </Button>
              </>
            ) : (
              <>
                {!!Object.keys(userDetails).length && (
                  <Grid sx={{ display: 'flex', alignItems: 'center', gap: '5px', cursor: 'pointer' }}>
                    <Typography variant="h6" color="white" sx={{ mr: '10px' }}>
                      Привіт, {userDetails.name?.split(' ')[0]}
                    </Typography>
                    <span onClick={() => navigate(`/profile/${userDetails._id}`)}>
                      <UserAvatar userName={userDetails?.name} avatar={userDetails.avatar} size="small" />
                    </span>
                    <UserMenu name={userDetails.name} isAdmin={isAdmin} />
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Grid>
      ) : (
        <MobileHeader />
      )}
    </>
  )
}
