import './index.scss'

import useNotificationStore from 'store/notification.store'

import Box from '@mui/material/Box'
interface NotificationProps {
  title: string
}

export const NotificationBanner = ({ title }: NotificationProps): JSX.Element => {
  const { handleSetBanner } = useNotificationStore()

  const handleCloseBanner = (): void => {
    handleSetBanner(false)
  }

  return (
    <>
      {title && (
        <Box className="notification" onClick={handleCloseBanner}>
          <p>{title}</p>
          <span className="progress"></span>
        </Box>
      )}
    </>
  )
}
