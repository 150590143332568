import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'
import { Field, Form, Formik, FormikErrors, FormikTouched } from 'formik'

import { SuccessWindow } from '../SuccessWindow'
import { FeedbackSlider } from './FeedbackSlider'
import TransitionsModal from '../TransitionsModal'

import { CustomRating } from 'components/CustomRating'
import { EditInput } from 'components/EditField/EditFieldStyles'

import useUserStore from 'store/user.store'
import useModalStore from 'store/modals.store'
import useNotificationStore from 'store/notification.store'

import { authUser } from 'utils/userService'
import { InputChangeEvent } from 'types/common'
import { FeedbacksListData } from 'types/feedbacks'
import { FormState, SendFeedbackStatus, IUserComment } from 'utils/types'

import ENDPOINTS from 'api/endpoints'
import feedbackApi from 'api/feedbackApi'

import { FeedbacksWrapper } from './FeedbacksStyles'
import { InputError } from '../FormField/FormFieldStyles'
import { CourseTypeButton } from '../CourseType/CourseTypeStyles'
import { circleLoading, closeIcon, doneIcon } from '../ContactUsForm/styles'

import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import { Close, Done } from '@mui/icons-material'
import Typography from '@mui/material/Typography'
import OutlinedInput from '@mui/material/OutlinedInput'
import CircularProgress from '@mui/material/CircularProgress'

export const Feedbacks = (): JSX.Element => {
  const navigate = useNavigate()
  const { userDetails } = useUserStore()
  const { handleSetModal } = useModalStore()
  const { handleSetBanner, handleSetMessage } = useNotificationStore()

  const initialValues = {
    comment: '',
    rating: 5,
    authorId: userDetails?._id,
    authorName: userDetails?.name,
    role: userDetails?.role,
  }

  const [userComment, setUserComment] = useState<IUserComment>(initialValues)
  const [feedbacks, setFeedbacks] = useState<FeedbacksListData[]>([])
  const [formState, setFormState] = useState<FormState>({
    buttonState: '',
    errors: {},
  })

  const { data: feedbacksList, isSuccess } = useQuery(ENDPOINTS.FEEDBACKS, feedbackApi.getFeedbacksList)

  const { mutate: createFeedback } = useMutation(ENDPOINTS.FEEDBACKS, feedbackApi.createFeedback, {
    onSuccess: ({ comments, message }) => {
      setFeedbacks(comments)
      handleSetMessage(message)
      handleSetBanner(true)
      setUserComment(initialValues)
      setFormState({ ...formState, buttonState: 'success' })
    },
    onError: ({ response }) => {
      console.log(response)
      handleSetBanner(true)
      handleSetMessage(response.data.message)
      setFormState({ ...formState, buttonState: 'error' })
    },
    onSettled: () => {
      handleSetModal()
    },
  })

  const { mutate: deleteFeedback } = useMutation(ENDPOINTS.FEEDBACKS, feedbackApi.deleteFeedback, {
    onSuccess: ({ comments, message }) => {
      handleSetMessage(message)
      setFeedbacks(comments)
      handleSetBanner(true)
    },
    onError: ({ response }: any) => {
      handleSetBanner(true)
      handleSetMessage(response.data.message)
    },
  })

  const handleClick = (
    errors: FormikErrors<SendFeedbackStatus>,
    touched: FormikTouched<SendFeedbackStatus>
  ): void => {
    if (Object.keys(errors).length > 0 || Object.keys(touched).length === 0)
      setFormState({ ...formState, buttonState: 'error' })
    setTimeout(() => {
      setFormState({ ...formState, buttonState: '' })
    }, 3000)
  }

  const handleChange = (event: InputChangeEvent): void => {
    const { name, value } = event.target

    if (name === 'rating') return setUserComment({ ...userComment, [name]: Number(value) })
    setUserComment({ ...userComment, [name]: value })
  }

  const validationSchema = Yup.object().shape({
    comment: Yup.string().required("Обов'язкове поле").min(10, 'Коментар має містити мінімум 8 символів'),
  })

  const redirectUser = (): void => {
    navigate('sign-in')
  }

  const onSubmit = (): void => {
    setFormState({ ...formState, buttonState: 'loading' })
    createFeedback({
      authorName: userDetails.name,
      authorId: userDetails._id,
      role: userDetails.role,
      comment: userComment.comment,
      rating: userComment.rating,
    })
  }

  useEffect(() => {
    if (feedbacksList) setFeedbacks(feedbacksList)
  }, [feedbacksList])

  return (
    <>
      {isSuccess && (
        <Grid width="100%" sx={FeedbacksWrapper}>
          <Typography variant="h1" ml={{ xs: '20px', sm: 0 }}>
            Відгуки наших
            <span style={{ color: 'var(--main)' }}> студентів </span>
          </Typography>
          <Grid container>
            <FeedbackSlider feedbackData={feedbacks} handleCommentDelete={deleteFeedback} />
          </Grid>

          <Button
            variant="outlined"
            sx={CourseTypeButton}
            size="medium"
            onClick={authUser() ? handleSetModal : redirectUser}
          >
            Написати відгук
          </Button>
        </Grid>
      )}

      <TransitionsModal>
        <SuccessWindow>
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ errors, touched, setFieldValue, setFieldTouched }) => (
              <Form
                style={{
                  width: '100%',
                  textAlign: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '15px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <span>
                  <Typography variant="h3" fontWeight={500} gutterBottom>
                    Ваш Відгук 💜
                  </Typography>
                  <Typography variant="h6">
                    Нам дуже важлива ваша думка Спасибі, що ділитеся нею 🫶🏻
                  </Typography>
                </span>
                <CustomRating value={userComment.rating} handleChange={handleChange} name="rating" />
                <Field name="comment">
                  {({ meta }) => (
                    <Grid container>
                      <OutlinedInput
                        fullWidth
                        sx={{
                          ...EditInput,
                          color: !!meta.error && !!meta.touched ? '#d32f2f' : 'var(--black)',
                          p: 0,
                        }}
                        placeholder="Ваш відгук..."
                        multiline
                        value={userComment.comment}
                        rows={3}
                        name="comment"
                        onBlur={() => setFieldTouched('comment', true)}
                        error={!!meta.error && !!meta.touched}
                        onChange={(event: InputChangeEvent) => {
                          handleChange(event)
                          setFieldValue('comment', event.target.value)
                        }}
                      />
                      <Grid sx={InputError}>{!!meta.error && !!meta.touched ? meta.error : ''}</Grid>
                    </Grid>
                  )}
                </Field>

                <Grid container justifyContent="center">
                  <button
                    type="submit"
                    className={formState.buttonState === 'error' ? 'send-button error' : 'send-button'}
                    onClick={() => handleClick(errors, touched)}
                  >
                    {formState.buttonState === '' && 'Надіслати'}
                    {formState.buttonState === 'loading' && <CircularProgress size={24} sx={circleLoading} />}
                    {formState.buttonState === 'error' && <Close sx={closeIcon} />}
                    {formState.buttonState === 'success' && <Done sx={doneIcon} />}
                  </button>
                </Grid>
              </Form>
            )}
          </Formik>
        </SuccessWindow>
      </TransitionsModal>
    </>
  )
}
