import { apiClient } from './apiClient'
import ENDPOINTS from './endpoints'

const endpoint = ENDPOINTS.TEST

const getLanguageTest = (language: string, level: string): any =>
  apiClient.get(`${endpoint}?language=${language}&level=${level}`).then((res) => res.data)

const createTestQuestion = (test: any): any => apiClient.post(`${endpoint}`, test).then((res) => res)

const deleteTestQuestion = (id: string): any => apiClient.delete(`${endpoint}/${id}`).then((res) => res.data)

const updateTestQuestion = (testId: string, testData: any): any =>
  apiClient.put(`${endpoint}/${testId}`, testData).then((res) => res.data)

const getTestQuestion = (id: string): any => apiClient.get(`${endpoint}/${id}`).then((res) => res.data)

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getLanguageTest,
  createTestQuestion,
  deleteTestQuestion,
  updateTestQuestion,
  getTestQuestion,
}
