import { UserDataStorage } from './types'

export const setCookies = ({ accessToken, refreshToken, userId }): void => {
  localStorage.setItem(
    'userData',
    JSON.stringify({
      accessToken,
      refreshToken,
      userId,
    })
  )
}

export const authUser = (): boolean => {
  const user = localStorage.getItem('userData')
  if (user) return true
  return false
}

export const isAdmin = (): boolean => {
  const data: UserDataStorage =
    localStorage.getItem('userData') && JSON.parse(localStorage.getItem('userData'))

  const isAdminRole: boolean = data?.role === 'admin' ? true : false
  return isAdminRole
}

export const deleteCookies = (): void => {
  localStorage.removeItem('userData')
}

export const getUserData = (): UserDataStorage => {
  const data: UserDataStorage | null =
    localStorage.getItem('userData') && JSON.parse(localStorage.getItem('userData'))
  return data ? data : null
}
