import { Form, Formik } from 'formik'
import { FormField } from 'components/FormField'

import { UserCourseInitialValues } from 'utils/InitialValues'

import { UserArrangeFormWrapper } from '../CourseDetailsStyles'

import Grid from '@mui/material/Grid'
import { Close, Done } from '@mui/icons-material'
import CircularProgress from '@mui/material/CircularProgress'
import { circleLoading, closeIcon, doneIcon } from 'components/ContactUsForm/styles'

export const WithoutLevelForm = ({
  onSubmit,
  userData,
  formState,
  handleClick,
  validationSchema,
  handlerChange,
}): JSX.Element => {
  return (
    <Formik initialValues={UserCourseInitialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ errors, touched, setFieldValue, setFieldTouched }) => (
        <Form style={{ width: '98%' }}>
          <Grid container sx={UserArrangeFormWrapper}>
            <FormField
              name="name"
              label="Ім'я та прізвище"
              variant="standard"
              formValue={userData.name}
              onChange={handlerChange}
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
            />
            <FormField
              name="email"
              label="Email"
              variant="standard"
              formValue={userData.email}
              onChange={handlerChange}
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
            />
            <FormField
              name="phone"
              label="Номер телефону"
              variant="standard"
              formValue={userData.phone}
              onChange={handlerChange}
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
            />
            <FormField
              name="age"
              label="Вік"
              variant="standard"
              formValue={userData.age}
              onChange={handlerChange}
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
            />
            <Grid container sx={{ mt: '15px' }} justifyContent="center">
              <button
                type="submit"
                className={formState.buttonState === 'error' ? 'send-button error' : 'send-button'}
                onClick={() => handleClick(errors, touched)}
              >
                {formState.buttonState === '' && 'Відправити'}
                {formState.buttonState === 'loading' && <CircularProgress size={24} sx={circleLoading} />}
                {formState.buttonState === 'error' && <Close sx={closeIcon} />}
                {formState.buttonState === 'success' && <Done sx={doneIcon} />}
              </button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}
