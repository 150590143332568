import emailjs from '@emailjs/browser'

//send password token
export const handleSendPasswordToken = async (
  serviceId: string,
  templateId: string,
  data: any,
  userId: string
) => {
  const response = emailjs.send(serviceId, templateId, data, userId)
  return await response
}

// feedback API
export const handleSendFeedback = async (serviceId: string, templateId: string, data, userId: string) => {
  const result = emailjs.send(serviceId, templateId, data, userId)
  return await result
}

// send course invite
export const handleCourseInvite = async (serviceId: string, templateId: string, data, userId: string) => {
  const result = emailjs.send(serviceId, templateId, data, userId)
  return await result
}
