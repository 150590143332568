export const FormWrapper = {
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
}

export const DeleteProfile = {
  textDecoration: 'underline',
  cursor: 'pointer',
  mt: '10px',
  color: '#d32f2f',
  fontSize: '14px',
}

export const AnswerButton = {
  p: '12px 5px',
  width: '50%',
}
