import { CoursesListResponse } from 'types/courses'
import { apiClient } from './apiClient'
import ENDPOINTS from './endpoints'

const endpoint = ENDPOINTS.COURSES

const getListOfCourses = (search: string = ''): CoursesListResponse =>
  apiClient.get(`${endpoint}${search === '' ? '' : `?title=${search}`}`).then((res) => res.data)

const getCourseById = (id: string): any => apiClient.get(`${endpoint}/${id}`).then((res) => res.data)

const deleteCourse = (id: string): any => apiClient.delete(`${endpoint}/${id}`).then((res) => res)

const createCourse = (courseData: any): any =>
  apiClient.post(`${endpoint}/create`, courseData).then((res) => res.data)

const updateCourse = ({ courseData, id }): any =>
  apiClient.put(`${endpoint}/${id}`, courseData).then((res) => res.data)

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getListOfCourses,
  getCourseById,
  deleteCourse,
  createCourse,
  updateCourse,
}
