import { Suspense, useEffect } from 'react'
import { useQuery } from 'react-query'

import { Footer } from 'components/Footer'
import { Header } from 'components/Header'
import { Loader } from 'components/Loader'
import { NotificationBanner } from '../components/NotificationBanner'

import { DeviceWrapper } from 'layouts/DeviceWrapper'

import { Routes } from 'config/Routes'
import { getUserData } from 'utils/userService'

import useUserStore from 'store/user.store'
import useLoaderStore from 'store/loader.store'
import useNotificationStore from 'store/notification.store'

import userApi from 'api/userApi'

import { theme } from '../styles/muiTheme'
import { Grid, ThemeProvider } from '@mui/material'

export const App = (): JSX.Element => {
  const { handleSetUserDetails } = useUserStore()
  const { loading } = useLoaderStore()
  const { showBanner, message } = useNotificationStore()

  const userData = getUserData()

  const { data } = useQuery([userData], () => userApi.getUserDetails(userData?.userId), {
    enabled: !!userData,
  })

  useEffect(() => {
    if (data) {
      handleSetUserDetails(data)
    }
  }, [data, handleSetUserDetails])

  return (
    <ThemeProvider theme={theme}>
      <DeviceWrapper>
        <Suspense fallback={<Loader />}>
          {loading && <Loader />}
          <Grid sx={{ display: loading ? 'none' : 'block' }}>
            <Header />
            <Routes />
            <Footer />
            {showBanner && <NotificationBanner title={message} />}
          </Grid>
        </Suspense>
      </DeviceWrapper>
    </ThemeProvider>
  )
}
