import { Fragment } from 'react'
import useUserStore, { initialUserState } from 'store/user.store'
import useNotificationStore from 'store/notification.store'

import { NavLink } from '../components/NavLink'

import { ROLES } from 'utils/constants'
import { MenuContentPropTypes } from 'utils/types'
import { authUser, deleteCookies } from 'utils/userService'

import './index.scss'

import * as AiIcons from 'react-icons/ai'
import * as IoIcons from 'react-icons/io'
import * as FiIcons from 'react-icons/fi'
import * as FaIcons from 'react-icons/fa'
import * as CgIcons from 'react-icons/cg'
import * as HiIcons from 'react-icons/hi'
import * as GiIcons from 'react-icons/gi'

import Box from '@mui/material/Box'
import List from '@mui/material/List'
import Grid from '@mui/material/Grid'

export const MenuContent = ({ open, setOpen }: MenuContentPropTypes): JSX.Element => {
  const { userDetails, handleSetUserDetails } = useUserStore()
  const { handleSetBanner, handleSetMessage } = useNotificationStore()

  const isAdmin: boolean = userDetails?.role === ROLES.ADMIN
  const isAuth: boolean = authUser()

  const handleExitUser = (): void => {
    handleSetMessage(`До зустрічі, ${userDetails.name} 👌`)
    deleteCookies()
    handleSetUserDetails(initialUserState)
    handleSetBanner(true)
  }

  const MOBILE_LIST_ITEMS = [
    {
      title: 'Головна',
      path: '/',
      icon: <AiIcons.AiFillHome size={20} />,
      show: true,
    },
    {
      title: 'Курси',
      path: '/courses',
      icon: <GiIcons.GiOpenBook size={21} />,
      show: true,
    },
    {
      title: 'Тестування',
      path: '/language-test',
      icon: <IoIcons.IoIosPaper size={20} />,
      show: true,
    },
    {
      title: 'Відгуки',
      path: '/feedbacks',
      icon: <AiIcons.AiFillMessage size={22} />,
      show: true,
    },
    {
      title: 'Контакти',
      path: '/contacts',
      icon: <IoIcons.IoMdPeople size={21} />,
      show: true,
    },
    {
      title: 'Профіль',
      path: `/profile/${userDetails?._id}`,
      icon: <FaIcons.FaUser size={16} />,
      show: isAuth,
    },
    {
      title: 'Додати курс',
      path: '/course-add',
      icon: <HiIcons.HiViewGridAdd size={18} />,
      show: isAuth && isAdmin,
    },
    {
      title: 'Додати питання',
      path: '/test-add',
      icon: <HiIcons.HiViewGridAdd size={20} />,
      show: isAuth && isAdmin,
    },
    {
      title: 'Тестові питання',
      path: '/test-questions',
      icon: <HiIcons.HiViewGridAdd size={20} />,
      show: isAuth && isAdmin,
    },
    {
      title: 'Вийти',
      path: '',
      icon: <FiIcons.FiLogOut size={20} />,
      show: isAuth,
      onClick: handleExitUser,
    },
    {
      title: 'Вхід',
      path: '/sign-in',
      icon: <FaIcons.FaUser size={15} />,
      show: !isAuth,
    },
    {
      title: 'Реєстрація',
      path: '/sign-up',
      icon: <IoIcons.IoMdPersonAdd size={20} />,
      show: !isAuth,
    },
  ]

  return (
    <Grid className={open ? 'menu active' : 'menu'} onClick={() => setOpen(false)}>
      <Box className="blur" />
      <Box className="menu-wrapper">
        <List className="menu-content">
          <Grid onClick={() => setOpen(false)} container justifyContent="flex-end" sx={{ mb: '10px' }}>
            <CgIcons.CgClose size={34} color="white" />
          </Grid>

          {MOBILE_LIST_ITEMS.map(({ title, path, icon, show, onClick }) => (
            <Fragment key={path}>
              {show && (
                <NavLink to={path} onClick={onClick && onClick}>
                  {icon}
                  {title}
                </NavLink>
              )}
            </Fragment>
          ))}
        </List>
      </Box>
    </Grid>
  )
}
