import { useState } from 'react'
import dayjs from 'dayjs'
import * as Yup from 'yup'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { Formik, Form, FormikErrors, FormikTouched } from 'formik'

import { FormState, SelectedAvatarPropTypes } from 'utils/types'

import userApi from 'api/userApi'

import { InputChangeEvent } from 'types/common'
import { isEmptyObj } from 'utils/isEmptyObject'
import { deleteCookies } from 'utils/userService'
import { SelectedAvatarInitialValue } from 'utils/InitialValues'
import { BASE_AWS_URL, FILE_FORMATS, MAX_FILE_SIZE } from 'utils/constants'

import { DateField } from 'components/DateField'
import { EditField } from 'components/EditField'
import { SuccessWindow } from 'components/SuccessWindow'
import { AvatarSlider } from './components/AvatarSlider'
import TransitionsModal from 'components/TransitionsModal'
import { Label } from 'components/EditField/EditFieldStyles'
import { circleLoading, closeIcon, doneIcon } from 'components/ContactUsForm/styles'

import useUserStore from 'store/user.store'
import useModalStore from 'store/modals.store'
import useNotificationStore from 'store/notification.store'

import { AnswerButton, DeleteProfile, FormWrapper } from './styles'

import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { Close, Done } from '@mui/icons-material'
import CircularProgress from '@mui/material/CircularProgress'

export const UserEdit = ({ userDetails, defaultAvatars }: any): JSX.Element => {
  const initialValues = {
    name: userDetails.name,
    phone: userDetails.phone,
    avatar: userDetails.avatar,
    birthday: userDetails.birthday && dayjs(userDetails.birthday).format('MM/DD/YYYY'),
  }

  const navigate = useNavigate()
  const { handleSetModal } = useModalStore()
  const { handleSetUserDetails } = useUserStore()
  const { handleSetBanner, handleSetMessage } = useNotificationStore()

  const [userData, setUserData] = useState<any>(initialValues)
  const [formState, setFormState] = useState<FormState>({
    buttonState: '',
    errors: {},
  })
  const [selectedAvatar, setSelectedAvatar] = useState<SelectedAvatarPropTypes>(SelectedAvatarInitialValue)
  const [uploaded, setUploaded] = useState(false)

  const { mutate: updateUser } = useMutation(userApi.updateUser, {
    onSuccess: ({ data }) => {
      handleSetUserDetails(data.updatedUser)
      handleSetMessage(data.message)
      handleSetBanner(true)
      setFormState({ ...formState, buttonState: 'success' })
    },
    onError: ({ response }) => {
      handleSetBanner(true)
      handleSetMessage(response.data.message)
      setFormState({ ...formState, buttonState: 'error' })
    },
    onSettled: () => {
      setSelectedAvatar(SelectedAvatarInitialValue)
      setFormState({ errors: {}, buttonState: '' })
      setUploaded(false)
    },
  })

  const { mutate: deleteUser } = useMutation(userApi.deleteUser, {
    onSuccess: ({ data }) => {
      handleSetMessage(data.message)
      handleSetBanner(true)
      deleteCookies()
    },
    onError: ({ response }) => {
      handleSetBanner(true)
      handleSetMessage(response.data.message)
    },
    onSettled: () => {
      navigate('/')
      handleSetModal()
      setUploaded(false)
    },
  })

  const handleChange = (event: InputChangeEvent): void => {
    event.preventDefault()
    const { name, value } = event.target as HTMLInputElement
    setUserData({ ...userData, [name]: value })
  }

  const handleBirthDayChange = (birthday: string | null): void => {
    setUserData({ ...userData, birthday })
  }

  const deleteUploadAvatar = (): void => {
    setUserData({ ...userData, avatar: '' })
    setUploaded(false)
  }

  const handleAddAvatar = (event: InputChangeEvent): void => {
    event.preventDefault()
    const { name, files } = event.target as HTMLInputElement
    validateSelectedImage(name, files[0].size, files)
  }

  const handleUserAvatar = (_id: string): void => {
    const isSelectedAvatar = defaultAvatars.find((element: SelectedAvatarPropTypes) => element._id === _id)
    if (selectedAvatar._id === _id) {
      setSelectedAvatar(SelectedAvatarInitialValue)
    } else {
      setSelectedAvatar(isSelectedAvatar)
      setUserData({ ...userData, avatar: '' })
    }
  }

  const validateSelectedImage = (name: string, size: number, file: any) => {
    deleteUploadAvatar()
    const fileSizeKiloBytes = size / 1024
    const files = [...file]

    if (
      FILE_FORMATS &&
      files.some(
        (file) => !FILE_FORMATS.some((format) => file.name.toLowerCase().endsWith(format.toLowerCase()))
      )
    ) {
      handleSetBanner(true)
      return handleSetMessage('Даний формат не підтримується.')
    }

    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      handleSetBanner(true)
      return handleSetMessage('MAX_FILE_SIZE')
    } else if (file && file[0]) {
      handleSetBanner(true)
      handleSetMessage('Фото додане 📸')
      setSelectedAvatar(SelectedAvatarInitialValue)
      setUploaded(true)
      return setUserData({ ...userData, [name]: file[0] })
    } else {
      handleSetBanner(true)
      return handleSetMessage('Щось пішло не так')
    }
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string(),
  })

  const sendData = () => {
    if (uploaded) {
      const formData = new FormData()
      formData.append('name', userData.name)
      formData.append('phone', userData.phone)
      formData.append('avatar', userData.avatar)
      if (userData.birthday) formData.append('birthday', userData.birthday)

      return formData
    }
    return {
      name: userData.name,
      phone: userData.phone,
      ...(selectedAvatar.key && { avatar: `${BASE_AWS_URL}${selectedAvatar.key}` }),
      birthday: userData.birthday,
    }
  }

  const onSubmit = () => {
    setFormState({ ...formState, buttonState: 'loading' })
    return updateUser({ userData: sendData(), userId: userDetails._id })
  }

  const handleClick = (errors: FormikErrors<any>, touched: FormikTouched<any>): void => {
    if (Object.keys(errors).length > 0 || Object.keys(touched).length === 0)
      setFormState({ ...formState, buttonState: 'error' })
    setTimeout(() => {
      setFormState({ ...formState, buttonState: '' })
    }, 3500)
  }

  return (
    <>
      {isEmptyObj(userDetails) && (
        <Grid>
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ errors, touched, setFieldValue, setFieldTouched }) => (
              <Form>
                <Grid sx={FormWrapper}>
                  <Grid container gap={3}>
                    <EditField
                      name="name"
                      label="Ім'я"
                      formValue={userData.name}
                      onChange={handleChange}
                      setFieldTouched={setFieldTouched}
                      setFieldValue={setFieldValue}
                    />
                    <EditField
                      name="phone"
                      label="Номер телефону"
                      formValue={userData.phone}
                      onChange={handleChange}
                      setFieldTouched={setFieldTouched}
                      setFieldValue={setFieldValue}
                    />
                    <DateField
                      value={userData.birthday}
                      handleChange={handleBirthDayChange}
                      label="Дата народження"
                    />
                  </Grid>

                  <Grid container flexDirection="column">
                    <Typography variant="body2" sx={Label} gutterBottom>
                      Avatar
                    </Typography>

                    <AvatarSlider
                      avatarCollection={defaultAvatars}
                      selectedAvatar={selectedAvatar}
                      uploadActive={uploaded}
                      handleAddAvatar={handleAddAvatar}
                      handleUserAvatar={handleUserAvatar}
                      deleteUploadAvatar={deleteUploadAvatar}
                    />
                  </Grid>

                  <Grid container justifyContent="center" sx={{ mb: '10px' }}>
                    <button
                      type="submit"
                      style={{ padding: '0px 20px', width: '210px' }}
                      className={formState.buttonState === 'error' ? 'send-button error' : 'send-button'}
                      onClick={() => handleClick(errors, touched)}
                    >
                      {formState.buttonState === '' && `Оновити інформацію`}
                      {formState.buttonState === 'loading' && (
                        <CircularProgress size={24} sx={circleLoading} />
                      )}
                      {formState.buttonState === 'error' && <Close sx={closeIcon} />}
                      {formState.buttonState === 'success' && <Done sx={doneIcon} />}
                    </button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
          <Typography variant="body2" sx={DeleteProfile} onClick={handleSetModal}>
            Видалити профіль
          </Typography>
        </Grid>
      )}

      <TransitionsModal>
        <SuccessWindow>
          <Typography variant="h5" sx={{ ...Label, mb: '15px' }}>
            Ви впевнені, що хочете видалити профіль?
          </Typography>
          <Grid container width="100%" gap={2} flexWrap="nowrap">
            <Button
              variant="contained"
              sx={{ ...AnswerButton, backgroundColor: 'var(--black)' }}
              onClick={() => deleteUser(userDetails._id)}
            >
              Так
            </Button>
            <Button
              variant="outlined"
              sx={{
                ...AnswerButton,
                border: '2px solid var(--main)',
                fontWeight: 600,
              }}
              onClick={handleSetModal}
            >
              Ні
            </Button>
          </Grid>
        </SuccessWindow>
      </TransitionsModal>
    </>
  )
}
