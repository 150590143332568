import { styled } from '@mui/material/styles'
import InputBase from '@mui/material/InputBase'

export const WelcomeScreenWrapper = {
  backgroundColor: 'var(--black)',
  width: '100%',
  p: '50px 0px',
  textAlign: 'center',
  borderRadius: '24px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  mb: '45px',
}

export const Search = styled('div')(() => ({
  position: 'relative',
  borderRadius: '10px',
  backgroundColor: 'var(--white)',
  display: 'flex',
  justifyContent: 'flex-start',
  marginLeft: 0,
  boxShadow: '0px 0px 10px 5px rgb(28 37 43 / 3%)',
}))

export const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: '#515257',
  fontSize: '14px',
  fontWeight: 500,

  '& .MuiInputBase-input': {
    padding: '14px 14px 14px 0px',
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '500px',
    [theme.breakpoints.down('md')]: {
      width: '400px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '230px',
    },
  },
}))
