import { useNavigate } from 'react-router-dom'

import './CouseTypeCard.scss'
import '../CourseSlider/CourseSlider.scss'

import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Chip from '@mui/material/Chip'
import Button from '@mui/material/Button'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'

export const CourseCard = ({ courseTypes }: any): JSX.Element => {
  const { title, description, price, _id, language, image, lessonType } = courseTypes
  const courseLanguage = language === 'eng' ? 'Англійська мова' : 'Німецька мова'
  const coursePrise = lessonType === 'group' || lessonType === 'children' ? 'місяць' : 'год'
  const navigate = useNavigate()

  const handleCourseDetails = () => {
    navigate(`/course-details/${_id}`)
  }

  return (
    <Card
      sx={{
        boxShadow: 'none',
        margin: '10px 0px',
        transition: 'transform 0.2s ease-in-out',
        ':hover': {
          boxShadow: '0px 0px 20px 10px rgb(28, 37, 43, 0.045)',
          transition: 'transform 0.2s ease-in-out',
          zIndex: 10,
        },
        cursor: 'pointer',
      }}
      onClick={handleCourseDetails}
    >
      <CardMedia
        component="img"
        alt="course type card"
        src={image}
        sx={{
          borderRadius: '12px',
          width: '100%',
        }}
      />
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          p: '22px',
          gap: '15px',
          height: '240px',
        }}
      >
        <Chip
          label={courseLanguage}
          sx={{
            color: language === 'eng' ? 'var(--white)' : 'var(--main)',
            width: { xs: '55%', md: '50%' },
            backgroundColor: language === 'eng' ? 'var(--main)' : '#F7F4FF',
          }}
        />
        <Typography variant="h5" component="div">
          {title}
        </Typography>
        <Typography
          variant="body2"
          component="div"
          color="var(--light-gray)"
          className="course-type-card-text"
        >
          {description}
        </Typography>
      </CardContent>
      <CardActions sx={{ p: '0px 16px 16px' }}>
        <Grid width="100%" container justifyContent="flex-start" sx={{ mt: 1 }}>
          <Typography
            gutterBottom
            variant="body2"
            component="div"
            color="var(--main)"
            fontSize="13px"
            fontWeight={600}
          >
            {coursePrise === 'год' && 'від '}
            {price} грн / {coursePrise}
          </Typography>
        </Grid>
        <Button
          variant="contained"
          sx={{
            backgroundColor: 'var(--black)',
            py: '9px',
            width: '90%',
            fontWeight: 500,
          }}
          size="medium"
          onClick={handleCourseDetails}
        >
          Детальніше
        </Button>
      </CardActions>
    </Card>
  )
}
