import { UserAvatar } from 'components/UserAvatar'
import { useNavigate } from 'react-router-dom'

import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

export const MobileUserInfo = ({ userDetails }: any): JSX.Element => {
  const navigate = useNavigate()

  const handleRedirectUser = (path: string): void => {
    navigate(`/${path}`)
  }

  return (
    <>
      <Grid container alignItems="center" gap={2} flexDirection="column" sx={{ mt: '13px' }}>
        <UserAvatar userName={userDetails?.name} avatar={userDetails?.avatar} size="medium" />
        <Grid textAlign="center">
          <Typography variant="h5">{userDetails?.name}</Typography>
          <Typography variant="h6" color="var(--light-gray)" fontSize={14}>
            {userDetails?.email}
          </Typography>
        </Grid>
      </Grid>
      <Grid container flexWrap="nowrap" gap={2}>
        <Button
          variant="contained"
          onClick={() => handleRedirectUser('courses')}
          sx={{
            backgroundColor: 'var(--black)',
            py: '12px',
            width: { xs: '100%', sm: '80%', md: '50%' },
            fontWeight: 600,
          }}
          size="medium"
        >
          До курсів
        </Button>
        <Button
          variant="outlined"
          onClick={() => handleRedirectUser('language-test')}
          sx={{
            py: '12px',
            width: { xs: '100%', sm: '80%', md: '50%' },
            border: '2.5px solid rgba(166, 140, 241, 0.5)',
            fontWeight: 600,
            ':hover': {
              border: '2.5px solid rgba(166, 140, 241, 0.5)',
            },
          }}
          size="medium"
        >
          Пройти тест
        </Button>
      </Grid>
    </>
  )
}
