const ArrowRight = ({ className = '', ...props }): JSX.Element => {
  return (
    <svg
      width={7}
      height={13}
      viewBox="0 0 7 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: 'pointer' }}
      {...props}
    >
      <path
        d="M1 1L5.38848 5.82733C5.73523 6.20875 5.73523 6.79125 5.38848 7.17267L1 12"
        stroke="#2A302E"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default ArrowRight
