export const ProfileWrapper = {
  p: '48px 24px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: '25px',
}

export const ProfileUserHeader = {
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  gap: '40px',
  alignItems: 'center',
  textAlign: 'center',
  boxShadow: '0px 0px 30px 15px rgb(166, 140, 241, 0.17)',
  borderRadius: '14px',
  p: '24px',
}

export const SettingsButtonWrapper = {
  p: '8px',
  border: '1px solid var(--light-gray)',
  borderRadius: '10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

export const UserAvatar = {
  width: '100px',
  height: '100px',
  border: '3px solid var(--white)',
}

export const DesktopUserAvatar = {
  width: '140px',
  height: '140px',
  backgroundColor: 'var(--main)',
  border: '4px solid var(--white)',
}

export const ProfileBreadCrumbsIcons = {
  fill: '#54198b',
  mb: '3px',
}
