export const SmallSize = {
  width: 36,
  height: 36,
  border: '3px solid var(--main)',
  backgroundColor: '#F7F4FF',
  color: 'var(--main)',
  fontWeight: 600,
}

export const MediumSize = {
  width: '100px',
  height: '100px',
  boxSizing: 'border-box',
  border: '3px solid white',
  fontSize: '42px',
  fontWeight: 600,
  backgroundColor: 'var(--main)',
}

export const LargeSize = {
  width: '140px',
  height: '140px',
  backgroundColor: 'var(--main)',
  boxSizing: 'border-box',
  border: '3px solid white',
  fontSize: '48px',
  fontWeight: 500,
}

export const CommentSize = {
  boxSizing: 'border-box',
  width: '53px',
  height: '53px',
  border: '3px solid white',
  backgroundColor: 'var(--main)',
  fontWeight: 600,
  fontSize: '22px',
}
