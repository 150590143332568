import { Field } from 'formik'

import { EditFieldProps } from 'utils/types'
import { InputChangeEvent } from 'types/common'
import { InputError } from 'components/FormField/FormFieldStyles'

import { EditInput, Label } from './EditFieldStyles'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import OutlinedInput from '@mui/material/OutlinedInput'

export const EditField = ({
  name,
  formValue,
  label,
  onChange,
  setFieldTouched,
  setFieldValue,
  type = 'text',
  withLabel = true,
}: EditFieldProps): JSX.Element => {
  return (
    <Field name={name}>
      {({ meta }) => (
        <Grid container gap={withLabel ? 0.6 : 0}>
          {withLabel && (
            <Typography variant="body2" sx={Label}>
              {label}
            </Typography>
          )}
          <OutlinedInput
            fullWidth
            sx={{
              ...EditInput,
              color: !!meta.error && !!meta.touched ? '#d32f2f' : 'var(--black)',
            }}
            placeholder={label}
            name={name}
            type={type}
            onBlur={() => setFieldTouched(name, true)}
            value={formValue}
            error={!!meta.error && !!meta.touched}
            onChange={(event: InputChangeEvent) => {
              onChange(event)
              setFieldValue(name, event.target.value)
            }}
          />
          <Grid sx={InputError}>{!!meta.error && !!meta.touched ? meta.error : ''}</Grid>
        </Grid>
      )}
    </Field>
  )
}
