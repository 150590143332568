import { useEffect } from 'react'

import { VisitUs } from 'components/VisitUs'
import { Feedbacks } from 'components/Feedbacks'
import { TestStart } from 'components/TestStart'
import { CourseType } from 'components/CourseType'
import { HowToStart } from 'components/HowToStart'
import { WhyChooseUs } from 'components/WhyChooseUs'
import { WelcomeScreen } from 'components/WelcomeScreen'
import { FrequentlyQuestions } from 'components/FrequentlyQuestions'

import { CustomMetaTitle } from 'utils/customMetaTitle'

import { HomePageWrapper } from './HomeStyles'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

export const Home = (): JSX.Element => {
  useEffect(() => {
    window.scrollTo(0, 0)
    CustomMetaTitle('Leader Language School')
  }, [])

  return (
    <Grid sx={HomePageWrapper}>
      <Box>
        <WelcomeScreen />
        <WhyChooseUs />
      </Box>
      <HowToStart />
      <CourseType />
      <TestStart />
      <Feedbacks />
      <VisitUs />
      <Box mb="70px">
        <FrequentlyQuestions />
      </Box>
    </Grid>
  )
}
