export const SelectedAvatarInitialValue = {
  key: '',
  _id: '',
  alt: '',
}

export const UserCourseInitialValues = {
  email: '',
  phone: '',
  name: '',
  age: '',
}

export const ResetPasswordInitialState = {
  email: '',
}

export const NewPasswordInitialState = {
  newPassword: '',
}
