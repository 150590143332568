import { useState, MouseEvent } from 'react'
import { useNavigate } from 'react-router-dom'

import { deleteCookies } from 'utils/userService'

import useNotificationStore from 'store/notification.store'

import Box from '@mui/material/Box'
import Menu from '@mui/material/Menu'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import Logout from '@mui/icons-material/Logout'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import MoreIcon from '@mui/icons-material/MoreVert'
import ListItemIcon from '@mui/material/ListItemIcon'

interface Props {
  name: string
  isAdmin: boolean
}

export const UserMenu = ({ name, isAdmin }: Props): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
  }
  const navigate = useNavigate()
  const { handleSetMessage, handleSetBanner } = useNotificationStore()

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleRedirectUser = (url: string = '/'): void => {
    navigate(url)
  }

  const handleUserExit = (): void => {
    handleSetMessage(`До зустрічі, ${name} 👌`)
    handleSetBanner(true)
    deleteCookies()
    handleRedirectUser('/')
  }

  const MENU_ITEMS = [
    {
      title: 'Вийти',
      icon: <Logout />,
      onClick: () => handleUserExit(),
      show: true,
    },
    {
      title: 'Додати питання',
      icon: <Logout />,
      onClick: () => handleRedirectUser('/test-add'),
      show: isAdmin,
    },
    {
      title: 'Тестові питання',
      icon: <Logout />,
      onClick: () => handleRedirectUser('/test-questions'),
      show: isAdmin,
    },
  ]

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Налаштування">
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <MoreIcon sx={{ color: 'var(--white)' }} />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            mt: 1.5,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {MENU_ITEMS.map(({ title, show, icon, onClick }, index) => (
          <Box key={index}>
            {show && (
              <MenuItem onClick={onClick && onClick}>
                <ListItemIcon>{icon}</ListItemIcon>
                <Typography variant="h6">{title}</Typography>
              </MenuItem>
            )}
          </Box>
        ))}
      </Menu>
    </>
  )
}
