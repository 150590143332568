import { Field } from 'formik'

import { InputChangeEvent } from 'types/common'

import { InputError } from './FormFieldStyles'

import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'

export const FormField = ({
  name,
  formValue,
  label,
  variant,
  onChange,
  setFieldTouched,
  setFieldValue,
  type = 'text',
}: any): JSX.Element => {
  return (
    <Field name={name}>
      {({ meta }) => (
        <Grid sx={{ width: '100%', fontSize: '14px' }}>
          <TextField
            sx={{
              width: '100%',
              fontSize: '14px',
            }}
            label={label}
            name={name}
            type={type}
            variant={variant}
            onBlur={() => setFieldTouched(name, true)}
            value={formValue}
            error={!!meta.error && !!meta.touched}
            onChange={(event: InputChangeEvent) => {
              onChange(event)
              setFieldValue(name, event.target.value)
            }}
          />
          <Grid sx={InputError}>{!!meta.error && !!meta.touched ? meta.error : ''}</Grid>
        </Grid>
      )}
    </Field>
  )
}
