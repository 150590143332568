import { useCallback, useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { QuizCard } from './QuizCard'
import { PopUp } from 'components/PopUp'
import { SuccessWindow } from '../SuccessWindow'

import useUserStore from 'store/user.store'
import useModalStore from 'store/modals.store'
import useLoaderStoreStore from 'store/loader.store'
import useNotificationStore from 'store/notification.store'

import { QuizProps } from 'types/quiz'
import { getUserLevel } from 'utils/getUserLevel'
import { shuffleArray } from 'utils/shuffleArray'

import testApi from 'api/testApi'
import userApi from 'api/userApi'
import ENDPOINTS from 'api/endpoints'

import successGif from 'assets/Modal/Done.gif'

import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

export const QuizTest = ({ language, level }: QuizProps): JSX.Element => {
  const navigate = useNavigate()
  const { handleSetLoading } = useLoaderStoreStore()
  const { userDetails, handleSetUserDetails } = useUserStore()
  const { handleSetModal, handleSetShowPopUp } = useModalStore()
  const { handleSetBanner, handleSetMessage } = useNotificationStore()

  const [score, setScore] = useState(0)
  const [number, setNumber] = useState(0)
  const [gameOver, setGameOver] = useState(true)
  const [questions, setQuestions] = useState([])
  const [userLevel, setUserLevel] = useState('')
  const [userAnswers, setUserAnswers] = useState([])

  const TOTAL_QUESTIONS: number = questions.length
  const btnStatus: boolean = userAnswers.length === number + 1 && number !== TOTAL_QUESTIONS - 1

  const { mutate: updateUser } = useMutation(userApi.updateUser, {
    onSuccess: ({ data }) => {
      handleSetUserDetails(data.updatedUser)
      handleSetMessage('Вітаємо з успішним проходженням тестування 🥳')
      handleSetBanner(true)
      navigate(`/courses`)
    },
    onError: ({ response }) => {
      handleSetBanner(true)
      handleSetMessage(response.data.message)
    },
    onSettled: () => {
      handleSetModal()
      handleSetShowPopUp(false)
    },
  })

  const {
    data: testList,
    isLoading,
    isFetching,
  } = useQuery([ENDPOINTS.TEST], () => testApi.getLanguageTest(language, level), {
    enabled: language !== '' && level !== '',
    cacheTime: 1,
    onSuccess: () => {
      setGameOver(false)
      setNumber(0)
      setUserAnswers([])
      setScore(0)
    },
    onError: () => {
      handleSetBanner(true)
      handleSetMessage('Упс, сталась помилка 🥺')
    },
    onSettled: () => {
      handleSetLoading(false)
      handleSetShowPopUp(false)
    },
  })

  useEffect(() => {
    if (testList) {
      setQuestions(
        testList.map((question) => ({
          ...question,
          answers: shuffleArray([...question.incorrect_answers, question.correct_answer]),
        }))
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testList])

  const handleUserResult = (): void => {
    setUserLevel(getUserLevel(language, level, score))
  }

  useEffect(() => {
    isFetching ? handleSetLoading(true) : handleSetLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching])

  const checkAnswer = useCallback(
    (event: any): void => {
      if (!gameOver) {
        const answer = event.currentTarget.value
        const correct = questions[number].correct_answer === answer
        if (correct) setScore((prev) => prev + 1)
        const answerObject = {
          question: questions[number].question,
          answer,
          correct,
          correctAnswer: questions[number].correct_answer,
        }
        setUserAnswers((prev) => [...prev, answerObject])
      }
    },
    [gameOver, number, questions]
  )

  const nextQuestion = useCallback((): void => {
    const nextQ: number = number + 1

    if (nextQ === TOTAL_QUESTIONS) {
      setGameOver(true)
    } else {
      setNumber(nextQ)
    }
  }, [TOTAL_QUESTIONS, number])

  const handleTestEnd = useCallback((): void => {
    updateUser({
      userId: userDetails._id,
      userData: {
        languageLevel: {
          [language]: { test: level, score },
        },
      },
    })
    handleSetShowPopUp(false)
  }, [handleSetShowPopUp, language, level, score, updateUser, userDetails._id])

  return (
    <>
      {!isLoading && testList && (
        <Grid
          container
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
          gap={1}
          sx={{ width: '100%' }}
        >
          {!gameOver && (
            <QuizCard
              questionNr={number + 1}
              totalQuestions={TOTAL_QUESTIONS}
              question={questions[number]?.question}
              answers={questions[number]?.answers}
              task={questions[number]?.task}
              userAnswer={userAnswers ? userAnswers[number] : undefined}
              callback={checkAnswer}
              modal={handleSetShowPopUp}
              nextButton={!gameOver && userAnswers?.length !== questions?.length ? true : false}
              endButton={
                userAnswers?.length === questions?.length && userAnswers?.length !== 0 ? true : false
              }
              handleUserResult={handleUserResult}
              btnStatus={btnStatus}
              nextQuestion={nextQuestion}
            />
          )}

          <PopUp>
            <SuccessWindow>
              <img src={successGif} alt="successGif" width={270} />
              <Grid container justifyContent="center" alignItems="center" flexDirection="column" gap={1}>
                <Typography variant="h5">Правильних відповідей: {score}</Typography>
                {userLevel && (
                  <Typography variant="h6">
                    Ваш рівень:{' '}
                    <span style={{ color: 'var(--main)' }}>{getUserLevel(language, level, score)}</span>
                  </Typography>
                )}

                <Button
                  variant="contained"
                  sx={{
                    width: { xs: '95%', sm: '50%', md: '320px' },
                    zIndex: 2,
                    p: '12px 24px',
                    fontSize: '16px',
                    height: '100%',
                    backgroundColor: 'var(--main)',
                    border: 'none',
                    '&:hover': { backgroundColor: 'var(--main)', color: 'white', border: 'none' },
                  }}
                  style={{ padding: '8px 35px', width: '60%', marginTop: '30px' }}
                  size="medium"
                  onClick={handleTestEnd}
                >
                  До курсів
                </Button>
              </Grid>
            </SuccessWindow>
          </PopUp>
        </Grid>
      )}
    </>
  )
}
