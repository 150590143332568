import { useNavigate } from 'react-router-dom'

import robot from 'assets/robot.svg'

import { GradientButton } from 'components/GradientButton'

import './TestStart.scss'
import { TestStartContent, TestStartWrapper } from './TestStartStyles'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

export const TestStart = (): JSX.Element => {
  const navigate = useNavigate()

  const handleTestStart = (): void => {
    navigate(`/language-test`)
  }

  return (
    <Grid sx={TestStartWrapper}>
      <Grid
        sx={TestStartContent}
        container
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        gap={5}
      >
        <img src={robot} alt="robot" className="robot" />
        <Typography variant="h1" color={'var(--white)'} sx={{ zIndex: 3 }}>
          Визначте свій
          <span style={{ color: 'var(--gold)' }}> рівень знань </span>
        </Typography>
        <Typography variant="h6" color={'var(--white)'} textAlign={{ xs: 'left', md: 'center' }}>
          Для того щоб підібрати для вас ідеальний навчальний план, нам дуже важливо дізнатися ваш рівень. Для
          цього, пройдіть наш тест. Або оберіть відповідний з наведених рівнів (з нуля до С1), відповідно до
          міжнародних стандартів CEFR.
        </Typography>
        <GradientButton onClick={handleTestStart} title="Перейти до тестування" />
      </Grid>
    </Grid>
  )
}
