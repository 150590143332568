import { useMemo } from 'react'
import { DefaultAvatarProps } from 'utils/types'

import { MediumSize, LargeSize, SmallSize, CommentSize } from './DefaultAvatarStyles'

import Avatar from '@mui/material/Avatar'

export const DefaultAvatar = ({ name, size }: DefaultAvatarProps): JSX.Element => {
  const AvatarSize = useMemo(
    () => ({
      small: SmallSize,
      medium: MediumSize,
      large: LargeSize,
      comment: CommentSize,
    }),
    []
  )

  return <Avatar sx={AvatarSize[size]}>{name}</Avatar>
}
