import { useMemo } from 'react'
import { ChartLine } from './components/MobileChartLine'
import useDeviceStore from 'store/deviceDetect.store'

import { FeedbackCounterProps, FeedbacksListData, RatingCounter } from 'types/feedbacks'

import { FaStar } from 'react-icons/fa'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Rating from '@mui/material/Rating'
import Typography from '@mui/material/Typography'
import MovingIcon from '@mui/icons-material/Moving'

const rating: RatingCounter = {
  5: 'Відмінно',
  4: 'Чудово',
  3: 'Добре',
  2: 'Задовільно',
  1: 'Погано',
}

export const FeedbacksCounter = ({ feedbacks }: FeedbackCounterProps): JSX.Element => {
  const { isMobile } = useDeviceStore()

  const formatedRating = useMemo(() => {
    return Object.keys(rating)
      .reverse()
      .map((key: keyof RatingCounter) => {
        const feedbacksRating = feedbacks.filter(({ rating }) => rating === Number(key))
        const feedbacksRatingPercentage = (feedbacksRating.length * 100) / feedbacks.length

        return {
          rating: key,
          label: rating[key],
          feedbacks: feedbacksRating,
          progress: feedbacksRatingPercentage,
        }
      })
  }, [feedbacks])

  const handleGetRating = (feedbacks: FeedbacksListData[]): number => {
    let totalCount = 0
    feedbacks.filter(({ rating }) => (totalCount += rating))

    return totalCount
  }

  const totalRating = useMemo(() => handleGetRating(feedbacks), [feedbacks])

  return (
    <>
      {isMobile ? (
        <Grid
          sx={{ borderBottom: '2px solid #f0f0f0', width: '100%' }}
          justifyContent="center"
          alignItems="center"
          container
          flexDirection="column"
        >
          <Typography variant="h1" gutterBottom fontSize="42px">
            {totalRating}.0
          </Typography>
          <Rating
            value={Math.round(totalRating / feedbacks.length)}
            readOnly
            size="medium"
            defaultValue={5}
            sx={{
              fontSize: '1.75rem',
            }}
            icon={<FaStar style={{ opacity: 1, padding: '0px 2px' }} fontSize="inherit" />}
            emptyIcon={<FaStar style={{ opacity: 0.5, padding: '0px 2px' }} fontSize="inherit" />}
          />
          <Typography
            variant="h6"
            sx={{ mt: '8px', mb: '15px' }}
            color="var(--light-gray)"
          >{`На основі ${feedbacks.length}  відгуків`}</Typography>
          <Grid container sx={{ mb: '15px' }}>
            {formatedRating.map(({ label, progress, rating, feedbacks }, index) => (
              <ChartLine width={progress} label={label} rating={rating} key={index} feedbacks={feedbacks} />
            ))}
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          textAlign="start"
          sx={{
            p: '10px 20px',
            boxShadow: '0px 0px 20px 10px rgb(28 37 43 / 3%)',
            borderRadius: '16px',
            backgroundColor: 'white',
          }}
        >
          <Grid item md={3} container flexDirection="column">
            <Typography variant="h3" gutterBottom color="#1a1a29">
              Total reviews
            </Typography>

            <Grid container flexWrap="nowrap" gap="10px" alignItems="center">
              <Typography variant="h1" fontSize="32px">
                {totalRating}.0
              </Typography>
              <Grid
                container
                flexWrap="nowrap"
                justifyContent="center"
                alignItems="center"
                gap="3px"
                sx={{
                  borderRadius: '24px',
                  backgroundColor: '#e2f9e6',
                  width: '90px',
                  height: '100%',
                  p: '2px',
                }}
              >
                <Typography variant="h6" color="#5a7863" fontSize="14px">
                  100%
                </Typography>
                <MovingIcon sx={{ color: '#5a7863' }} />
              </Grid>
            </Grid>

            <Typography variant="h6" color="#a8acb3">
              Total reviews
            </Typography>
          </Grid>

          <Box sx={{ height: '80%', width: '2px', backgroundColor: '#f1f0f080' }}></Box>

          <Grid item md={3} container flexDirection="column">
            <Typography variant="h3" gutterBottom>
              Середній рейтинг
            </Typography>

            <Grid container alignItems="center" gap={1} flexWrap="nowrap">
              <Typography variant="h1" fontSize="32px">
                {Math.round(totalRating / feedbacks.length)}.0
              </Typography>
              <Rating
                value={Math.round(totalRating / feedbacks.length)}
                readOnly
                size="medium"
                defaultValue={5}
                sx={{
                  fontSize: '1.75rem',
                }}
                icon={<FaStar style={{ opacity: 1, padding: '0px 2px' }} fontSize="inherit" />}
                emptyIcon={<FaStar style={{ opacity: 0.5, padding: '0px 2px' }} fontSize="inherit" />}
              />
            </Grid>

            <Typography variant="h6" color="#a8acb3">
              {`На основі ${feedbacks.length} відгуків`}
            </Typography>
          </Grid>

          <Box sx={{ height: '80%', width: '2px', backgroundColor: '#f1f0f080' }}></Box>

          <Grid item md={3}>
            {formatedRating.map(({ label, progress, rating, feedbacks }, index) => (
              <ChartLine width={progress} label={label} rating={rating} key={index} feedbacks={feedbacks} />
            ))}
          </Grid>
        </Grid>
      )}
    </>
  )
}
