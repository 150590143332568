import { TabPanelProps } from 'utils/types'

import Grid from '@mui/material/Grid'

export const CustomTab = (props: TabPanelProps): JSX.Element => {
  const { children, value, index, ...other } = props

  return (
    <Grid
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Grid container sx={{ width: '100%' }} spacing={{ xs: 3, md: 5, lg: 7, xl: 20 }}>
          {children}
        </Grid>
      )}
    </Grid>
  )
}
