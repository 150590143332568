export const QuizCardContentContainer = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  gap: '20px',
  p: '30px 20px',
}

export const QuizCardWrapper = {
  width: { xs: '100%', sm: '380px', md: '400px' },
  minHeight: '370px',
  boxShadow: '0px 0px 35px 15px rgb(28, 37, 43, 0.045)',
}

export const NextQuestionButton = {
  fontWeight: 500,
  mt: '10px',
  p: '10px 30px',
  height: '100%',
  borderRadius: '10px',
  transition: ' transform 0.2s ease-in-out',

  border: '1px solid var(--black)',
  color: 'var(--black)',

  ':hover': {
    color: 'var(--main)',
    transition: ' transform 0.2s ease-in-out',
    transform: 'scale(1.04, 1.04)',
  },

  ':disabled': {
    color: '#00000042',
  },
}

export const EndQuestionButton = {
  backgroundColor: 'var(--black)',
  p: '12px 28px',
  height: '100%',
  color: 'white',
  fontWeight: 600,

  '&:disabled': {
    backgroundColor: '#F7F4FF',
    color: 'var(--main)',
  },
}

export const UserAnswerButton = {
  width: '100%',
  borderRadius: '10px',
  background: 'none',
  color: 'var(--light-gray)',
  py: '11px',

  '&:disabled': {
    backgroundColor: 'white',
    color: 'var(--black)',
  },

  '&:hover': {
    backgroundColor: 'white',
    borderRadius: '1.8px solid black',
  },
}

export const TaskTitle = {
  mb: '15px',
  fontWeight: 600,
}

export const QuizHeaderCard = {
  textAlign: 'start',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '20px',
}
