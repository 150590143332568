import { useState, useEffect } from 'react'

function useDebounce<T>(value: T, delay: number) {
	const [debouncedValue, setDebouncedValue] = useState<T>(value)

	useEffect(() => {
		const handler = setTimeout(() => setDebouncedValue(value), delay)

		return () => clearTimeout(handler)
	}, [value, delay])

	return debouncedValue
}

export default useDebounce