import { ContactUsForm } from '../ContactUsForm'

import useDeviceStore from 'store/deviceDetect.store'

import { VisitUsWrapper, VisitUsWrapperMobile } from './VisitUsStyles'
import './VisitUs.scss'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

export const VisitUs = (): JSX.Element => {
  const { isMobile } = useDeviceStore()

  return (
    <Grid
      sx={isMobile ? VisitUsWrapperMobile : VisitUsWrapper}
      container
      className={`${!isMobile && 'mapWrapper'}`}
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Grid container alignItems="center" textAlign="center" gap={3} flexDirection="column">
        <Typography variant="h1" color="white">
          Зв’яжіться з <span style={{ color: 'var(--main)' }}> нами </span>
        </Typography>
        <Typography variant="h6" color="var(--white)">
          Залишились запитання? <br /> Чи просто хочеться дізнатися все і одразу?
          <br /> Напишіть нам і ми радо про все детально розповімо 🤗
        </Typography>
      </Grid>
      <Grid width={{ xs: '100%' }} container alignItems="center" justifyContent="center">
        <ContactUsForm />
      </Grid>
    </Grid>
  )
}
