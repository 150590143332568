import { WhyChooseUsCard } from './WhyChooseUsCard'
import { ChooseUsWrapper } from './WhyChooseUsStyles'

import heart from 'assets/WhyChooseUs/heart.svg'
import world from 'assets/WhyChooseUs/world.svg'
import people from 'assets/WhyChooseUs/people.svg'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

export const WhyChooseUs = (): JSX.Element => {
  return (
    <Grid
      container
      width="100%"
      justifyContent="center"
      alignItems="center"
      sx={{ p: { xs: '0px 0px', md: '0px 120px' } }}
    >
      <Grid sx={ChooseUsWrapper}>
        <Typography variant="h1">
          Чому <span style={{ color: 'var(--main)' }}> обирають нас? </span>
        </Typography>
        <Typography variant="h6" color="var(--light-gray)">
          Все дуже просто, бо ми школа з душею і ми не просто навчаємо вас іноземної, ми вас в неї закохуємо
          ❤️
          <br />А ще…
        </Typography>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          sx={{ mt: { xs: 1, md: 3 } }}
          gap={{ xs: 5, md: 5 }}
        >
          <WhyChooseUsCard
            title="Чуйні викладачі"
            text="Ми знаємо, наскільки важливою є підтримка в навчанні, тому запрошуємо до нашої школи лише добрих і чуйних професіоналів, які знають, що роблять і кайфують від цього."
            icon={heart}
          />
          <WhyChooseUsCard
            title="«Прокачаний велосипед»"
            text="Ми не винаходимо «велосипед», а просто вдосконалюємо його! 
            Комунікативна методика + доступна граматика + інтерактивні вправи/відео/ігри + моделювання життєвих мовних ситуацій = ідеальний рецепт ❤️"
            icon={world}
          />
          <WhyChooseUsCard
            title="Все для тебе"
            text="В нас дійсно індивідуальний підхід до кожного студента та групи. Наші заняття проходять як наживо в комфортних умовах, в самому центрі Ужгорода, і маленьких групах 4-6 чол, так і онлайн."
            icon={people}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
