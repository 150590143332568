import { ModalWrapper } from './PopUp'
import popUpStore from 'store/modals.store'

import Box from '@mui/material/Box'
import Fade from '@mui/material/Fade'
import Modal from '@mui/material/Modal'
import Backdrop from '@mui/material/Backdrop'

interface layoutProps {
  children: React.ReactNode
}

export const PopUp = ({ children }: layoutProps): JSX.Element => {
  const { isShow, handleSetShowPopUp } = popUpStore()

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isShow}
        onClose={() => handleSetShowPopUp(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isShow}>
          <Box sx={ModalWrapper}>{children}</Box>
        </Fade>
      </Modal>
    </>
  )
}
