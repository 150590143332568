export const TestStartWrapper = {
  width: '100%',
  p: { xs: 0, md: '0px 80px 0px', lg: '0px 100px 0px' },
  mt: { xs: 0, md: '50px' },
  overflow: 'hidden',
}

export const TestStartContent = {
  backgroundColor: '#1c252b',
  opacity: 1,
  backgroundImage:
    'repeating-radial-gradient( circle at 0 0, transparent 0, #1c252b 45px ), repeating-linear-gradient( #232e3555, #232e35 )',
  width: '100%',
  borderRadius: { xs: 0, md: '24px' },
  p: { xs: '50px 30px', md: '60px', lg: '60px' },
}
