import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { deleteCookies } from 'utils/userService'

import useUserStore from 'store/user.store'
import useDeviceStore from 'store/deviceDetect.store'
import useNotificationStore from 'store/notification.store'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'

export const SettingItem = ({
  icon,
  title,
  iconColor = 'var(--black)',
  activeComponent,
}: any): JSX.Element => {
  const [showComponent, setshowComponent] = useState(false)
  const { userDetails } = useUserStore()
  const navigate = useNavigate()
  const { isMobile } = useDeviceStore()

  const { handleSetBanner, handleSetMessage } = useNotificationStore()

  const exitUser = () => {
    navigate('/')
    deleteCookies()
    handleSetMessage(`До зустрічі, ${userDetails.name} 👌`)
    handleSetBanner(true)
  }

  return (
    <Grid container flexDirection="column" gap={2.5} sx={{ cursor: 'pointer' }}>
      <Grid
        container
        alignItems="center"
        flexWrap="nowrap"
        justifyContent="space-between"
        onClick={() => {
          title === 'Вийти з профілю' ? exitUser() : setshowComponent(!showComponent)
        }}
      >
        <Grid container alignItems="center" gap={2} flexWrap="nowrap">
          <Grid
            sx={{
              p: isMobile ? '7px 8px' : '12px 13px',
              borderRadius: '10px',
              backgroundColor: iconColor,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '48px',
              height: '48px',
            }}
          >
            {icon}
          </Grid>
          <Typography variant="h6" color="#484848">
            {title}
          </Typography>
        </Grid>
        <NavigateNextIcon
          sx={{
            color: 'var(--light-gray)',
            transform: showComponent ? 'rotate(90deg)' : 0,
            transition: 'all 0.08s linear',
          }}
        />
      </Grid>
      <Grid sx={{ display: showComponent ? 'block' : 'none', width: '100%' }}>
        {showComponent && activeComponent}
      </Grid>
    </Grid>
  )
}
