import axios, { AxiosInstance } from 'axios'
import { UserDataStorage } from 'utils/types'
import { deleteCookies, getUserData, setCookies } from 'utils/userService'

const headers = {
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json',
}

const apiClient: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers,
})

const authClient: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers,
})

apiClient.interceptors.response.use(
  function (response) {
    return response
  },

  async function (error) {
    const originalRequest = error.config

    if (error.response.status !== 401) {
      return Promise.reject(error)
    }

    if (!originalRequest._retry) {
      originalRequest._retry = true
      const userData: UserDataStorage = getUserData()
      if (userData.refreshToken) {
        try {
          const { data } = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/auth/refresh`,
            {},
            {
              headers: {
                Authorization: `Bearer ${userData.refreshToken}`,
              },
            }
          )
          if (data) {
            setCookies({
              ...userData,
              accessToken: data.accessToken,
            })
          } else {
            deleteCookies()
          }
          axios.defaults.headers.common['Authorization'] = `Bearer ${data.accessToken}`
        } catch (error) {
          deleteCookies()
        }
      }
      return apiClient(originalRequest)
    }
    return Promise.reject(error)
  }
)

apiClient.interceptors.request.use(
  (config) => {
    const userData: UserDataStorage = getUserData()

    if (userData) {
      config.headers.Authorization = `Bearer ${userData?.accessToken}`
    }
    config.baseURL = process.env.REACT_APP_BASE_URL
    return config
  },
  (err) => {
    return Promise.reject(err)
  }
)

export { apiClient }
export { authClient }
