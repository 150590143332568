import { useState } from 'react'

import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'

import { FormField } from 'components/FormField'

import { setCookies } from 'utils/userService'
import { InputChangeEvent } from 'types/common'
import { INewPassword, ParamsType } from 'utils/types'
import { NewPasswordInitialState } from 'utils/InitialValues'

import useLoaderStoreStore from 'store/loader.store'
import useNotificationStore from 'store/notification.store'

import './index.scss'
import { AuthButton } from '../Auth/AuthStyles'
import { ResetPasswordContent, ResetPasswordWrapper } from '../ResetPassword/ResetPasswordStyles'

import userApi from 'api/userApi'

import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

export const NewPassword = (): JSX.Element => {
  const { handleSetLoading } = useLoaderStoreStore()
  const { handleSetBanner, handleSetMessage } = useNotificationStore()

  const { id } = useParams<ParamsType>()
  const [userData, setUserData] = useState<INewPassword>(NewPasswordInitialState)

  const handleChange = (event: InputChangeEvent): void => {
    const { value } = event.target
    setUserData({ newPassword: value })
  }

  const { mutate: sendPassword } = useMutation(userApi.sendPasswordRequest, {
    onSuccess: ({ data }) => {
      userLogin({ email: data.email, password: userData.newPassword })
    },
    onError: ({ response }) => {
      handleSetBanner(true)
      handleSetMessage(response.message)
      handleSetLoading(false)
    },
  })

  const { mutate: userLogin } = useMutation(userApi.userLogin, {
    onSuccess: ({ data }) => {
      setCookies(data)
      handleSetBanner(true)
      handleSetMessage(`З поверненням, ${data.name} 👋`)
      handleSetLoading(false)
    },
    onError: ({ response }) => {
      handleSetBanner(true)
      handleSetMessage(response.data.message)
      handleSetLoading(false)
    },
  })

  const validationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required("Обов'язкове поле для заповнення")
      .min(8, 'Пароль має містити мінімум 8 символів')
      .matches(/[0-9]/, 'Пароль має містити хоча б 1 цифру')
      .matches(/[a-z]/, 'Пароль має містити хоча б 1 літеру з великої букви')
      .matches(/[A-Z]/, 'Пароль має містити хоча б 1 літеру з малої букви'),
  })

  const onSubmit = (): void => {
    handleSetLoading(true)
    sendPassword({ token: id, newPassword: userData.newPassword })

    // sendPasswordRequest(id, userData.newPassword)
    //   .then(({ data }) => {
    //     userLogin({ email: data.email, password: userData.newPassword })
    //   })
    //   .catch(({ response }) => {
    //     handleSetBanner(true)
    //     handleSetMessage(response.message)
    //     handleSetLoading(false)
    //   })
  }

  return (
    <Grid sx={ResetPasswordWrapper}>
      <Grid sx={ResetPasswordContent}>
        <Typography variant="h5" fontWeight={500}>
          Введіть ваш новий пароль 😇
        </Typography>

        <Formik
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          initialValues={NewPasswordInitialState}
        >
          {({ setFieldValue, setFieldTouched }) => (
            <Form className="password-form-wrapper">
              <FormField
                variant="outlined"
                name="newPassword"
                label="Пароль"
                formValue={userData.newPassword}
                onChange={handleChange}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
              />
              <Button variant="contained" sx={{ ...AuthButton, mt: 0 }} size="medium" type="submit">
                Відправити
              </Button>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  )
}
