import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import AddIcon from '@mui/icons-material/Add'
import Accordion from '@mui/material/Accordion'
import Typography from '@mui/material/Typography'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'

export const QuestionAccordion = (): JSX.Element => {
  const [expanded, setExpanded] = useState<string | false>('')

  const handleChange =
    (panel: string) =>
    (event: React.SyntheticEvent, newExpanded: boolean): void => {
      setExpanded(newExpanded ? panel : false)
    }

  return (
    <div>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<AddIcon sx={{ color: 'var(--main)' }} />}
          aria-controls="panel1d-content"
          id="panel1d-header"
        >
          <Typography variant="h5" sx={{ mr: '15px', fontSize: '16px' }}>
            Чи є можливість онлайн занять?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="h6">
            Так, звісно. Але наразі онлайн в нас тільки індивідуальні заняття або в парах. Групові все ж
            переважно наживо в нашій школі в центрі м. Ужгород.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<AddIcon sx={{ color: 'var(--main)' }} />}
          aria-controls="panel2d-content"
          id="panel2d-header"
        >
          <Typography variant="h5" sx={{ mr: '15px', fontSize: '16px' }}>
            Навчання проходить по підручниках чи без?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="h6">
            У нас дуже комплексно підібрані курси. Студенти навчаються по спеціальним підручникам (комплект
            Student’s Book + Workbook), які розроблені різними поважними видавництвами, такими як Oxford,
            Pearson, Macmillan та іншими. Та це тільки база, бо програма доповнюється різними додатковими
            вправами, рольовими та настільними іграми, відеороликами та навіть переглядом фільмів.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<AddIcon sx={{ color: 'var(--main)' }} />}
          aria-controls="panel3d-content"
          id="panel3d-header"
        >
          <Typography variant="h5" sx={{ mr: '15px', fontSize: '16px' }}>
            А можна самостійно обрати тему уроку і спрямування курсу?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="h6">
            Можна все обсудити з викладачем і домовитися про необхідну тему заздалегідь, без проблем. Але якщо
            Вам потрібна спеціалізована англійська (напр. IT, Business чи медичний нахил), то про це потрібно
            попередити перед початком курсу, так як це має бути інакша програма і ціна.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary
          expandIcon={<AddIcon sx={{ color: 'var(--main)' }} />}
          aria-controls="panel4d-content"
          id="panel4d-header"
        >
          <Typography variant="h5" sx={{ mr: '15px', fontSize: '16px' }}>
            А у вас є групи для діток?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="h6">
            Так, але тільки для діток шкільного віку, тобто починаючи з першого класу.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary
          expandIcon={<AddIcon sx={{ color: 'var(--main)' }} />}
          aria-controls="panel5d-content"
          id="panel5d-header"
        >
          <Typography variant="h5" sx={{ mr: '15px', fontSize: '16px' }}>
            Я не знаю який саме в мене рівень, чи можна його визначити?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="h6">
            Звісно, все дуже просто. До початку проходите наш тест на сайті{' '}
            <Link to="/language-test" style={{ textDecoration: 'none' }}>
              <span style={{ color: 'var(--main)', fontWeight: 600 }}>ТУТ</span>
            </Link>
            , а потім ми ще поспілкуємося з вами усно, щоб все точно визначити. І підберемо необхідний курс.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
        <AccordionSummary
          expandIcon={<AddIcon sx={{ color: 'var(--main)' }} />}
          aria-controls="panel6d-content"
          id="panel6d-header"
        >
          <Typography variant="h5" sx={{ mr: '15px', fontSize: '16px' }}>
            Що робити, якщо я не зможу прийти на урок?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="h6">
            Індивідуальне заняття завжди можна перенести, просто потрібно попередити нас якомога скоріше, як
            мінімум за 2-3 год до уроку.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}
