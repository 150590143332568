import * as React from 'react'
import useModalStore from 'store/modals.store'

import { ModalWrapper } from './TransitionsModalStyles'

import Box from '@mui/material/Box'
import Fade from '@mui/material/Fade'
import Modal from '@mui/material/Modal'
import Backdrop from '@mui/material/Backdrop'

interface layoutProps {
  children: React.ReactNode
  open?: boolean
  onClose?: (open: boolean) => void
}

export default function TransitionsModal({ open, onClose, children }: layoutProps): JSX.Element {
  const { modal, handleSetModal } = useModalStore()

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open || modal}
      onClose={onClose || handleSetModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open || modal}>
        <Box sx={ModalWrapper}>{children}</Box>
      </Fade>
    </Modal>
  )
}
