export const HeaderWrapper = {
  height: 'var(--header-height)',
  backgroundColor: 'var(--main)',
  display: 'flex',
  alignItems: 'center',
  padding: { xs: '0px 50px', md: '0px 100px', lg: '0px 100px' },
  width: '100%',
  top: 0,
  zIndex: 100,
  position: 'sticky',
}

export const RegistrationButton = {
  backgroundColor: 'black',
  fontWeight: 600,
  p: '10px 36px',
  '&:hover': {
    background: 'white',
    color: 'black',
  },
}

export const LinkContainer = {
  marginLeft: 'auto',
  marginRight: 'auto',
  display: 'flex',
  flexWrap: 'nowrap',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: 600,
  gap: '40px',
}

export const AuthContainer = {
  display: 'flex',
  flexWrap: 'nowrap',
  gap: 2,
}

export const AuthBtn = {
  backgroundColor: 'inherit',
  border: '2px solid white',
  borderRadius: '24px',
  fontSize: '15px',
  fontWeight: 600,
  p: '7px 24px',
  '&:hover': {
    background: 'white',
    color: 'black',
  },
}
