import { useEffect, useState, useMemo } from 'react'
import * as Yup from 'yup'
import { useMutation, useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { FormikErrors, FormikTouched } from 'formik'

import { InfoBar } from './components/InfoBar'
import { InfoPaper } from './components/InfoPaper'
import { SuccessWindow } from 'components/SuccessWindow'
import TransitionsModal from 'components/TransitionsModal'

import useUserStore from 'store/user.store'
import useLoaderStoreStore from 'store/loader.store'
import useDeviceStore from 'store/deviceDetect.store'
import useNotificationStore from 'store/notification.store'

import ENDPOINTS from 'api/endpoints'
import courseApi from 'api/courseApi'
import { handleCourseInvite } from 'api/emailApi'

import {
  emailJsCourseServiceId,
  emailJsCourseTemplateId,
  emailJsCoursePublicKey,
  emailJsCourseTemplateIdWithLevel,
} from 'utils/constants'
import { CustomMetaTitle } from 'utils/customMetaTitle'
import { UserCourseInitialValues } from 'utils/InitialValues'
import { FormikErrorsPropTypes, ParamsType } from 'utils/types'

import robot from 'assets/AddToCourse/robot.jpg'

import './CourseDetails.scss'

import { Button, Divider, Grid, Typography } from '@mui/material'
import { WithoutLevelForm } from './Forms/WithoutLevelForm'
import { InputChangeEvent } from 'types/common'

export const CourseDetails = (): JSX.Element => {
  const [withoutLevel, setWithoutLevel] = useState(false)
  const [courseDetails, setCourseDetails] = useState<any>({})
  const [userData, setUserData] = useState(UserCourseInitialValues)
  const [formState, setFormState] = useState({
    buttonState: '',
    errors: {},
  })

  const { handleSetBanner, handleSetMessage } = useNotificationStore()
  //const { courseModal, handleSetCourseModal } = popOpStore()
  const [showCourseModal, setShowCourseModal] = useState(false)
  const { handleSetLoading } = useLoaderStoreStore()
  const { isMobile } = useDeviceStore()
  const { userDetails } = useUserStore()
  const { id } = useParams<ParamsType>()
  const navigate = useNavigate()

  const { data, isLoading, isSuccess } = useQuery(
    [ENDPOINTS.COURSES, id],
    () => courseApi.getCourseById(id),
    {
      onError: ({ response }: any) => {
        handleSetLoading(false)
        handleSetBanner(true)
        handleSetMessage(response.data.message)
      },
    }
  )

  const coursePrise: string = useMemo(
    () =>
      courseDetails.lessonType === 'group' || courseDetails.lessonType === 'children' ? 'місяць' : 'година',
    [courseDetails.lessonType]
  )
  const userLanguageSkill = useMemo(
    () => !!Object.keys(userDetails).length && !!userDetails?.languageLevel[courseDetails?.language]?.length,
    [courseDetails?.language, userDetails]
  )

  const userTests = useMemo(
    () =>
      userLanguageSkill &&
      userDetails?.languageLevel[courseDetails?.language]?.map(({ test, score }, index: number) => {
        return `${index}: ${test}, score: ${score}`
      }),
    [courseDetails?.language, userDetails?.languageLevel, userLanguageSkill]
  )

  const { mutate: deleteCourse } = useMutation(ENDPOINTS.COURSES, () => courseApi.deleteCourse(id), {
    onSuccess: () => {
      handleSetMessage('Курси видалено')
      handleSetBanner(true)
    },
    onError: ({ response }) => {
      handleSetBanner(true)
      handleSetMessage(response.data.message)
      navigate('/')
    },
    onSettled: () => {
      navigate('/')
    },
  })

  const chipData: string[] = useMemo(
    () => [
      courseDetails?.age,
      courseDetails?.lessonTime,
      courseDetails?.peopleAmount,
      courseDetails?.frequency,
      courseDetails?.level,
    ],
    [courseDetails]
  )

  const courseType = (name: string): string => {
    switch (name) {
      case 'group':
        return 'Групові заняття'
      case 'individual':
        return 'Індивідуальні заняття'
      case 'children':
        return 'Для дітей'
      case 'semi-individual':
        return 'Напівіндивідуальні заняття'
      default:
        return 'Індивідуальні заняття'
    }
  }

  const handleRedirectUser = (): void => {
    navigate('/language-test')
  }

  const handleCourseEdit = (): void => {
    navigate(`/course-edit/${id}`)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    if (data) {
      setCourseDetails(data)
      CustomMetaTitle(`LS • ${data.title}`)
    }
  }, [data])

  useEffect(() => {
    if (isLoading) {
      handleSetLoading(true)
    } else {
      handleSetLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  const handlerChange = (event: InputChangeEvent): void => {
    const { name, value } = event.target
    setUserData({ ...userData, [name]: value })
  }

  const onSubmit = (): void => {
    setFormState({ ...formState, buttonState: 'loading' })
    handleCourseInvite(
      emailJsCourseServiceId,
      emailJsCourseTemplateId,
      {
        ...userData,
        language: courseDetails.language,
        course: courseDetails.title,
        type: courseDetails.lessonType,
      },
      emailJsCoursePublicKey
    )
      .then(({ status }): void => {
        if (status === 200) {
          setUserData(UserCourseInitialValues)
          setFormState({ ...formState, buttonState: 'success' })
          handleSetMessage('Супер! Mи з вами скоро звʼяжемося ❤️')
        } else {
          handleSetMessage('Щось пішло не так 🥺')
          setFormState({ ...formState, buttonState: 'error' })
        }
      })
      .finally(() => {
        setShowCourseModal(false)
        handleSetBanner(true)
        setWithoutLevel(false)
      })
  }

  const onSubmitUserLevel = (): void => {
    handleSetLoading(true)
    handleCourseInvite(
      emailJsCourseServiceId,
      emailJsCourseTemplateIdWithLevel,
      {
        name: userDetails.name,
        age: userDetails.age,
        email: userDetails.email,
        phone: userDetails.phone,
        course: courseDetails.title,
        type: courseDetails.lessonType,
        language: courseDetails.language,
        level: userTests.join('|'),
        id: courseDetails._id,
      },
      emailJsCoursePublicKey
    )
      .then(({ status }): void => {
        setShowCourseModal(false)
        if (status === 200) {
          handleSetMessage('Супер! Mи з вами скоро звʼяжемося ❤️')
        } else {
          handleSetMessage('Щось пішло не так 🥺')
        }
      })
      .finally(() => {
        handleSetBanner(true)
        handleSetLoading(false)
      })
  }

  const validationSchema = Yup.object().shape({
    age: Yup.string().required("Обов'язкове поле"),
    name: Yup.string().required("Обов'язкове поле"),
    phone: Yup.string().required("Обов'язкове поле"),
    email: Yup.string().email('Введіть правильний email').required("Обов'язкове поле"),
  })

  const handleClick = (
    errors: FormikErrors<FormikErrorsPropTypes>,
    touched: FormikTouched<FormikErrorsPropTypes>
  ) => {
    if (Object.keys(errors).length > 0 || Object.keys(touched).length === 0)
      setFormState({ ...formState, buttonState: 'error' })
    setTimeout(() => {
      setFormState({ ...formState, buttonState: '' })
    }, 3000)
  }

  return (
    <Grid
      container
      flexDirection="column"
      gap={{ xs: 5, md: 8 }}
      sx={{
        p: { xs: '60px 24px', sm: '75px 70px', lg: '70px 100px' },
      }}
    >
      {isSuccess && data && (
        <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-between' }}
          flexWrap={{ xs: 'wrap', md: 'nowrap' }}
          alignItems="center"
          gap={{ xs: 4, md: 7 }}
          sx={{ mt: '-15px' }}
        >
          <img src={courseDetails?.image} alt="course-img" className="details-image" />
          <Grid
            container
            gap={{ xs: 3, md: 2 }}
            flexDirection="column"
            alignItems="center"
            justifyContent={{ sm: 'center', md: 'flex-start' }}
            textAlign={{ xs: 'left', sm: 'center', md: 'left' }}
          >
            <Grid container flexDirection="column" alignItems="flex-start" justifyContent="flex-start">
              <Typography variant="body1" fontWeight={600}>
                {courseDetails?.title}
              </Typography>
              {isMobile && (
                <Typography variant="h6" color="var(--light-gray)">
                  {courseType(courseDetails.lessonType)}
                </Typography>
              )}
            </Grid>
            {isMobile && <InfoBar rating={courseDetails.rating} location={courseDetails.location} />}
            <Grid>
              {isMobile && (
                <Typography variant="h5" component="div" gutterBottom>
                  Опис курсу
                </Typography>
              )}
              <Typography variant="h6" component="div" color="var(--light-gray)">
                {courseDetails?.description}
              </Typography>
            </Grid>
            {!isMobile && <Divider />}

            <Grid
              container
              flexWrap={{ xs: 'wrap', md: 'nowrap' }}
              alignItems={{ xs: 'center', md: 'flex-start' }}
              flexDirection={{ xs: 'row', md: 'column' }}
              gap={3}
              justifyContent={{ xs: 'center', md: 'flex-start' }}
            >
              {!isMobile && (
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  color="var(--main)"
                  fontWeight={600}
                  sx={{ mt: '10px' }}
                >
                  Вартість: {coursePrise === 'година' && 'від '}
                  {courseDetails?.price} грн / {coursePrise}
                </Typography>
              )}
              <Button
                variant="contained"
                onClick={() => setShowCourseModal(true)}
                sx={{
                  backgroundColor: 'var(--black)',
                  py: '14px',
                  width: { xs: '100%', sm: '180px', md: '100%' },
                  fontWeight: 600,
                  fontSize: '16px',
                }}
                size="medium"
              >
                Хочу навчатись
              </Button>
              {userDetails?.role === 'admin' && (
                <Grid container flexWrap={{ xs: 'wrap', md: 'nowrap' }} gap={{ xs: 0, md: 2 }}>
                  <Button
                    variant="outlined"
                    onClick={() => deleteCourse()}
                    sx={{
                      py: '9px',
                      width: { xs: '100%', sm: '180px' },
                    }}
                    size="medium"
                  >
                    Видалити курс
                  </Button>

                  <Button
                    variant="outlined"
                    onClick={handleCourseEdit}
                    sx={{
                      py: '9px',
                      width: { xs: '100%', sm: '180px' },
                    }}
                    size="medium"
                  >
                    Редагувати курс
                  </Button>
                </Grid>
              )}
              {isMobile && (
                <Typography gutterBottom variant="h6" component="div" color="var(--main)" fontWeight={600}>
                  {coursePrise === 'година' && 'від '}
                  {courseDetails?.price} грн / {coursePrise}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}

      <Grid
        container
        flexWrap={{ md: 'wrap', lg: 'nowrap' }}
        justifyContent="space-between"
        width="100%"
        gap={{ xs: 4, lg: 0 }}
        sx={{ mb: '20px' }}
      >
        <InfoPaper title="Переваги:" infoData={courseDetails.benefits} />
        <InfoPaper title="Деталі:" infoData={chipData} />
      </Grid>

      {showCourseModal && (
        <TransitionsModal
          open={showCourseModal}
          onClose={() => {
            setWithoutLevel(false)
            setShowCourseModal(false)
          }}
        >
          <SuccessWindow>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              gap={2}
              width="100%"
            >
              {!withoutLevel && <img src={robot} alt="img" style={{ width: '180px' }} />}
              <Typography variant="h5">Запис на курс</Typography>
              {!withoutLevel && (
                <Grid container width="100%" flexDirection="column" gap={2}>
                  {userLanguageSkill ? (
                    <Button
                      variant="contained"
                      style={{ padding: '12px 26px', backgroundColor: 'var(--black)' }}
                      size="medium"
                      onClick={onSubmitUserLevel}
                    >
                      Вказати поточний рівень
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      style={{ padding: '12px 26px', backgroundColor: 'var(--black)' }}
                      size="medium"
                      onClick={handleRedirectUser}
                    >
                      Пройти тестування
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    style={{ padding: '12px 26px' }}
                    size="medium"
                    onClick={() => setWithoutLevel(true)}
                  >
                    Немає рівня
                  </Button>
                </Grid>
              )}
              {withoutLevel && (
                <WithoutLevelForm
                  onSubmit={onSubmit}
                  userData={userData}
                  formState={formState}
                  handleClick={handleClick}
                  validationSchema={validationSchema}
                  handlerChange={handlerChange}
                />
              )}
            </Grid>
          </SuccessWindow>
        </TransitionsModal>
      )}
    </Grid>
  )
}
