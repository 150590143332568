export const ModalWrapper = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {
    xs: '75%',
    sm: '50%',
    md: '40%',
    lg: '26%',
    xl: '23%',
  },
  bgcolor: 'background.paper',
  p: {
    xs: '30px 20px',
  },
  borderRadius: {
    xs: 4,
  },
  outline: 'none',
}
