export const HowToStartWrapper = {
  // backgroundColor: 'var(--black)',
  backgroundColor: 'var(--black)',
  opacity: 1,
  backgroundImage:
    'repeating-radial-gradient( circle at 0 0, transparent 0, #1c252b 50px ), repeating-linear-gradient( #232e3555, #232e35 )',
  p: { xs: '50px 24px', sm: '50px 60px', md: '80px 100px' },
  gap: 7,
  flexDirection: 'column',
  justifyContent: 'center',
  width: '100%',
  textAlign: 'left',
}

export const HowToStartTextContainer = {
  width: { xs: '100%', md: '70%', lg: '50%' },
  display: 'flex',
  flexWrap: 'nowrap',
  gap: 1,
  textAlign: 'left',
}

export const ImageWrapper = {
  width: { xs: '87%', sm: '80%', md: '30%' },
  mt: '70px',
  ml: { md: '40px' },
  position: 'absolute',
  opacity: { xs: 0.18, lg: 1 },
  alignItems: 'center',
  justifyContent: 'center',
  // flex: 3,
}

export const BlobImage = {
  width: { xs: '100%', sm: '700px' },
  position: 'absolute',
}

export const TextContainer = {
  justifyContent: 'center',
  alignItems: { xs: 'center', md: 'flex-end' },
  flexDirection: 'column',
  width: '100%',
  gap: 7,
  zIndex: 10,
}

export const TitleTextContainer = {
  gap: 2,
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  textAlign: 'center',
}
