export const ContactsFormWrapper = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  borderRadius: '22px',
  flexDirection: { xs: 'column', md: 'row' },
  boxShadow: '0px 0px 35px 15px rgb(28, 37, 43, 0.045)',
  backgroundColor: 'white',
  maxWidth: '1200px',
}
export const ContactsUserContainer = {
  p: { xs: '30px', sm: '60px', md: '50px 30px 50px 50px', lg: '80px' },
  width: { xs: '100%', md: '48%', lg: '51%' },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: 3,
}
export const ContactsUserInfo = {
  p: { xs: '10px 20px 30px', sm: '0px 60px 50px', md: '50px 20px', lg: '80px 80px 0px 0px' },
  width: { xs: '100%', md: '45%', lg: '40%' },
  gap: { xs: 1, md: 3 },
  flexDirection: 'column',
  display: 'flex',
}

export const ContactsBackgroundWrapper = {
  height: { xs: '100%', md: '120vh' },
  mt: '-18px',
  p: { xs: '60px 24px', sm: '60px', lg: '80px' },
  width: '100%',
}
