import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useQueryClient, useMutation, useQuery } from 'react-query'
import { Formik, Form, Field } from 'formik'

import { InputChangeEvent } from 'types/common'
import useNotificationStore from 'store/notification.store'

import testApi from 'api/testApi'
import ENDPOINTS from 'api/endpoints'

import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import InputLabel from '@mui/material/InputLabel'
import CardContent from '@mui/material/CardContent'
import FormControl from '@mui/material/FormControl'
import DeleteIcon from '@mui/icons-material/Delete'
import Select, { SelectChangeEvent } from '@mui/material/Select'

const initialValues = {
  task: '',
  question: '',
  correct_answer: '',
  incorrect_answers: [],
  language: '',
  level: '',
}

export const TestQuestions = (): JSX.Element => {
  const { handleSetBanner, handleSetMessage } = useNotificationStore()
  const client = useQueryClient()

  const [editStatus, setEditStatus] = useState(false)
  const [testData, setTestData] = useState<any>(initialValues)
  const [incorrectAnswers, setIncorrectAnswers] = useState('')
  const [questionId, setQuestionId] = useState('')

  const [testQuestions, setTestQuestions] = useState([])
  const [userState, setUserState] = useState({
    language: '',
    level: '',
  })

  const { data: testList } = useQuery({
    queryKey: [ENDPOINTS.TEST],
    queryFn: () => testApi.getLanguageTest(userState.language, userState.level),
    enabled: userState.language !== '' && userState.level !== '',
  })

  const { data: questionDetails } = useQuery('question', () => testApi.getTestQuestion(questionId), {
    enabled: questionId !== '',
  })

  const { mutate: deleteQuestion } = useMutation(testApi.deleteTestQuestion, {
    onSuccess: ({ message }) => {
      handleSetBanner(true)
      handleSetMessage(message)
      client.invalidateQueries({ queryKey: [ENDPOINTS.TEST] })
    },
    onError: ({ response }) => {
      handleSetBanner(true)
      handleSetMessage(response.data.message)
    },
  })

  const { mutate: updateQuestion } = useMutation(
    'question',
    () =>
      testApi.updateTestQuestion(testData._id, {
        task: testData.task,
        question: testData.question,
        correct_answer: testData.correct_answer,
        incorrect_answers:
          testData.incorrect_answers.length > 0 ? testData.incorrect_answers : incorrectAnswers,
        language: testData.language,
        level: testData.level,
      }),
    {
      onSuccess: ({ message }) => {
        handleSetBanner(true)
        handleSetMessage(message)
        client.invalidateQueries({ queryKey: [ENDPOINTS.TEST] })
      },
      onError: ({ response }) => {
        handleSetBanner(true)
        handleSetMessage(response.data.message)
      },
      onSettled: () => {
        setEditStatus(false)
      },
    }
  )

  const handleChange = (event: SelectChangeEvent): void => {
    const { name, value } = event.target
    setUserState({ ...userState, [name]: value })
  }

  useEffect(() => {
    testList && setTestQuestions(testList)
  }, [testList])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handlerChange = (event: InputChangeEvent): void => {
    const { name, value } = event.target

    setTestData({ ...testData, [name]: value })
  }

  const handleAddIncorrectAnswers = (): void => {
    setTestData({ ...testData, incorrect_answers: [...testData.incorrect_answers, incorrectAnswers] })
    setIncorrectAnswers('')
  }

  const handleIncorrectAnswers = (event: InputChangeEvent): void => {
    const { value } = event.target
    setIncorrectAnswers(value)
  }

  const validationSchema = Yup.object().shape({
    task: Yup.string().required("Обов'язкове поле"),
    question: Yup.string().required("Обов'язкове поле"),
    correct_answer: Yup.string().required("Обов'язкове поле"),
    language: Yup.string().required("Обов'язкове поле"),
    level: Yup.string().required("Обов'язкове поле"),
  })

  const onSubmit = (): void => {
    updateQuestion()
  }

  const handleTestEdit = (id: string) => {
    setEditStatus(!editStatus)
    setQuestionId(id)
  }

  useEffect(() => {
    questionDetails && setTestData(questionDetails)
  }, [questionDetails])

  return (
    <Grid sx={{ p: '48px 24px' }} container alignItems="center" flexDirection="column" gap="24px">
      <Grid container gap={2} flexWrap="nowrap">
        <FormControl fullWidth variant="standard">
          <InputLabel id="demo-simple-select-label">Language</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={userState.language}
            name="language"
            label="Language"
            onChange={handleChange}
          >
            <MenuItem value="eng">English</MenuItem>
            <MenuItem value="de">Deutch</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth variant="standard">
          <InputLabel id="demo-simple-select-label">Level</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={userState.level}
            label="Level"
            name="level"
            onChange={handleChange}
          >
            <MenuItem value="elementary">Elementary</MenuItem>
            <MenuItem value="intermediate">Intermediate</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      {testQuestions.map(({ _id, task, question, level, correct_answer, incorrect_answers }) => (
        <Card
          key={_id}
          sx={{
            width: { xs: '330px', sm: '380px' },
            height: '100%',
            boxShadow: '0px 0px 35px 15px rgb(28, 37, 43, 0.045)',
          }}
        >
          <CardContent
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              gap: '25px',
              textAlign: 'start',
            }}
            sx={{ p: '30px 20px' }}
          >
            <Grid>
              <DeleteIcon sx={{ color: 'var(--main)' }} onClick={() => deleteQuestion(_id)} />
              <button onClick={() => handleTestEdit(_id)}>edit</button>
            </Grid>
            <Typography variant="body2" color="var(--light-gray)">
              {level}
            </Typography>
            <Grid sx={{ width: '100%' }}>
              <Typography variant="body2" color="var(--light-gray)" sx={{ mb: '15px', fontWeight: 600 }}>
                {task}
              </Typography>
              <Typography variant="h6" fontWeight={600}>
                {question}
              </Typography>
            </Grid>
            <Typography variant="h6" fontWeight={600}>
              {correct_answer}
            </Typography>
            <Grid>
              {incorrect_answers.map((text: string) => (
                <Typography variant="h6" fontWeight={600}>
                  {text}
                </Typography>
              ))}
            </Grid>
          </CardContent>
        </Card>
      ))}

      {editStatus && (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
          {({ setFieldValue, setFieldTouched }) => (
            <Form>
              <Grid container gap={2} flexDirection="column">
                <Field name="task">
                  {({ meta }) => (
                    <Grid sx={{ width: '350px' }}>
                      <TextField
                        sx={{ width: '100%' }}
                        variant="standard"
                        label="task"
                        name="task"
                        onBlur={() => setFieldTouched('task', true)}
                        value={testData.task}
                        error={!!meta.error && !!meta.touched}
                        onChange={(event: InputChangeEvent) => {
                          handlerChange(event)
                          setFieldValue('task', event.target.value)
                        }}
                      />
                      <Grid>{!!meta.error && !!meta.touched ? meta.error : ''}</Grid>
                    </Grid>
                  )}
                </Field>
                <Field name="level">
                  {({ meta }) => (
                    <Grid>
                      <TextField
                        sx={{ width: '100%' }}
                        variant="standard"
                        label="level"
                        name="level"
                        onBlur={() => setFieldTouched('level', true)}
                        value={testData.level}
                        error={!!meta.error && !!meta.touched}
                        onChange={(event: InputChangeEvent) => {
                          handlerChange(event)
                          setFieldValue('level', event.target.value)
                        }}
                      />
                      <Grid>{!!meta.error && !!meta.touched ? meta.error : ''}</Grid>
                    </Grid>
                  )}
                </Field>
                <Field name="question">
                  {({ meta }) => (
                    <Grid>
                      <TextField
                        sx={{ width: '100%' }}
                        variant="standard"
                        label="question"
                        name="question"
                        onBlur={() => setFieldTouched('question', true)}
                        value={testData.question}
                        error={!!meta.error && !!meta.touched}
                        onChange={(event: InputChangeEvent) => {
                          handlerChange(event)
                          setFieldValue('question', event.target.value)
                        }}
                      />
                      <Grid>{!!meta.error && !!meta.touched ? meta.error : ''}</Grid>
                    </Grid>
                  )}
                </Field>
                <Field name="language">
                  {({ meta }) => (
                    <Grid>
                      <TextField
                        sx={{ width: '100%' }}
                        variant="standard"
                        label="language"
                        name="language"
                        onBlur={() => setFieldTouched('language', true)}
                        value={testData.language}
                        error={!!meta.error && !!meta.touched}
                        onChange={(event: InputChangeEvent) => {
                          handlerChange(event)
                          setFieldValue('language', event.target.value)
                        }}
                      />
                      <Grid>{!!meta.error && !!meta.touched ? meta.error : ''}</Grid>
                    </Grid>
                  )}
                </Field>
                <Field name="correct_answery">
                  {({ meta }) => (
                    <Grid>
                      <TextField
                        sx={{ width: '100%' }}
                        variant="standard"
                        label="correct_answer"
                        name="correct_answer"
                        onBlur={() => setFieldTouched('correct_answer', true)}
                        value={testData.correct_answer}
                        error={!!meta.error && !!meta.touched}
                        onChange={(event: InputChangeEvent) => {
                          handlerChange(event)
                          setFieldValue('correct_answer', event.target.value)
                        }}
                      />
                      <Grid>{!!meta.error && !!meta.touched ? meta.error : ''}</Grid>
                    </Grid>
                  )}
                </Field>

                <Grid container flexWrap="nowrap">
                  <Field name="incorrect_answers">
                    {({ meta }) => (
                      <Grid width="100%">
                        <TextField
                          sx={{ width: '100%' }}
                          variant="standard"
                          label="incorrect_answers"
                          name="incorrect_answers"
                          onBlur={() => setFieldTouched('incorrect_answers', true)}
                          value={incorrectAnswers}
                          error={!!meta.error && !!meta.touched}
                          onChange={(event: InputChangeEvent) => {
                            handleIncorrectAnswers(event)
                            setFieldValue('incorrect_answers', event.target.value)
                          }}
                        />
                        <Grid>{!!meta.error && !!meta.touched ? meta.error : ''}</Grid>
                      </Grid>
                    )}
                  </Field>
                  <button type="button" aria-label="delete" onClick={handleAddIncorrectAnswers}>
                    add
                  </button>
                </Grid>
              </Grid>
              <Grid width="100%" container justifyContent="center" sx={{ p: '20px 0px' }}>
                <Button variant="outlined" onClick={onSubmit} sx={{ p: '8px 32px' }}>
                  Відправити
                </Button>
              </Grid>
            </Form>
          )}
        </Formik>
      )}
    </Grid>
  )
}
