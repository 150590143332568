export const EMAIL_JS_TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID
export const EMAIL_JS_SERVICE_ID = process.env.REACT_APP_SERVICE_ID
export const EMAIL_JS_PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY

export const baseURL = process.env.REACT_APP_BASE_URL
export const BASE_FRONTEND_URL = process.env.REACT_APP_BASE_URL_FRONTEND

export const emailJsCourseTemplateId = process.env.REACT_APP_COURSE_TEMPLATE_ID
export const emailJsCourseServiceId = process.env.REACT_APP_COURSE_SERVICE_ID
export const emailJsCoursePublicKey = process.env.REACT_APP_COURSE_PUBLIC_KEY

export const emailJsCourseTemplateIdWithLevel = process.env.REACT_APP_COURSE_TEMPLATE_ID_WITH_LEVEL

export const googleId = process.env.REACT_APP_GOOGLE_CLIENT_ID

export const BASE_AWS_URL = 'https://leader-bucket.s3.eu-central-1.amazonaws.com/'

export const PhoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const FILE_FORMATS = ['png', 'jpg', 'svg', 'jpeg']
export const MAX_FILE_SIZE = 10240

export const ROLES = {
  USER: 'user',
  ADMIN: 'admin',
}
