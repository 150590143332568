import { FeedbacksListData, FeedbacksListResponse } from 'types/feedbacks'
import { apiClient } from './apiClient'
import ENDPOINTS from './endpoints'

const endpoint = ENDPOINTS.FEEDBACKS

const getFeedbacksList = (): FeedbacksListResponse =>
  apiClient.get<FeedbacksListData[]>(`${endpoint}`).then((res) => res.data)

const deleteFeedback = (id: string): any => apiClient.delete(`${endpoint}/${id}`).then((res) => res.data)

const createFeedback = (comment: any): any =>
  apiClient.post<any>(`${endpoint}/create`, comment).then((res) => res.data)

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getFeedbacksList,
  deleteFeedback,
  createFeedback,
}
