export const ContactUsFieldsWrapper = { gap: 3 }

export const ContactUsWrapper = {
  background: 'var(--white)',
  width: '100%',
  maxWidth: '1050px',
  display: 'flex',
  alignItems: 'center',
  borderRadius: '22px',
  overflow: 'hidden',
  boxShadow: '0px 0px 70px 30px rgba(156, 163, 168, 0.1)',
  texAlign: 'left',
  flexDirection: { xs: 'column', md: 'row' },
  justifyContent: 'space-between',
}

export const ContactUsFormContainer = {
  padding: { xs: '50px 30px 70px', sm: '50px 50px 70px' },
  textAlign: 'left',
  position: 'relative',
  float: 'left',
  width: { xs: '100%', md: '50.45945946%', lg: '60.45945946%' },
  display: 'flex',
  gap: '20px',
  flexDirection: 'column',
}

export const ContactUsInfoContainer = {
  padding: { xs: '75px 30px 40px', md: '75px 50px 50px' },
  backgroundColor: '#F7F4FF',
  float: 'right',
  width: { xs: '100%', md: '49.18918919%', lg: '39.18918919%' },
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '30px',
}

export const tableImage = {
  width: '20px',
  margin: '0px  auto',
}

export const circleLoading = {
  color: 'var(--main)',
}
export const closeIcon = {
  fontSize: '40px',
}
export const doneIcon = {
  fontSize: '40px',
}
