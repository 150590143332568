import * as Yup from 'yup'
import { useMutation } from 'react-query'
import { useEffect, useState } from 'react'
import { Formik, Form, Field } from 'formik'

import { InputChangeEvent } from 'types/common'

import ENDPOINTS from 'api/endpoints'
import courseApi from 'api/courseApi'

import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

const initialValues = {
  title: '',
  description: '',
  lessonTime: '',
  rating: 0,
  location: '',
  frequency: '',
  language: '',
  type: '',
  age: '',
  peopleAmount: '',
  level: '',
  benefits: [],
  price: 350,
  image: '',
  lessonType: '',
}

export const AddCourse = (): JSX.Element => {
  const [benefits, setBenefits] = useState('')
  const [courseData, setCourseData] = useState<any>(initialValues)

  const { mutate: createCourse } = useMutation(ENDPOINTS.COURSES, courseApi.createCourse)

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Обов'язкове поле"),
    description: Yup.string().required("Обов'язкове поле"),
    lessonTime: Yup.string().required("Обов'язкове поле"),
    rating: Yup.number().required("Обов'язкове поле"),
    location: Yup.string().required("Обов'язкове поле"),
    frequency: Yup.string().required("Обов'язкове поле"),
    language: Yup.string().required("Обов'язкове поле"),
    type: Yup.string().required("Обов'язкове поле"),
    lessonType: Yup.string().required("Обов'язкове поле"),
    age: Yup.string().required("Обов'язкове поле"),
    peopleAmount: Yup.string().required("Обов'язкове поле"),
    level: Yup.string().required("Обов'язкове поле"),
    // benefits: Yup.array().of(Yup.string()).required("Обов'язкове поле"),
    price: Yup.number().required("Обов'язкове поле"),
    image: Yup.string().required("Обов'язкове поле"),
  })
  const onSubmit = (): void => {
    const formData = new FormData()

    formData.append('title', courseData.title)
    formData.append('description', courseData.description)
    formData.append('lessonTime', courseData.lessonTime)
    formData.append('rating', courseData.rating)
    formData.append('location', courseData.location)
    formData.append('frequency', courseData.frequency)
    formData.append('language', courseData.language)
    formData.append('type', courseData.type)
    formData.append('lessonType', courseData.lessonType)
    formData.append('age', courseData.age)
    formData.append('peopleAmount', courseData.peopleAmount)
    formData.append('level', courseData.level)
    formData.append('price', courseData.price)
    formData.append('image', courseData.image)
    formData.append('benefits', JSON.stringify(courseData.benefits))

    createCourse(formData)
  }

  const handlerChange = (event: InputChangeEvent): void => {
    const { name, value } = event.target

    setCourseData({ ...courseData, [name]: value })
  }

  const handleAddImage = (event: InputChangeEvent): void => {
    const { name, files } = event.target
    setCourseData({ ...courseData, [name]: files[0] })
  }

  const handleAddBenefits = (): void => {
    setCourseData({ ...courseData, benefits: [...courseData.benefits, benefits] })
    setBenefits('')
  }

  const handleBenefits = (event: InputChangeEvent): void => {
    const { value } = event.target
    setBenefits(value)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Grid
      container
      alignItems="center"
      sx={{ p: '70px 0px' }}
      justifyContent="center"
      flexDirection="column"
      gap={3}
    >
      <Typography variant="h1" sx={{ fontSize: { xs: '32px', md: '38px' } }} color="var(--black)">
        Додавання курсу
      </Typography>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ setFieldValue, setFieldTouched }) => (
          <Form>
            <Grid container gap={2} flexDirection="column">
              <Field name="title">
                {({ meta }) => (
                  <Grid sx={{ width: '350px' }}>
                    <TextField
                      sx={{ width: '100%' }}
                      variant="standard"
                      label="Заголовок"
                      name="title"
                      onBlur={() => setFieldTouched('title', true)}
                      value={courseData.title}
                      error={!!meta.error && !!meta.touched}
                      onChange={(event: InputChangeEvent) => {
                        handlerChange(event)
                        setFieldValue('title', event.target.value)
                      }}
                    />
                    <Grid>{!!meta.error && !!meta.touched ? meta.error : ''}</Grid>
                  </Grid>
                )}
              </Field>
              <Field name="description">
                {({ meta }) => (
                  <Grid>
                    <TextField
                      sx={{ width: '100%' }}
                      variant="standard"
                      label="Опис"
                      name="description"
                      onBlur={() => setFieldTouched('description', true)}
                      value={courseData.description}
                      error={!!meta.error && !!meta.touched}
                      onChange={(event: InputChangeEvent) => {
                        handlerChange(event)
                        setFieldValue('description', event.target.value)
                      }}
                    />
                    <Grid>{!!meta.error && !!meta.touched ? meta.error : ''}</Grid>
                  </Grid>
                )}
              </Field>
              <Field name="lessonTime">
                {({ meta }) => (
                  <Grid>
                    <TextField
                      sx={{ width: '100%' }}
                      variant="standard"
                      label="Тривалість"
                      name="lessonTime"
                      onBlur={() => setFieldTouched('lessonTime', true)}
                      value={courseData.lessonTime}
                      error={!!meta.error && !!meta.touched}
                      onChange={(event: InputChangeEvent) => {
                        handlerChange(event)
                        setFieldValue('lessonTime', event.target.value)
                      }}
                    />
                    <Grid>{!!meta.error && !!meta.touched ? meta.error : ''}</Grid>
                  </Grid>
                )}
              </Field>
              <Field name="rating">
                {({ meta }) => (
                  <Grid>
                    <TextField
                      sx={{ width: '100%' }}
                      variant="standard"
                      label="Популярність від 0 до 5"
                      name="rating"
                      type="number"
                      onBlur={() => setFieldTouched('rating', true)}
                      value={courseData.rating}
                      error={!!meta.error && !!meta.touched}
                      onChange={(event: InputChangeEvent) => {
                        handlerChange(event)
                        setFieldValue('rating', event.target.value)
                      }}
                    />
                    <Grid>{!!meta.error && !!meta.touched ? meta.error : ''}</Grid>
                  </Grid>
                )}
              </Field>
              <Field name="frequency">
                {({ meta }) => (
                  <Grid>
                    <TextField
                      sx={{ width: '100%' }}
                      variant="standard"
                      label="Частота"
                      name="frequency"
                      onBlur={() => setFieldTouched('frequency', true)}
                      value={courseData.frequency}
                      error={!!meta.error && !!meta.touched}
                      onChange={(event: InputChangeEvent) => {
                        handlerChange(event)
                        setFieldValue('frequency', event.target.value)
                      }}
                    />
                    <Grid>{!!meta.error && !!meta.touched ? meta.error : ''}</Grid>
                  </Grid>
                )}
              </Field>
              <Field name="location">
                {({ meta }) => (
                  <Grid>
                    <TextField
                      sx={{ width: '100%' }}
                      variant="standard"
                      label="Локація"
                      name="location"
                      onBlur={() => setFieldTouched('location', true)}
                      value={courseData.location}
                      error={!!meta.error && !!meta.touched}
                      onChange={(event: InputChangeEvent) => {
                        handlerChange(event)
                        setFieldValue('location', event.target.value)
                      }}
                    />
                    <Grid>{!!meta.error && !!meta.touched ? meta.error : ''}</Grid>
                  </Grid>
                )}
              </Field>
              <Field name="language">
                {({ meta }) => (
                  <Grid>
                    <TextField
                      sx={{ width: '100%' }}
                      variant="standard"
                      label="Мова (eng, de)"
                      name="language"
                      onBlur={() => setFieldTouched('language', true)}
                      value={courseData.language}
                      error={!!meta.error && !!meta.touched}
                      onChange={(event: InputChangeEvent) => {
                        handlerChange(event)
                        setFieldValue('language', event.target.value)
                      }}
                    />
                    <Grid>{!!meta.error && !!meta.touched ? meta.error : ''}</Grid>
                  </Grid>
                )}
              </Field>
              <Field name="type">
                {({ meta }) => (
                  <Grid>
                    <TextField
                      sx={{ width: '100%' }}
                      variant="standard"
                      label="Тип"
                      name="type"
                      onBlur={() => setFieldTouched('type', true)}
                      value={courseData.type}
                      error={!!meta.error && !!meta.touched}
                      onChange={(event: InputChangeEvent) => {
                        handlerChange(event)
                        setFieldValue('type', event.target.value)
                      }}
                    />
                    <Grid>{!!meta.error && !!meta.touched ? meta.error : ''}</Grid>
                  </Grid>
                )}
              </Field>
              <Field name="age">
                {({ meta }) => (
                  <Grid>
                    <TextField
                      sx={{ width: '100%' }}
                      variant="standard"
                      label="Вік"
                      name="age"
                      onBlur={() => setFieldTouched('age', true)}
                      value={courseData.age}
                      error={!!meta.error && !!meta.touched}
                      onChange={(event: InputChangeEvent) => {
                        handlerChange(event)
                        setFieldValue('age', event.target.value)
                      }}
                    />
                    <Grid>{!!meta.error && !!meta.touched ? meta.error : ''}</Grid>
                  </Grid>
                )}
              </Field>
              <Field name="level">
                {({ meta }) => (
                  <Grid>
                    <TextField
                      sx={{ width: '100%' }}
                      variant="standard"
                      label="Рівень"
                      name="level"
                      onBlur={() => setFieldTouched('level', true)}
                      value={courseData.level}
                      error={!!meta.error && !!meta.touched}
                      onChange={(event: InputChangeEvent) => {
                        handlerChange(event)
                        setFieldValue('level', event.target.value)
                      }}
                    />
                    <Grid>{!!meta.error && !!meta.touched ? meta.error : ''}</Grid>
                  </Grid>
                )}
              </Field>
              <Field name="lessonType">
                {({ meta }) => (
                  <Grid>
                    <TextField
                      sx={{ width: '100%' }}
                      variant="standard"
                      label="Тип уроку"
                      name="lessonType"
                      onBlur={() => setFieldTouched('lessonType', true)}
                      value={courseData.lessonType}
                      error={!!meta.error && !!meta.touched}
                      onChange={(event: InputChangeEvent) => {
                        handlerChange(event)
                        setFieldValue('level', event.target.value)
                      }}
                    />
                    <Grid>{!!meta.error && !!meta.touched ? meta.error : ''}</Grid>
                  </Grid>
                )}
              </Field>
              <Field name="peopleAmount">
                {({ meta }) => (
                  <Grid>
                    <TextField
                      sx={{ width: '100%' }}
                      variant="standard"
                      label="Кількість людей"
                      name="peopleAmount"
                      type="text"
                      onBlur={() => setFieldTouched('peopleAmount', true)}
                      value={courseData.peopleAmount}
                      error={!!meta.error && !!meta.touched}
                      onChange={(event: InputChangeEvent) => {
                        handlerChange(event)
                        setFieldValue('peopleAmount', event.target.value)
                      }}
                    />
                    <Grid>{!!meta.error && !!meta.touched ? meta.error : ''}</Grid>
                  </Grid>
                )}
              </Field>
              <Grid container flexWrap="nowrap">
                <Field name="benefits">
                  {({ meta }) => (
                    <Grid width="100%">
                      <TextField
                        sx={{ width: '100%' }}
                        variant="standard"
                        label="Переваги"
                        name="benefits"
                        onBlur={() => setFieldTouched('benefits', true)}
                        value={benefits}
                        error={!!meta.error && !!meta.touched}
                        onChange={(event: InputChangeEvent) => {
                          handleBenefits(event)
                          setFieldValue('benefits', event.target.value)
                        }}
                      />
                      <Grid>{!!meta.error && !!meta.touched ? meta.error : ''}</Grid>
                    </Grid>
                  )}
                </Field>
                <IconButton type="button" aria-label="delete" onClick={handleAddBenefits}>
                  <AddIcon />
                </IconButton>
              </Grid>
              <Field name="price">
                {({ meta }) => (
                  <Grid>
                    <TextField
                      sx={{ width: '100%' }}
                      variant="standard"
                      label="Ціна"
                      name="price"
                      type="number"
                      onBlur={() => setFieldTouched('price', true)}
                      value={courseData.price}
                      error={!!meta.error && !!meta.touched}
                      onChange={(event: InputChangeEvent) => {
                        handlerChange(event)
                        setFieldValue('price', event.target.value)
                      }}
                    />
                    <Grid>{!!meta.error && !!meta.touched ? meta.error : ''}</Grid>
                  </Grid>
                )}
              </Field>
              <Grid>
                <input
                  name="image"
                  type="file"
                  onChange={(event) => {
                    handleAddImage(event)
                  }}
                />
              </Grid>
            </Grid>
            <Grid width="100%" container justifyContent="center" sx={{ p: '20px 0px' }}>
              <Button variant="outlined" type="submit" onClick={onSubmit} sx={{ p: '8px 32px' }}>
                Відправити
              </Button>
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  )
}
