import { WhyChooseUsCardPropTypes } from 'utils/types'
import './WhyChooseUsCard.scss'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

export const WhyChooseUsCard = ({ title, text, icon }: WhyChooseUsCardPropTypes): JSX.Element => {
  return (
    <Grid
      container
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      flexWrap="nowrap"
      sx={{
        p: '0px 20px 30px',
        maxWidth: '330px',
        height: { xs: '420px', sm: '390px' },
        minWidth: '270px',
        borderRadius: '18px',
      }}
      className="choose-card"
      gap={{ xs: 2, sm: 3 }}
    >
      <img src={icon} alt="alt" width="130px" />
      <Grid>
        <Typography variant="h5" gutterBottom>
          {title}
        </Typography>
        <Typography variant="h6" color="#999999">
          {text}
        </Typography>
      </Grid>
    </Grid>
  )
}
