import { useEffect } from 'react'
import useDeviceStore from 'store/deviceDetect.store'

import { useMediaQuery } from '@mui/material'

const MOBILE_QUERY = '(max-width: 767px)'
const TABLET_QUERY = '(min-width: 767px) and (max-width: 1024px)'

export const DeviceWrapper = ({ children }): JSX.Element => {
  const { setTabletDevice, setMobileDevice } = useDeviceStore()

  const isMobileDevice = useMediaQuery(MOBILE_QUERY)
  const isTabletDevice = useMediaQuery(TABLET_QUERY)

  useEffect(() => {
    setMobileDevice(isMobileDevice)
    setTabletDevice(isTabletDevice)
  }, [isMobileDevice, isTabletDevice, setMobileDevice, setTabletDevice])

  return <>{children}</>
}
