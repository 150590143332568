import { ReactElement } from 'react'
import { Route, Routes as RoutesWrapper } from 'react-router-dom'

import { TermsAndPolicyText } from '../utils/termsText'

import { AdminRoutes } from './AdminRoutes'
import { PrivateRoutes } from './PrivateRoutes'
import { ProtectedRoutes } from './ProtectedRoutes'

import { Auth } from '../containers/Auth'
import { Home } from '../containers/Home'
import { Courses } from '../containers/Courses'
import { Profile } from '../containers/Profile'
import { Contacts } from '../containers/Contacts'
import { NotFound } from '../containers/NotFound'
import { Feedbacks } from '../containers/Feedbacks'
import { AddCourse } from '../containers/AddCourse'
import { NewPassword } from '../containers/NewPassword'
import { LanguageTest } from '../containers/LanguageTest'
import { CourseDetails } from '../containers/CourseDetails'
import { TermsAndPolicy } from '../containers/Terms&Policy'
import { ResetPassword } from '../containers/ResetPassword'
import { TestQuestions } from '../containers/TestQuestions'
import { AddTestQuestion } from '../containers/AddTestQuestion'
import { CourseEditing } from '../containers/CourseDetails/CourseEditing'

export const Routes = (): ReactElement => {
  return (
    <RoutesWrapper>
      <Route path="/" element={<Home />} />
      <Route
        path="/terms-of-conditions"
        element={<TermsAndPolicy title="Terms of conditions" text={TermsAndPolicyText.terms} />}
      />
      <Route
        path="/privacy-policy"
        element={<TermsAndPolicy title="ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ" text={TermsAndPolicyText.policy} />}
      />
      <Route path="/courses" element={<Courses />} />
      <Route path="/feedbacks" element={<Feedbacks />} />
      <Route path="/course-details/:id" element={<CourseDetails />} />
      <Route path="/contacts" element={<Contacts />} />
      <Route element={<ProtectedRoutes />}>
        <Route path="/sign-up" element={<Auth />} />
        <Route path="/sign-in" element={<Auth />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/user-token/:id" element={<NewPassword />} />
      </Route>
      <Route element={<AdminRoutes />}>
        <Route path="/course-add" element={<AddCourse />} />
        <Route path="/test-add" element={<AddTestQuestion />} />
        <Route path="/test-questions" element={<TestQuestions />} />
        <Route path="/course-edit/:id" element={<CourseEditing />} />
      </Route>
      <Route element={<PrivateRoutes />}>
        <Route path="/profile/:id" element={<Profile />} />
        <Route path="/language-test" element={<LanguageTest />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </RoutesWrapper>
  )
}
