import { Link } from 'react-router-dom'

import logo from 'assets/Logo/logo-white.svg'

import './Footer.scss'

import { HiHome, HiMail } from 'react-icons/hi'
import { FaPhoneAlt, FaFacebookF, FaInstagram } from 'react-icons/fa'

import { Box, Divider, Grid, Typography } from '@mui/material'

export const Footer = (): JSX.Element => {
  return (
    <Grid
      height="100%"
      sx={{
        backgroundColor: '#1c252c',
        opacity: 1,
        backgroundImage:
          'repeating-radial-gradient( circle at 0 0, transparent 0, #1c252b 50px ), repeating-linear-gradient( #232e3555, #232e35 )',
        p: { xs: '80px 40px 50px', md: '100px 50px 50px' },
      }}
      container
      flexDirection="column"
      width="100%"
      gap={4}
    >
      <Grid
        container
        justifyContent="space-between"
        flexDirection={{ xs: 'column', md: 'row' }}
        gap={{ xs: 4, md: 0 }}
      >
        <img src={logo} alt="logo" width="140px" height="100%" style={{ marginTop: '10px' }} />
        <Grid container height="100%" width={{ xs: '100%', md: '50%' }}>
          <Grid container flexWrap={{ xs: 'wrap', md: 'nowrap' }} sx={{ mb: '20px' }} gap="20px">
            <Grid container gap={1.5} flexWrap="nowrap">
              <Box>
                <FaPhoneAlt size={20} color="#ffff" style={{ marginTop: '5px' }} />
              </Box>
              <Box>
                <Typography variant="h6" color="#ffff">
                  Телефон
                </Typography>
                <a href="tel:+380953804160" className="link">
                  <Typography variant="h6" color="var(--light-gray)" fontSize={{ xs: '14px', md: '16px' }}>
                    +380 (95) 380 41 60
                  </Typography>
                </a>
              </Box>
            </Grid>
            <Grid container gap={1.5} flexWrap="nowrap">
              <Box>
                <HiMail size={25} color="#ffff" />
              </Box>
              <Box>
                <Typography variant="h6" color="#ffff" fontSize={{ xs: '14px', md: '16px' }}>
                  Email
                </Typography>
                <a href="mailto:leaderschooluz@gmail.com" className="link">
                  leaderschooluz@gmail.com
                </a>
              </Box>
            </Grid>
          </Grid>
          <Grid container gap={1.5} flexWrap="nowrap" width="100%">
            <Box>
              <HiHome size={25} color="#ffff" />
            </Box>
            <Box>
              <Typography variant="h6" color="#ffff">
                Розташування
              </Typography>
              <Typography variant="h6" color="var(--light-gray)" fontSize={{ xs: '14px', md: '16px' }}>
                вулиця Льва Толстого, 3в, 3-й Поверх, Ужгород, Закарпатська область, 88000
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid
          width={{ xs: '60%', md: '20%' }}
          container
          justifyContent={{ xs: 'flex-start', md: 'flex-end' }}
          gap={{ xs: 1.5, md: 5 }}
        >
          <Box className="social-media-icon-wrapper">
            <a href="https://www.facebook.com/leaderschooluz/" target="_blank" rel="noreferrer">
              <FaFacebookF size={18} color="var(--black)" style={{ margin: '3px 1px 0px 0px' }} />
            </a>
          </Box>
          <Box className="social-media-icon-wrapper">
            <a
              href="https://instagram.com/leaderschooluz?igshid=YWJhMjlhZTc="
              target="_blank"
              rel="noreferrer"
            >
              <FaInstagram size={20} className="social-media-icon instagram" />
            </a>
          </Box>
          <Box className="social-media-icon-wrapper">
            <a href="mailto:leaderschooluz@gmail.com" className="link" style={{ height: '24px' }}>
              <HiMail size={24} color="var(--black)" />
            </a>
          </Box>
        </Grid>
      </Grid>

      <Divider variant="middle" sx={{ mt: '30px', opacity: '0.2', backgroundColor: 'var(--light-gray)' }} />

      <Grid width="100%" container flexDirection={{ xs: 'column', md: 'row' }} gap={{ xs: 2, md: 0 }}>
        <Grid container flexWrap="nowrap" width={{ xs: '100%', md: '65%' }}>
          <Typography variant="h6" fontSize="14px" color="var(--light-gray)">
            Copyright © {new Date().getFullYear()} Leader Language School - All rights reserved
          </Typography>
        </Grid>
        <Grid
          container
          flexWrap="nowrap"
          gap={3}
          width={{ xs: '100%', md: '35%' }}
          justifyContent={{ xs: 'flex-start', md: 'flex-end' }}
        >
          <Link to="/privacy-policy" target="_blank" rel="noreferrer" className="link-style">
            <Typography variant="h6" fontSize="14px" color="var(--light-gray)">
              Terms of conditions
            </Typography>
          </Link>
          <Link to="/privacy-policy" target="_blank" rel="noreferrer" className="link-style">
            <Typography variant="h6" fontSize="14px" color="var(--light-gray)">
              Privacy Policy
            </Typography>
          </Link>
        </Grid>
      </Grid>
    </Grid>
  )
}
