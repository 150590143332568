import Rating from '@mui/material/Rating'
import StarIcon from '@mui/icons-material/Star'

export const CustomRating = ({ value, handleChange, name }: any) => {
  return (
    <Rating
      size="large"
      name={name}
      value={value}
      precision={0.5}
      onChange={handleChange}
      emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
    />
  )
}
