import { ReactElement } from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import useUserStore from '../store/user.store'

export const AdminRoutes = (): ReactElement => {
  const { userDetails } = useUserStore()

  const isAdmin: boolean = userDetails?.role === 'admin'

  return isAdmin ? <Outlet /> : <Navigate to="/" />
}
