import { useEffect } from 'react'

import { TestStart } from 'components/TestStart'
import { CourseTabs } from 'components/CourseTabs'
import { HowToStartCoursesPage } from 'components/HowToStart/HowToStartCoursesPage'

import { CustomMetaTitle } from 'utils/customMetaTitle'

import Box from '@mui/material/Box'

export const Courses = (): JSX.Element => {
  useEffect(() => {
    window.scrollTo(0, 0)
    CustomMetaTitle('LS • Курси')
  }, [])

  return (
    <Box>
      <CourseTabs />
      <TestStart />
      <Box sx={{ p: { xs: '70px 24px', sm: '70px 70px', md: '70px 100px', lg: '70px 200px' } }}>
        <HowToStartCoursesPage />
      </Box>
    </Box>
  )
}
