import './index.scss'

import Grid from '@mui/material/Grid'

export const Loader = (): JSX.Element => {
  return (
    <Grid className="loader-wrapper">
      <div className="loader"></div>
    </Grid>
  )
}
