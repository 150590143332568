import React, { useCallback, useEffect, useState } from 'react'
import { useQuery } from 'react-query'

import { CustomTab } from './CustomTab'
import { TabSection } from './CustomTab/TabSection'

import { OopsAlert } from 'components/OopsAlert'

import { InputChangeEvent } from 'types/common'
import useLoaderStoreStore from 'store/loader.store'

import useDebounce from 'hooks/useDebounce'

import ENDPOINTS from 'api/endpoints'
import courseApi from 'api/courseApi'

import { Search, SearchIconWrapper, StyledInputBase, WelcomeScreenWrapper } from './CourseTabsStyles'

import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import SearchIcon from '@mui/icons-material/Search'
import Tabs, { tabsClasses } from '@mui/material/Tabs'
import { CircularProgress } from '@mui/material'

export const CourseTabs = (): JSX.Element => {
  const { handleSetLoading } = useLoaderStoreStore()

  const [value, setValue] = useState(0)
  const [coursesList, setCourses] = useState([])
  const [searcValue, setSearcValue] = useState('')

  const debouncedQuery = useDebounce(searcValue, 500)

  const { data, isLoading, isSuccess } = useQuery([ENDPOINTS.COURSES, { debouncedQuery }], () =>
    courseApi.getListOfCourses(debouncedQuery)
  )

  const getCourseList = useCallback(
    (type: string, searchItem: string) => {
      return coursesList?.filter((item) => item[type] === searchItem)
    },
    [coursesList]
  )

  useEffect(() => {
    if (data) setCourses(data.course)
  }, [data])

  useEffect(() => {
    if (isLoading && !searcValue) return handleSetLoading(true)
    return handleSetLoading(false)
  }, [handleSetLoading, isLoading, searcValue])

  const handleChange = (event: React.SyntheticEvent, newValue: number): void => {
    setValue(newValue)
  }

  const onChange = (event: InputChangeEvent): void => {
    const { value } = event.target
    setSearcValue(value)
  }

  return (
    <Grid sx={{ p: { xs: '50px 24px', sm: '50px', md: '70px 100px' } }}>
      <Grid
        sx={{
          ...WelcomeScreenWrapper,
          backgroundColor: 'var(--black)',
          opacity: 1,
          backgroundImage:
            'repeating-radial-gradient( circle at 0 0, transparent 0, #1c252b 50px ), repeating-linear-gradient( #232e3555, #232e35 )',
        }}
      >
        <Typography
          variant="h1"
          sx={{ fontSize: { xs: '30px', sm: '34px', md: '38px' }, mx: '30px' }}
          color="white"
        >
          Hello, оберіть свій
          <span style={{ color: 'var(--main)' }}> курс </span>
        </Typography>
        <Grid sx={{ mt: { xs: '165px', sm: '140px', md: '145px' }, position: 'absolute' }}>
          <Search>
            <SearchIconWrapper>
              <SearchIcon sx={{ color: 'var(--light-gray)' }} />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Знайди свій курс"
              inputProps={{ 'aria-label': 'search' }}
              onChange={onChange}
            />
          </Search>
        </Grid>
      </Grid>
      <Box
        sx={{
          flexGrow: 1,
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          p: '20px 0px 30px',
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="scrollable force tabs example"
          sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              '&.Mui-disabled': { opacity: 0.2 },
            },
          }}
        >
          <Tab label="Всі" />
          <Tab label="Англійська мова" />
          <Tab label="Німецька мова" />
          <Tab label="Групові заняття" />
          <Tab label="Індивідуальні" />
          <Tab label="Для дітей" />
          <Tab label="Напівіндивідуальні заняття" />
        </Tabs>
      </Box>
      {isSuccess && (
        <Box>
          <CustomTab value={value} index={0}>
            <TabSection courseData={coursesList} />
          </CustomTab>
          <CustomTab value={value} index={1}>
            <TabSection courseData={getCourseList('language', 'eng')} />
          </CustomTab>
          <CustomTab value={value} index={2}>
            <TabSection courseData={getCourseList('language', 'de')} />
          </CustomTab>
          <CustomTab value={value} index={3}>
            <TabSection courseData={getCourseList('lessonType', 'group')} />
          </CustomTab>
          <CustomTab value={value} index={4}>
            <TabSection courseData={getCourseList('lessonType', 'individual')} />
          </CustomTab>
          <CustomTab value={value} index={5}>
            <TabSection courseData={getCourseList('lessonType', 'children')} />
          </CustomTab>
          <CustomTab value={value} index={6}>
            <TabSection courseData={getCourseList('lessonType', 'semi-individual')} />
          </CustomTab>
        </Box>
      )}
      {coursesList.length === 0 && !isLoading && searcValue && (
        <OopsAlert title="Oops! 🥺" subTitle="На жаль, ми не змогли нічого знайти за вашим запитом. . ." />
      )}
      {isLoading && searcValue && (
        <Box sx={{ height: '50vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress color="primary" />
        </Box>
      )}
    </Grid>
  )
}
