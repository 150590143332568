import { createTheme } from '@mui/material'

export const theme = createTheme({
  palette: {
    primary: {
      main: '#A68CF1',
    },
    secondary: {
      main: '#ffff',
    },
    info: {
      main: 'rgba(66, 188, 142, 1)',
    },
  },

  typography: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    body1: {
      fontSize: '20px',
      lineHeight: '32px',
      fontWeight: 600,
      color: '#1c252c',
    },
    body2: {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 400,
      color: '#1c252c',
    },
    h1: {
      fontSize: '38px',
      fontWeight: 600,
      color: '#1c252c',
    },
    h2: {
      fontSize: '32px',
      fontWeight: 600,
      color: '#1c252c',
    },
    h3: {
      fontSize: '24px',
      fontWeight: 600,
      lineHeight: '36px',
      color: '#1c252c',
    },
    h5: {
      fontSize: '18px',
      lineHeight: '24px',
      fontWeight: 600,
      color: '#1c252c',
    },
    h6: {
      fontSize: '16px',
      lineHeight: '24px',
      color: '#484848',
      fontWeight: 500,
    },
  },

  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          borderRadius: '10px',
          color: 'var(--black)',

          '& input:-internal-autofill-selected': {
            backgroundColor: 'white',
          },
          '&:-webkit-autofill': {
            backgroundColor: 'white',
          },
          '& .MuiInputBase-input': {
            padding: '16.2px 14px',
          },
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          fontSize: '12px',

          '& input:-internal-autofill-selected': {
            backgroundColor: 'white',
          },
          '&:-webkit-autofill': {
            backgroundColor: 'white',
          },
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          fontWeight: 400,
          color: 'var(--black)',
        },
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: '0px 0px 70px rgba(0, 0, 0, 0.05);',
        },
      },
    },

    MuiAccordion: {
      styleOverrides: {
        root: {
          borderRadius: '8px !important',
          backgroundColor: '#F7F4FF',
          padding: '16px 10px',
          boxShadow: 'none',
          marginBottom: '20px',
          textAlign: 'left',
          '&:before': {
            display: 'none',
          },
        },
      },
    },

    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '0px 16px 16px',
          border: 'none',
          color: 'var(--black)',
        },
      },
    },

    MuiButtonBase: {
      styleOverrides: {
        root: {
          borderRadius: '8px ',
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
          fontSize: '14px',
          fontWeight: 500,
          textTransform: 'none',
        },

        contained: {
          boxShadow: 'none',
          color: '#ffff',
          '&:hover': {
            boxShadow: 'none',
            background: '#A68CF1',
          },
        },
      },
    },

    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
          boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.07)',
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: 'none',
        },
      },
    },

    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'inherit',
          },
        },
      },
    },

    MuiListItem: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },

    MuiTab: {
      styleOverrides: {
        root: {
          color: '#515257',
          opacity: 0.6,

          '&.Mui-selected': {
            opacity: 1,
            fontWeight: 600,
          },
        },
      },
    },
  },
})
