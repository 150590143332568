export const ChooseUsWrapper = {
  padding: { xs: '50px 24px 0px', md: '70px 25px 20px' },
  borderRadius: '24px',
  overflow: 'visible',
  backgroundColor: 'var(--white)',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  gap: '20px',
  zIndex: 10,
  marginTop: { xs: '0px', md: '-76px' },
}
