import { create } from 'zustand'

interface IDevice {
  isMobile: boolean
  isTablet: boolean
  setMobileDevice: (value: boolean) => void
  setTabletDevice: (value: boolean) => void
}

const useDeviceStore = create<IDevice>()((set) => ({
  isMobile: false,
  isTablet: false,
  setMobileDevice: (value) => set(() => ({ isMobile: value })),
  setTabletDevice: (value) => set(() => ({ isTablet: value })),
}))

export default useDeviceStore
