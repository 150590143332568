import { useEffect } from 'react'

import { QuizTest } from 'components/QuizTest'
import { SelectLanguageItem } from '../components/SelectLanguageItem'

import testImageMobile from 'assets/LanguageTest/testPageImage.jpg'
import '../LanguageTest.scss'

import de from 'assets/LanguageTest/germany.png'
import eng from 'assets/LanguageTest/english.png'
import firstPlace from 'assets/LanguageTest/firstPlace.png'
import secondPlace from 'assets/LanguageTest/secondPlace.png'

import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

export const MobileLanguageTest = ({
  nextStage,
  startTest,
  handleReadtUser,
  handleChange,
  handleStartTest,
  language,
  level,
  startButtonStatus,
}: any): JSX.Element => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [language, nextStage])

  return (
    <Grid
      sx={{ textAlign: 'center', mt: '-16px', justifyContent: 'space-between', p: '65px 24px' }}
      container
      flexWrap="nowrap"
      flexDirection="column"
    >
      {!nextStage && (
        <Grid>
          {!startTest && (
            <Grid
              container
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{ mt: '-30px' }}
            >
              <img src={testImageMobile} alt="img" className="mobile-language-test-img" />

              <Grid container flexDirection="column" gap={{ xs: 5, md: 0 }}>
                <Grid>
                  <Typography variant="h2" gutterBottom>
                    Всього кілька хвилин і визначимо ваш рівень
                    <span style={{ color: '#DF623A' }}> рівень </span>
                  </Typography>
                  <Typography variant="h6">
                    Спочатку оберіть мову (англійську чи німецьку), а далі свій приблизний рівень, щоб вже
                    точніше його визначити за допомогою тесту. А після цього можна домовлятися про ідеальний
                    для вас варіант навчання. Успіхів на тесті!
                  </Typography>
                </Grid>
                <Grid>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: 'var(--black)',
                      p: '14px 35px',
                      width: { xs: '80%', md: '40%' },
                      fontWeight: 600,
                    }}
                    size="medium"
                    onClick={handleReadtUser}
                  >
                    Готовий
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
      {nextStage && (
        <>
          {!startTest ? (
            <Grid container flexDirection="column" alignItems="center" gap={4} width="100%">
              <Grid width="100%" container gap={2.8} textAlign="start">
                <Typography variant="h3" fontWeight={500} fontSize="20px">
                  Оберіть мову, будь ласка
                </Typography>
                <SelectLanguageItem
                  name="language"
                  value="eng"
                  icon={eng}
                  title="English"
                  state={language}
                  handleChange={handleChange}
                />
                <SelectLanguageItem
                  name="language"
                  value="de"
                  icon={de}
                  title="Deutsch"
                  state={language}
                  handleChange={handleChange}
                />
              </Grid>

              {language && (
                <Grid width="100%" container gap={2.8} textAlign="start">
                  <Typography variant="h3" fontWeight={500} fontSize="20px">
                    Оберіть приблизний рівень (Elementary - ПОЧАТКОВИЙ; Intermediate - СЕРЕДНІЙ)
                  </Typography>
                  {language !== 'de' && (
                    <SelectLanguageItem
                      name="level"
                      value="intermediate"
                      icon={firstPlace}
                      title="Intermediate"
                      state={level}
                      handleChange={handleChange}
                      language={language}
                      disabled={language === 'de'}
                    />
                  )}
                  <SelectLanguageItem
                    name="level"
                    value="elementary"
                    icon={secondPlace}
                    title="Elementary"
                    state={level}
                    handleChange={handleChange}
                  />
                </Grid>
              )}

              <Button
                disabled={startButtonStatus}
                variant="outlined"
                sx={{
                  backgroundColor: 'var(--black)',
                  color: 'white',
                  p: '14px 35px',
                  width: { xs: '80%', md: '40%' },
                  '&:hover': {
                    backgroundColor: 'var(--main)',
                    color: 'white',
                  },
                  '&:disabled': {
                    backgroundColor: 'white',
                  },
                }}
                size="medium"
                onClick={handleStartTest}
              >
                Почати тестування
              </Button>
            </Grid>
          ) : (
            <Grid container justifyContent="center" alignItems="center" sx={{ mt: '10px' }}>
              <QuizTest language={language} level={level} />
            </Grid>
          )}
        </>
      )}
    </Grid>
  )
}
