import { useCallback, useEffect, useState } from 'react'
import { useQuery } from 'react-query'

import ENDPOINTS from 'api/endpoints'
import avatarApi from 'api/avatarApi'
import courseApi from 'api/courseApi'

import useUserStore from 'store/user.store'
import useLoaderStoreStore from 'store/loader.store'

import { isEmptyObj } from 'utils/isEmptyObject'
import { getUserLevel } from 'utils/getUserLevel'
import { CustomMetaTitle } from 'utils/customMetaTitle'

import useDeviceStore from 'store/deviceDetect.store'

import trophy from 'assets/Profile/trophy.png'
import certificate from 'assets/Profile/certificate.png'

import { UserEdit } from './components/UserEdit'
import { TestCard } from './components/TestCard'
import { SettingItem } from './components/SettingItem'
import { BreadCrumbs } from '../../components/BreadCrumbs'
import { PasswordChange } from './components/PasswordChange'
import { MobileUserInfo } from './components/MobileUserInfo'
import { DesktopUserInfo } from './components/DesktopUserInfo'
import { RecommendationSlider } from './components/RecommendationSlider'

import { ProfileBreadCrumbsIcons, ProfileWrapper } from './Profile'

import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import EditIcon from '@mui/icons-material/Edit'
import HomeIcon from '@mui/icons-material/Home'
import Typography from '@mui/material/Typography'
import WidgetsIcon from '@mui/icons-material/Widgets'
import PasswordIcon from '@mui/icons-material/Password'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import NotificationsIcon from '@mui/icons-material/Notifications'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'

export const Profile = (): JSX.Element => {
  const [defaultAvatars, setDefaultAvatars] = useState([])

  const { userDetails } = useUserStore()
  const { isMobile } = useDeviceStore()
  const { handleSetLoading } = useLoaderStoreStore()

  const {
    data: coursesList,
    isLoading,
    isSuccess,
  } = useQuery(ENDPOINTS.COURSES, () => courseApi.getListOfCourses())

  const { data: avatarList, isSuccess: isSuccessedAvatars } = useQuery(ENDPOINTS.USER, () =>
    avatarApi.getAvatars()
  )

  const handleUserTests = useCallback(
    (language: string) => {
      const haveTest = !!userDetails?.languageLevel[language]?.length
      const lastTest = userDetails?.languageLevel[language].slice(-1)[0]

      return {
        haveTest,
        lastTest,
      }
    },
    [userDetails?.languageLevel]
  )

  useEffect(() => {
    window.scrollTo(0, 0)
    CustomMetaTitle('LS • Профіль')
  }, [])

  useEffect(() => {
    isLoading ? handleSetLoading(true) : handleSetLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  useEffect(() => {
    if (avatarList) setDefaultAvatars(avatarList)
  }, [avatarList])

  return (
    <Grid
      sx={{
        ...ProfileWrapper,
        p: 0,
        gap: isMobile ? '35px' : '70px',
        mb: isMobile ? '0px' : '50px',
      }}
    >
      {isEmptyObj(userDetails) && (
        <Grid
          container
          flexDirection="column"
          sx={{ p: isMobile ? '48px 24px 0px 24px' : '20px 70px 0px', gap: isMobile ? '35px' : '70px' }}
        >
          <Grid container gap="20px">
            <BreadCrumbs
              firsTitle="Головна"
              firstLocation=""
              secondTitle="Профіль"
              firstIcon={
                <HomeIcon
                  sx={{
                    ...ProfileBreadCrumbsIcons,
                    width: '20px',
                    height: '20px',
                  }}
                />
              }
              secondIcon={
                <SupervisorAccountIcon
                  sx={{
                    ...ProfileBreadCrumbsIcons,
                    width: '20px',
                    height: '20px',
                  }}
                />
              }
            />
            {isMobile ? (
              <MobileUserInfo userDetails={userDetails} />
            ) : (
              <DesktopUserInfo userDetails={userDetails} />
            )}
          </Grid>

          {isMobile ? (
            <Grid container flexDirection="column" gap={2.5}>
              <Typography variant={isMobile ? 'h3' : 'h2'} sx={{ m: '10px 0px 10px 0px' }}>
                Керуй своїм <span style={{ color: 'var(--main)' }}> профілем </span>
              </Typography>
              {isSuccessedAvatars && (
                <>
                  <SettingItem
                    icon={<EditIcon sx={{ color: '#ffff', width: '22px' }} />}
                    title="Редагувати профіль"
                    activeComponent={<UserEdit userDetails={userDetails} defaultAvatars={defaultAvatars} />}
                  />
                  <Divider sx={{ opacity: '.5' }} />
                </>
              )}

              {!userDetails.googleUserId && (
                <>
                  <SettingItem
                    icon={<PasswordIcon sx={{ color: '#ffff', width: '24px' }} />}
                    title="Зміна пароля"
                    activeComponent={<PasswordChange />}
                  />
                  <Divider sx={{ opacity: '.5' }} />
                </>
              )}

              <SettingItem
                icon={<WidgetsIcon sx={{ color: '#ffff' }} />}
                title="Пройдені тестування"
                activeComponent={
                  <Grid container flexDirection="column" gap={2}>
                    {handleUserTests('eng').haveTest && (
                      <TestCard
                        title="Рівень англійської мови:"
                        level={getUserLevel(
                          'eng',
                          handleUserTests('eng').lastTest.test,
                          handleUserTests('eng').lastTest.score
                        )}
                        image={certificate}
                      />
                    )}
                    {handleUserTests('de').haveTest && (
                      <TestCard
                        title="Рівень німецької мови:"
                        level={getUserLevel(
                          'de',
                          handleUserTests('de').lastTest.test,
                          handleUserTests('de').lastTest.score
                        )}
                        image={trophy}
                      />
                    )}
                    {!handleUserTests('eng').haveTest && !handleUserTests('de').haveTest && (
                      <Typography variant="h6" color="#484848">
                        Немає пройдених тестувань
                      </Typography>
                    )}
                  </Grid>
                }
              />

              <Divider sx={{ opacity: '.5' }} />

              <SettingItem
                icon={<NotificationsIcon sx={{ color: '#ffff' }} />}
                title="Сповіщення"
                activeComponent={
                  <Typography variant="h6" color="#484848">
                    Немає активних сповіщень
                  </Typography>
                }
              />

              <Divider sx={{ opacity: '.5' }} />
              <SettingItem
                icon={<ExitToAppIcon sx={{ color: '#ffff' }} />}
                title="Вийти з профілю"
                iconColor="var(--main)"
                activeComponent={<p>none</p>}
              />
            </Grid>
          ) : (
            <Grid container flexDirection="column" gap={2.5}>
              <Typography variant="h2" sx={{ mb: '10px' }}>
                Керуй своїм <span style={{ color: 'var(--main)' }}> профілем </span>
              </Typography>
              <Grid container flexWrap="nowrap" justifyContent="space-between">
                <Grid width="48%" container flexDirection="column" gap={2.5}>
                  {isSuccessedAvatars && (
                    <>
                      <SettingItem
                        icon={<EditIcon sx={{ color: '#ffff', width: '28px', height: '28px' }} />}
                        title="Редагувати профіль"
                        activeComponent={
                          <UserEdit userDetails={userDetails} defaultAvatars={defaultAvatars} />
                        }
                      />
                      <Divider sx={{ opacity: '.5' }} />
                    </>
                  )}

                  {!userDetails.googleUserId && (
                    <>
                      <SettingItem
                        icon={<PasswordIcon sx={{ color: '#ffff', width: '28px', height: '28px' }} />}
                        title="Зміна пароля"
                        activeComponent={<PasswordChange />}
                      />
                      <Divider sx={{ opacity: '.5' }} />
                    </>
                  )}

                  <SettingItem
                    icon={<WidgetsIcon sx={{ color: '#ffff', width: '28px', height: '28px' }} />}
                    title="Пройдені тестування"
                    activeComponent={
                      <Grid container flexDirection="column" gap={2}>
                        {handleUserTests('eng').haveTest && (
                          <TestCard
                            title="Рівень англійської мови:"
                            level={getUserLevel(
                              'eng',
                              handleUserTests('eng').lastTest.test,
                              handleUserTests('eng').lastTest.score
                            )}
                            image={certificate}
                          />
                        )}
                        {handleUserTests('de').haveTest && (
                          <TestCard
                            title="Рівень німецької мови:"
                            level={getUserLevel(
                              'de',
                              handleUserTests('de').lastTest.test,
                              handleUserTests('de').lastTest.score
                            )}
                            image={trophy}
                          />
                        )}
                        {!handleUserTests('eng').haveTest && !handleUserTests('de').haveTest && (
                          <Typography variant="h6" color="#484848">
                            Немає пройдених тестувань
                          </Typography>
                        )}
                      </Grid>
                    }
                  />
                </Grid>
                <Grid width="48%" container flexDirection="column" gap={2.5}>
                  <SettingItem
                    icon={<NotificationsIcon sx={{ color: '#ffff', width: '28px', height: '28px' }} />}
                    title="Сповіщення"
                    activeComponent={
                      <Typography variant="h6" color="#484848">
                        Немає активних сповіщень
                      </Typography>
                    }
                  />

                  <Divider sx={{ opacity: '.5' }} />
                  <SettingItem
                    icon={<ExitToAppIcon sx={{ color: '#ffff', width: '30px', height: '30px' }} />}
                    title="Вийти з профілю"
                    iconColor="var(--main)"
                    activeComponent={<p>none</p>}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
      {isSuccess && coursesList && (
        <Grid sx={{ px: isMobile ? '10px' : '70px', mb: '30px' }}>
          <Typography variant={isMobile ? 'h3' : 'h2'} sx={{ m: { xs: '10px 14px 0px', sm: '0px' } }}>
            <span style={{ color: 'var(--main)' }}> Рекомендaції </span> для тебе
          </Typography>

          <RecommendationSlider courses={coursesList.course} />
        </Grid>
      )}
    </Grid>
  )
}
