import { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'

import { BreadCrumbs } from 'components/BreadCrumbs'
import { FeedbacksCounter } from './components/FeedbacksCounter'
import { FeedbackCard } from 'components/Feedbacks/FeedbackCard'

import { ProfileBreadCrumbsIcons } from '../Profile/Profile'
import { CustomMetaTitle } from 'utils/customMetaTitle'
import useNotificationStore from 'store/notification.store'

import { FeedbacksListData } from 'types/feedbacks'

import useLoaderStoreStore from 'store/loader.store'

import feedbackApi from 'api/feedbackApi'
import ENDPOINTS from 'api/endpoints'

import { Box, Grid } from '@mui/material'
import HomeIcon from '@mui/icons-material/Home'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'

export const Feedbacks = (): JSX.Element => {
  const { handleSetBanner, handleSetMessage } = useNotificationStore()
  const { handleSetLoading } = useLoaderStoreStore()

  const [feedbacks, setFeedbacks] = useState<FeedbacksListData[]>([])

  const {
    data: feedbacksList,
    isLoading,
    isSuccess,
  } = useQuery(ENDPOINTS.FEEDBACKS, feedbackApi.getFeedbacksList)

  const { mutate: deleteFeedback } = useMutation(ENDPOINTS.FEEDBACKS, feedbackApi.deleteFeedback, {
    onSuccess: ({ comments, message }) => {
      handleSetMessage(message)
      setFeedbacks(comments)
      handleSetBanner(true)
    },
    onError: ({ response }) => {
      handleSetBanner(true)
      handleSetMessage(response.data.message)
    },
  })

  useEffect(() => {
    window.scrollTo(0, 0)
    CustomMetaTitle('LS • Відгуки')
  }, [])

  useEffect(() => {
    if (isLoading) {
      handleSetLoading(true)
    } else {
      handleSetLoading(false)
    }
    if (feedbacksList && !isLoading && isSuccess) setFeedbacks(feedbacksList)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbacksList, isLoading, isSuccess])

  return (
    <>
      {isSuccess && (
        <Grid
          sx={{
            p: { xs: '44px 24px', md: '34px 60px', lg: '34px 100px' },
            gap: { xs: '24px', md: 0 },
            mb: '50px',
          }}
          container
          justifyContent="center"
          flexDirection="column"
        >
          <Grid container justifyContent="flex-start" sx={{ mb: { xs: '16px', md: '24px' } }}>
            <BreadCrumbs
              firsTitle="Головна"
              firstLocation=""
              secondTitle="Відгуки"
              firstIcon={
                <HomeIcon
                  sx={{
                    ...ProfileBreadCrumbsIcons,
                    width: '20px',
                    height: '20px',
                  }}
                />
              }
              secondIcon={
                <SupervisorAccountIcon
                  sx={{
                    ...ProfileBreadCrumbsIcons,
                    width: '20px',
                    height: '20px',
                  }}
                />
              }
            />
          </Grid>

          <Box display="flex" sx={{ mb: { md: '32px', lg: '42px' } }}>
            <FeedbacksCounter feedbacks={feedbacks} />
          </Box>
          <Grid container spacing={{ xs: '24px', md: '32px', lg: '42px' }}>
            {feedbacks.map((item, index) => (
              <Grid item xs={12} sm={6} md={6} key={index}>
                <FeedbackCard data={item} handleCommentDelete={deleteFeedback} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
    </>
  )
}
