import { useEffect, useState } from 'react'

import useDeviceStore from 'store/deviceDetect.store'

import { CustomMetaTitle } from 'utils/customMetaTitle'

import { MobileLanguageTest } from './MobileLanguageTest'
import { DesktopLanguageTest } from './DesktopLanguageTest'

import './LanguageTest.scss'

import Grid from '@mui/material/Grid'

export const LanguageTest = (): JSX.Element => {
  const [startTest, setStartTest] = useState(false)
  const [startButtonStatus, setStartButtonStatus] = useState(false)
  const [nextStage, setnextStage] = useState(false)
  const [userState, setUserState] = useState({
    language: '',
    level: '',
  })

  const { isMobile } = useDeviceStore()

  useEffect(() => {
    window.scrollTo(0, 0)
    CustomMetaTitle('LS • Тестування')
  }, [])

  useEffect(() => {
    if (userState.language && userState.level) {
      setStartButtonStatus(false)
    } else {
      setStartButtonStatus(true)
    }
  }, [userState])

  const handleChange = (name: string, value: string): void => {
    if (userState[name] === value) {
      setUserState({ ...userState, [name]: '' })
    } else {
      setUserState({ ...userState, [name]: value })
    }
  }

  const handleStartTest = (): void => {
    if (userState.language !== '' && userState.level !== '') {
      setStartTest(true)
    }
  }

  const handleReadtUser = (): void => {
    setnextStage(true)
  }

  return (
    <Grid
      sx={{ textAlign: 'center', mt: '-16px', justifyContent: 'space-between' }}
      container
      flexWrap="nowrap"
      flexDirection={isMobile ? 'column' : 'row'}
    >
      {isMobile ? (
        <MobileLanguageTest
          nextStage={nextStage}
          startTest={startTest}
          handleReadtUser={handleReadtUser}
          handleChange={handleChange}
          handleStartTest={handleStartTest}
          language={userState.language}
          level={userState.level}
          startButtonStatus={startButtonStatus}
        />
      ) : (
        <DesktopLanguageTest
          startTest={startTest}
          handleChange={handleChange}
          handleStartTest={handleStartTest}
          language={userState.language}
          level={userState.level}
          startButtonStatus={startButtonStatus}
        />
      )}
    </Grid>
  )
}
