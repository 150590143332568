import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode } from 'swiper'

import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/pagination'

import './index.scss'

import { Avatar } from 'components/Avatar'
import { UploadImage } from 'components/UploadImage'

import { AvatarSliderPropTypes, SelectedAvatarPropTypes } from 'utils/types'

import Grid from '@mui/material/Grid'

export const AvatarSlider = ({
  uploadActive,
  selectedAvatar,
  avatarCollection,
  handleAddAvatar,
  handleUserAvatar,
  deleteUploadAvatar,
}: AvatarSliderPropTypes): JSX.Element => {
  return (
    <Grid sx={{ ml: { xs: '0px', md: '-20px' } }} width="100%">
      <Swiper
        freeMode={true}
        modules={[FreeMode]}
        className="mySwiper custom-slide"
        breakpoints={{
          320: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          400: {
            slidesPerView: 3.7,
            spaceBetween: 10,
          },
          540: {
            slidesPerView: 5,
          },
          700: {
            slidesPerView: 6,
          },
          765: {
            slidesPerView: 3,
          },
          900: {
            slidesPerView: 3.5,
          },
          950: {
            slidesPerView: 4,
          },
          1040: {
            slidesPerView: 4.5,
          },
          1150: {
            slidesPerView: 5,
          },
          1240: {
            slidesPerView: 5.5,
          },
          1440: {
            slidesPerView: 6,
          },
          1540: {
            slidesPerView: 6.5,
          },
          1630: {
            slidesPerView: 7,
          },
          1680: {
            slidesPerView: 7.8,
          },
          1750: {
            slidesPerView: 8.5,
          },
          2000: {
            slidesPerView: 9,
          },
        }}
      >
        <SwiperSlide className="custom-slide">
          <Grid sx={{ py: '5px', cursor: 'pointer' }}>
            <UploadImage
              handleAddAvatar={handleAddAvatar}
              active={uploadActive}
              deleteUploadAvatar={deleteUploadAvatar}
            />
          </Grid>
        </SwiperSlide>
        {Array.isArray(avatarCollection) &&
          avatarCollection.map(({ key, alt, _id }: SelectedAvatarPropTypes) => (
            <SwiperSlide key={_id} className="custom-profile-slide">
              <Avatar
                id={_id}
                url={key}
                alt={alt}
                active={selectedAvatar._id === _id && !!selectedAvatar}
                handleUserAvatar={handleUserAvatar}
              />
            </SwiperSlide>
          ))}
      </Swiper>
    </Grid>
  )
}
