import { AlertPropTypes } from 'utils/types'

import useDeviceStore from 'store/deviceDetect.store'

import './index.scss'
import none from 'assets/none.jpg'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

export const OopsAlert = ({ title, subTitle }: AlertPropTypes): JSX.Element => {
  const { isMobile } = useDeviceStore()

  return (
    <Grid container alignItems="center" flexDirection="column" justifyContent="center" textAlign="center">
      <img src={none} alt="none img" className="oops-image" />
      <Grid>
        <Typography
          variant="h3"
          fontSize={isMobile ? '24px' : '36px'}
          color="var(--main)"
          sx={{ mb: isMobile ? '7px' : '15px' }}
        >
          {title}
        </Typography>
        <Typography variant="h6" fontSize={isMobile ? '16px' : '22px'} color="var(--light-gray)">
          {subTitle}
        </Typography>
      </Grid>
    </Grid>
  )
}
