import useDeviceStore from 'store/deviceDetect.store'

import date from 'assets/HowToStartCoursesPage/date.jpg'
import sign from 'assets/HowToStartCoursesPage/sign.jpg'
import select from 'assets/HowToStartCoursesPage/select2.jpg'
import ckeckAvatar from 'assets/HowToStartCoursesPage/ckeckAvatar.jpg'

import './HowToStartCoursesPage.scss'
import { HowToStartTextContainer } from '../HowToStartStyles'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

export const HowToStartCoursesPage = (): JSX.Element => {
  const { isMobile } = useDeviceStore()

  return (
    <Grid container gap={{ xs: 4, md: 0 }} flexDirection="column">
      <Grid container gap={2} flexDirection="column" textAlign="center" sx={{ mb: { sx: 0, sm: 3 } }}>
        <Typography variant="h1">
          А далі все дуже<span style={{ color: 'var(--main)' }}> просто </span>
        </Typography>
        <Typography variant="h6">
          Ось покрокова інструкція, як приєднатися до нашої студентської ком‘юніті 🤩
        </Typography>
      </Grid>
      <Grid container gap={{ xs: 6, md: 0 }} flexDirection="column">
        <Grid
          container
          width="100%"
          alignItems="center"
          justifyContent="center"
          flexDirection={isMobile ? 'column' : 'row'}
          gap={isMobile ? 2 : 10}
          textAlign={isMobile ? 'center' : 'left'}
          flexWrap="nowrap"
        >
          <Grid>
            <img src={ckeckAvatar} alt="select" className="start-img" />
          </Grid>
          <Grid sx={HowToStartTextContainer} width={isMobile ? '100%' : '45%'}>
            <Grid container alignItems="flex-start" gap={1} flexDirection="column">
              <Typography variant="h3" color="var(--main)">
                • Оберіть підходящий для себе курс 🇺🇸 або 🇩🇪
              </Typography>
              <Typography variant="h6" color="var(--black)">
                чи то індивідуальні, чи групові заняття; для дорослого, або дитини; онлайн чи наживо в м.
                Ужгород
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          width="100%"
          alignItems="center"
          justifyContent="center"
          flexDirection={isMobile ? 'column' : 'row'}
          gap={isMobile ? 2 : 8}
          textAlign="left"
          flexWrap="nowrap"
        >
          {isMobile && (
            <Grid>
              <img src={select} alt="select" className="start-img" />
            </Grid>
          )}
          <Grid sx={HowToStartTextContainer} width={isMobile ? '100%' : '45%'}>
            <Grid container alignItems="flex-start" gap={1} flexDirection="column">
              <Typography variant="h3" color="var(--main)">
                • Вкажіть свій приблизний рівень або визначте свій рівень за допомогою нашого простого тесту
              </Typography>
              <Typography variant="h6" color="var(--black)">
                Оберіть його відповідно до міжнародних стандартів CEFR. А ще краще - визначте його за
                допомогою нашого простого тесту
              </Typography>
            </Grid>
          </Grid>
          {!isMobile && (
            <Grid>
              <img src={select} alt="create" className="start-img" />
            </Grid>
          )}
        </Grid>

        <Grid
          container
          width="100%"
          alignItems="center"
          justifyContent="center"
          flexDirection={isMobile ? 'column' : 'row'}
          gap={isMobile ? 2 : 12}
          textAlign={isMobile ? 'center' : 'left'}
          flexWrap="nowrap"
        >
          <Grid>
            <img src={sign} alt="create" className="start-img" />
          </Grid>
          <Grid sx={HowToStartTextContainer} width={isMobile ? '100%' : '45%'}>
            <Grid container alignItems="flex-start" gap={1} flexDirection="column">
              <Typography variant="h3" color="var(--main)">
                • Зареєструйтеся на нашому сайті
              </Typography>
              <Typography variant="h6" color="var(--black)">
                Щоб пройти тест для визначення рівня потрібно швиденько зареєструватися, щоб ми могли
                зв’язатися з вами для того, щоб провести усну частину тесту (максимум 10хв)
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          width="100%"
          alignItems="center"
          justifyContent="center"
          flexDirection={isMobile ? 'column' : 'row'}
          gap={isMobile ? 2 : 12}
          textAlign={isMobile ? 'center' : 'left'}
          flexWrap="nowrap"
        >
          {isMobile && (
            <Grid>
              <img src={date} alt="create" className="start-img" />
            </Grid>
          )}
          <Grid sx={HowToStartTextContainer} width={isMobile ? '100%' : '45%'}>
            <Grid container alignItems="flex-start" gap={1} flexDirection="column">
              <Typography variant="h3" color="var(--main)">
                • І на кінець ми домовляємося про найбільш підходящий варіант навчання та розклад
              </Typography>
              <Typography variant="h6" color="var(--black)">
                І ваша захоплююча подорож до вільного володіння іноземною розпочинається 🎊
              </Typography>
            </Grid>
          </Grid>
          {!isMobile && (
            <Grid>
              <img src={date} alt="create" className="start-img" />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}
