import { create } from 'zustand'

import { LoadingStorePropTypes } from '../utils/types'

const useStore = create<LoadingStorePropTypes>()((set) => ({
  loading: false,
  handleSetLoading: (loading: boolean) => set({ loading }),
}))

export default useStore
