import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { CourseSlider } from './CourseSlider'

import useDeviceStore from 'store/deviceDetect.store'

import courseApi from 'api/courseApi'
import ENDPOINTS from 'api/endpoints'

import { CourseTypeWrapper, CourseTypeButton } from './CourseTypeStyles'

import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

export const CourseType = (): JSX.Element => {
  const navigate = useNavigate()
  const { isMobile } = useDeviceStore()
  const {
    data: courseList,
    error,
    isSuccess,
  } = useQuery(ENDPOINTS.COURSES, () => courseApi.getListOfCourses())

  if (error) {
    console.log(error)
  }

  const handleRedirectUser = (): void => {
    navigate('courses')
  }

  return (
    <>
      {isSuccess && (
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          width="100%"
          sx={{ ...CourseTypeWrapper, px: { xs: '10px', sm: '0px' } }}
        >
          <Grid
            container
            justifyContent="center"
            sx={{ ...CourseTypeWrapper, px: { xs: '14px', sm: '0px' } }}
          >
            <Typography variant="h1">
              Ось наші
              <span style={{ color: 'var(--main)' }}> пропозиції </span>
            </Typography>
            <Grid sx={{ position: 'absolute', right: { md: '80px', lg: '100px' } }}>
              {!isMobile && (
                <Button variant="outlined" sx={CourseTypeButton} size="medium" onClick={handleRedirectUser}>
                  Хочу більше курсів
                </Button>
              )}
            </Grid>
          </Grid>
          <Grid width="100%">{<CourseSlider courseTypes={courseList.course} />}</Grid>
          {isMobile && (
            <Button variant="outlined" sx={CourseTypeButton} size="medium" onClick={handleRedirectUser}>
              Хочу більше курсів
            </Button>
          )}
        </Grid>
      )}
    </>
  )
}
