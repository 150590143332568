export const VisitUsWrapper = {
  backgroundColor: 'var(--black)',
  p: { xs: '50px 24px', sm: '50px 80px', md: '100px 100px' },
  gap: 5,
}

export const VisitUsWrapperMobile = {
  p: { xs: '50px 24px', sm: '50px 80px', md: '100px 100px' },
  gap: 5,
  backgroundColor: '#1c252c',
  opacity: 1,
  backgroundImage:
    'repeating-radial-gradient( circle at 0 0, transparent 0, #1c252b 50px ), repeating-linear-gradient( #232e3555, #232e35 )',
}
