import './index.scss'

import { Box, Grid, Typography } from '@mui/material'
import { FaStar } from 'react-icons/fa'

const lineColor: { [index: string]: string } = {
  Відмінно: '#62A155',
  Чудово: '#B0D351',
  Добре: '#F4E659',
  Задовільно: '#EBA843',
  Погано: '#DC4A29',
}

export const ChartLine = ({ width, label, rating, feedbacks }: any): JSX.Element => {
  return (
    <Grid container flexWrap="nowrap" alignItems="center" margin="1px 0px" gap={1}>
      <Box display="flex" width="35px" alignItems="center">
        <FaStar style={{ opacity: 0.1, padding: '0px 2px', margin: '-1px 2px 0px 0px' }} fontSize="inherit" />
        <Typography variant="h5" color="#515257" fontSize="15px">
          {rating}
        </Typography>
      </Box>
      <Grid container flexWrap="nowrap" gap="10px" alignItems="center">
        <Box className="chart-container">
          <Box
            className="filled-line"
            style={{ width: `${width === 0 ? 3.5 : width}%`, backgroundColor: lineColor[label] }}
          ></Box>
        </Box>
        <Typography variant="h5" color="#515257" fontSize="15px">
          {feedbacks.length}
        </Typography>
      </Grid>
    </Grid>
  )
}
