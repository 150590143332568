import { QuizCardProps } from 'utils/types'

import {
  QuizHeaderCard,
  QuizCardWrapper,
  UserAnswerButton,
  NextQuestionButton,
  QuizCardContentContainer,
} from './QuizCard'

import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Chip from '@mui/material/Chip'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'

export const QuizCard = ({
  questionNr,
  totalQuestions,
  question,
  answers,
  task,
  userAnswer,
  callback,
  nextQuestion,
  nextButton,
  endButton,
  modal,
  btnStatus,
  handleUserResult,
}: QuizCardProps): JSX.Element => {
  return (
    <Card sx={QuizCardWrapper}>
      <CardContent sx={QuizCardContentContainer}>
        <Grid container flexWrap="nowrap" gap={1}>
          <Grid>
            <Typography variant="h2">{questionNr}</Typography>
            <div
              style={{
                borderRadius: '10px',
                height: '3.5px',
                width: '35px',
                backgroundColor: 'var(--main)',
              }}
            ></div>
          </Grid>
          <Typography variant="h5" color="var(--light-gray)" sx={{ mt: '-5px' }}>
            з {totalQuestions}
          </Typography>
        </Grid>
        <Grid sx={QuizHeaderCard}>
          <Chip
            label={task}
            sx={{
              backgroundColor: 'var(--main)',
              color: 'var(--white)',
              width: '90%',
              fontSize: '16px',
              height: '36px',
              borderRadius: '25px',
            }}
          />
          <Typography variant="h6" fontWeight={500}>
            {question}
          </Typography>
        </Grid>
        {answers?.map((answer: any, index) => (
          <Button
            disabled={userAnswer ? true : false}
            value={answer}
            key={index}
            onClick={callback}
            variant="contained"
            sx={UserAnswerButton}
            style={{
              border: `1.2px
                     solid ${
                       userAnswer?.correctAnswer === answer
                         ? '#03bc7d'
                         : userAnswer?.answer === answer
                         ? 'red'
                         : '	#E0E0E0'
                     }`,
            }}
          >
            {answer}
          </Button>
        ))}
        {nextButton && (
          <Button
            onClick={nextQuestion}
            disabled={btnStatus ? false : true}
            sx={NextQuestionButton}
            variant="outlined"
          >
            Наступне питання
          </Button>
        )}
        {endButton && (
          <Button
            onClick={() => {
              handleUserResult()
              modal(true)
            }}
            sx={{ ...NextQuestionButton }}
            variant="outlined"
          >
            Закінчити тестування
          </Button>
        )}
      </CardContent>
    </Card>
  )
}
