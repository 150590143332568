import { useEffect, useState, MouseEvent, useCallback } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import * as Yup from 'yup'
import jwtDecode from 'jwt-decode'
import { useMutation } from 'react-query'
import { Formik, Form, Field } from 'formik'

import { PopUp } from 'components/PopUp'
import { EditField } from 'components/EditField'
import { SuccessWindow } from 'components/SuccessWindow'
import { InputError } from 'components/FormField/FormFieldStyles'

import popUpStore from 'store/modals.store'
import useLoaderStoreStore from 'store/loader.store'
import useDeviceStore from 'store/deviceDetect.store'
import useNotificationStore from 'store/notification.store'

import { setCookies } from 'utils/userService'
import { InputChangeEvent } from 'types/common'
import { GoogleAuth, UserAuth } from 'types/auth'
import { CustomMetaTitle } from 'utils/customMetaTitle'
import { googleId, PhoneRegExp } from 'utils/constants'

import userApi from 'api/userApi'

import authImg from 'assets/Registration/authBackImage.jpg'

import {
  LogInText,
  AuthButton,
  AuthWrapper,
  FormWrapper,
  AuthPhotoWrapper,
  AuthFieldsWrapper,
} from './AuthStyles'
import './Auth.scss'

import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Visibility from '@mui/icons-material/Visibility'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputAdornment from '@mui/material/InputAdornment'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'

const initialValues = {
  email: '',
  password: '',
  name: '',
  phone: null,
}

const googleInitialValues = {
  email: '',
  name: '',
}

const initialGoogleValues = {
  phone: null,
}

export const Auth = (): JSX.Element => {
  const navigate = useNavigate()
  const { handleSetShowPopUp } = popUpStore()
  const { handleSetLoading } = useLoaderStoreStore()
  const { handleSetMessage, handleSetBanner } = useNotificationStore()

  const { isMobile } = useDeviceStore()
  const { pathname } = useLocation()
  const id = pathname.split('/')[1]

  const [userReg, setUserReg] = useState(true)
  const [userAvatar, setUserAvatar] = useState('')
  const [credential, setCredential] = useState('')
  const [googleUserId, setGoogleUserId] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [userData, setUserData] = useState<UserAuth>(initialValues)
  const [userPhone, setUserPhone] = useState<any>(initialGoogleValues)
  const [googleData, setGoogleData] = useState<UserAuth>(googleInitialValues)

  useEffect(() => {
    id === 'sign-in' ? setUserReg(true) : setUserReg(false)
    setUserData(initialValues)
  }, [id])

  const { mutate: create } = useMutation(userApi.createUser, {
    onSuccess: () => {
      if (credential) {
        return onGoogleLogIn()
      }
      return onLogIn()
    },
    onError: ({ response }) => {
      handleSetBanner(true)
      setCredential('')
      handleSetMessage(response.data.message)
      handleSetLoading(false)

      handleSetShowPopUp(false)
    },
    onSettled: () => {
      handleSetShowPopUp(false)
    },
  })

  const { mutate: userLogin } = useMutation(userApi.userLogin, {
    onSuccess: ({ data }) => {
      setCookies(data)
      navigate('/')
      handleSetBanner(true)
      handleSetMessage(`Раді вас бачити, ${data.name} 👋`)
    },
    onError: ({ response }) => {
      handleSetBanner(true)
      handleSetMessage(response.data.message)
    },
    onSettled: () => {
      handleSetLoading(false)
      handleSetShowPopUp(false)
    },
  })

  const { mutate: googleLogin } = useMutation(userApi.googleLogin, {
    onSuccess: ({ data }) => {
      setCookies(data)
      navigate('/')
      handleSetBanner(true)
      handleSetMessage(`Раді вас бачити, ${data.name} 👋`)
    },
    onError: ({ response }) => {
      handleSetBanner(true)
      handleSetMessage(response.data.message)
    },
    onSettled: () => {
      setCredential('')
      handleSetShowPopUp(false)
      setUserData(initialValues)
      setGoogleData(googleInitialValues)
      handleSetLoading(false)
    },
  })

  const onSignUp = async () => {
    const { phone, ...rest } = userData
    handleSetLoading(true)
    create({ ...rest, phone: `38${phone}` })
  }

  const onGoogleSignUp = async () => {
    handleSetLoading(true)
    const { name, email } = googleData
    create({
      name,
      email,
      googleUserId,
      avatar: userAvatar,
      phone: `38${userPhone.phone}`,
    })
  }

  const onLogIn = async () => {
    handleSetLoading(true)
    userLogin({ email: userData.email, password: userData.password })
  }

  const handleCallbackResponse = useCallback((googleUserData: GoogleAuth): void => {
    document.getElementById('signInDiv')!.hidden = false
    const { name, email, picture, sub }: any = jwtDecode(googleUserData.credential)
    setCredential(googleUserData.credential)
    setGoogleData({ name, email })
    setUserAvatar(picture)
    setGoogleUserId(sub)
  }, [])

  const onGoogleLogIn = async () => {
    handleSetLoading(true)
    googleLogin(credential)
  }

  const handleUserStatus = (): void => {
    id === 'sign-in' ? navigate('/sign-up') : navigate('/sign-in')
    setUserData(initialValues)
  }

  const handlerChange = (event: InputChangeEvent): void => {
    event.preventDefault()
    const { name, value } = event.target
    setUserData({ ...userData, [name]: value })
  }

  const handleGooglePhoneChange = (event: InputChangeEvent): void => {
    const { value } = event.target
    setUserPhone({ phone: value })
  }

  const handleUserPushBack = (): void => {
    navigate('/')
  }

  useEffect(() => {
    if (userReg && credential) onGoogleLogIn()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credential])

  useEffect(() => {
    if (!userReg && credential) handleSetShowPopUp(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userReg, credential])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    /* global google */
    /*Global Google*/
    // @ts-ignore: Unreachable code error
    window.google.accounts.id.initialize({
      client_id: googleId,
      callback: handleCallbackResponse,
      referrerPolicy: {
        policy: 'strict-origin-when-cross-origin',
      },
    })
    // @ts-ignore: Unreachable code error
    window.google.accounts.id.renderButton(document.getElementById('signInDiv'), {
      referrerPolicy: {
        policy: 'strict-origin-when-cross-origin',
      },
    })
  }, [handleCallbackResponse])

  useEffect(() => {
    window.scrollTo(0, 0)
    CustomMetaTitle(`LS • ${!userReg ? 'Реєстрація' : 'Вхід'}`)
  }, [userReg])

  const validationSchema = Yup.object().shape({
    name: Yup.string().min(3, 'Довжина повинна бути більша 3 літер').required("Обов'язкове поле"),
    password: Yup.string()
      .required("Обов'язкове поле для заповнення")
      .min(8, 'Пароль має містити мінімум 8 символів')
      .matches(/[0-9]/, 'Пароль має містити хоча б 1 цифру')
      .matches(/[a-z]/, 'Пароль має містити хоча б 1 літеру з малої букви')
      .matches(/[A-Z]/, 'Пароль має містити хоча б 1 літеру з великої букви'),
    email: Yup.string().email('Невалідний email').required("Обов'язкове поле"),
    phone: Yup.string()
      .matches(PhoneRegExp, 'Невалідний номер телефону')
      .length(10, 'Довжина повинна дорівнювати 10 символам')
      .required("Обов'язкове поле")
      .nullable(),
  })

  const validationSchemaGoogle = Yup.object().shape({
    phone: Yup.string()
      .matches(PhoneRegExp, 'Невалідний номер телефону')
      .length(10, 'Довжина повинна дорівнювати 10 символам')
      .required("Обов'язкове поле")
      .nullable(),
  })

  const handleClickShowPassword = (): void => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault()
  }

  return (
    <Grid container sx={AuthWrapper}>
      <Grid container sx={AuthPhotoWrapper}>
        <img src={authImg} alt="registration" className="photo" />
      </Grid>
      <Grid container sx={FormWrapper}>
        {isMobile && (
          <Grid container justifyContent="flex-start">
            <button className="back-btn" onClick={handleUserPushBack}>
              <ChevronLeftIcon fontSize="medium" sx={{ color: 'gray' }} />
            </button>
          </Grid>
        )}

        <Grid
          width={{ xs: '100%', md: '80%' }}
          container
          flexDirection="column"
          gap={userReg ? 0 : 2}
          textAlign={{ xs: 'left', md: 'center' }}
        >
          <Typography variant="h2" color="var(--black)" lineHeight="30px">
            {!userReg ? 'Реєстрація' : 'Вхід в профіль.'}
          </Typography>

          <Typography variant="h6" color="var(--black)" lineHeight="30px">
            {!userReg && `Давайте знайомитись ближче 🤗 `}
          </Typography>
        </Grid>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSignUp}>
          {({ setFieldValue, setFieldTouched }) => (
            <Form
              style={{ width: '100%', display: 'flex', justifyContent: 'center', alignContent: 'center' }}
            >
              <Grid container sx={AuthFieldsWrapper}>
                {!userReg && (
                  <EditField
                    name="name"
                    label="Ім'я"
                    withLabel={false}
                    formValue={userData.name}
                    onChange={handlerChange}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                  />
                )}
                <EditField
                  name="email"
                  label="Email"
                  withLabel={false}
                  formValue={userData.email}
                  onChange={handlerChange}
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                />
                {!userReg && (
                  <EditField
                    name="phone"
                    type="tel"
                    withLabel={false}
                    label="Номер телефону"
                    formValue={userData.phone || ''}
                    onChange={handlerChange}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                  />
                )}
                <Field name="password">
                  {({ meta }) => (
                    <Grid sx={{ width: '100%', fontSize: '14px' }}>
                      <OutlinedInput
                        fullWidth
                        sx={{ color: !!meta.error && !!meta.touched && '#d32f2f' }}
                        type={showPassword ? 'text' : 'password'}
                        name="password"
                        placeholder="Пароль"
                        value={userData.password}
                        error={!!meta.error && !!meta.touched}
                        onBlur={() => setFieldTouched('password', true)}
                        onChange={(event: InputChangeEvent) => {
                          handlerChange(event)
                          setFieldValue('password', event.target.value)
                        }}
                        endAdornment={
                          <InputAdornment position="end" sx={{ p: '0px 8px' }}>
                            <IconButton
                              aria-label="password"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      <Grid sx={InputError}>{!!meta.error && !!meta.touched ? meta.error : ''}</Grid>
                    </Grid>
                  )}
                </Field>

                {userReg ? (
                  <Button variant="contained" sx={AuthButton} size="medium" type="button" onClick={onLogIn}>
                    Ввійти
                  </Button>
                ) : (
                  <Button variant="contained" sx={AuthButton} size="medium" type="submit">
                    Зареєструватись
                  </Button>
                )}
                <Grid width="100%" container flexDirection="column" gap={2}>
                  <Grid>
                    <Divider>
                      <Typography variant="body2" lineHeight="30px">
                        Або {userReg ? 'ввійти' : 'зареєструватись'} через
                      </Typography>
                    </Divider>
                  </Grid>
                  <div className="google-btn">
                    <Grid className="google-icon-wrapper">
                      <img
                        className="google-icon"
                        src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
                        alt="google-icon"
                      />
                    </Grid>
                    <p className="btn-text">
                      <b>{userReg ? 'Ввійти' : 'Зареєструватись'} через Google</b>
                    </p>
                    <button className="google-button" id="signInDiv"></button>
                  </div>
                </Grid>
                {!userReg && (
                  <Grid width="90%" sx={{ mt: '15px' }} textAlign="center">
                    <Typography variant="body2" color="var(--light-gray)">
                      Після натиснення кнопки "зареєструватись" ви погоджуєтесь з{' '}
                      <Link to="/terms-of-conditions" target="_blank" rel="noreferrer" className="link-style">
                        Terms of conditions
                      </Link>{' '}
                      та{' '}
                      <Link to="/privacy-policy" target="_blank" rel="noreferrer" className="link-style">
                        Privacy Policy
                      </Link>
                    </Typography>
                  </Grid>
                )}

                <Grid container gap={1} justifyContent="center">
                  <Typography variant="body2" fontWeight={500}>
                    {!userReg ? 'Вже є профіль?' : 'Нема профілю?'}
                  </Typography>
                  <Typography variant="body2" sx={LogInText} onClick={handleUserStatus}>
                    {!userReg ? 'Ввійти' : 'Зареєструватись'}
                  </Typography>
                </Grid>

                <Typography
                  variant="body2"
                  sx={LogInText}
                  fontWeight={500}
                  onClick={() => navigate('/reset-password')}
                >
                  {userReg && 'Забули пароль?'}
                </Typography>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>

      <PopUp>
        <SuccessWindow>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            gap="25px"
            width="100%"
          >
            <Typography variant="h5">Вкажіть свій номер мобільного телефону</Typography>
            <Formik
              initialValues={initialGoogleValues}
              validationSchema={validationSchemaGoogle}
              onSubmit={onGoogleSignUp}
            >
              {({ setFieldValue, setFieldTouched }) => (
                <Form
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <Grid container>
                    <EditField
                      name="phone"
                      type="tel"
                      withLabel={false}
                      label="Номер телефону"
                      formValue={userPhone.phone || ''}
                      onChange={handleGooglePhoneChange}
                      setFieldTouched={setFieldTouched}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                  <Button variant="contained" sx={AuthButton} size="medium" type="submit">
                    Зареєструватись
                  </Button>
                </Form>
              )}
            </Formik>
          </Grid>
        </SuccessWindow>
      </PopUp>
    </Grid>
  )
}
