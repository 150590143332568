const ENGLISH_LEVELS = {
  elementary: [
    { minScore: 0, maxScore: 15, name: 'Новачок' },
    { minScore: 16, maxScore: 24, name: 'Початківець А1' },
    { minScore: 25, maxScore: 35, name: 'Elementary А2' },
  ],
  intermediate: [
    { minScore: 0, maxScore: 10, name: 'Beginner A1' },
    { minScore: 11, maxScore: 20, name: 'Elementary A2' },
    { minScore: 21, maxScore: 34, name: 'Pre-Intermediate B1' },
    { minScore: 35, maxScore: 44, name: 'Intermediate B1+' },
    { minScore: 45, maxScore: 50, name: 'Upper-Intermediate B2' },
  ],
}

const GERMAN_LEVELS = {
  elementary: [
    { minScore: 0, maxScore: 14, name: 'Einstieg A1' },
    { minScore: 15, maxScore: 20, name: 'Grundlagen A2' },
  ],
}

export const getUserLevel = (language: string, level: string, score: number): string => {
  const levels = language === 'eng' ? ENGLISH_LEVELS : GERMAN_LEVELS
  if (!levels[level]) return 'Новачок'
  const levelData = levels[level]

  for (const levelInfo of levelData) {
    if (score >= levelInfo.minScore && score <= levelInfo.maxScore) {
      return levelInfo.name
    }
  }
}
