import { useState } from 'react'
import { Autoplay } from 'swiper'
import { FreeMode, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { FeedbacksListData } from 'types/feedbacks'
import { FeedbackCard } from '../FeedbackCard'

import './FeedbackSlider.scss'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css'

export const FeedbackSlider = ({ feedbackData, handleCommentDelete }: any): JSX.Element => {
  const [swiperIndex, setSwiperIndex] = useState(null)

  return (
    <>
      {Array.isArray(feedbackData) && (
        <Swiper
          slidesPerView={3}
          navigation={true}
          freeMode={true}
          onSnapIndexChange={({ activeIndex }) => setSwiperIndex(activeIndex)}
          spaceBetween={20}
          onSwiper={setSwiperIndex}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            480: {
              slidesPerView: 1.2,
            },
            550: {
              slidesPerView: 1.4,
              spaceBetween: 20,
            },
            640: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            720: {
              slidesPerView: 1.5,
              spaceBetween: 20,
            },
            860: {
              slidesPerView: 1.7,
              spaceBetween: 20,
            },
            920: {
              slidesPerView: 1.7,
              spaceBetween: 20,
            },
            1100: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1200: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1350: {
              slidesPerView: 3,
            },
            1500: {
              slidesPerView: 3.3,
            },
            1650: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
          }}
          autoplay={{
            delay: 6000,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, FreeMode, Navigation]}
          className="mySwiper swiper-feedbacks"
        >
          {feedbackData.map((item: FeedbacksListData, index: number) => (
            <SwiperSlide key={index}>
              <FeedbackCard
                data={item}
                handleCommentDelete={handleCommentDelete}
                activeSlide={swiperIndex}
                index={index}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </>
  )
}
