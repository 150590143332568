export const DesktopTestButton = {
  backgroundColor: 'var(--black)',
  p: '14px 45px',
  color: 'white',
  fontSize: '15px',
  mt: '20px',
  width: { md: '80%', lg: '60%' },
  border: 'none',

  '&:disabled': {
    backgroundColor: 'white',
  },

  '&:hover': {
    backgroundColor: 'black',
    border: 'none',
  },
}

export const DesktopLeftSideWrapper = {
  backgroundColor: 'var(--main-light)',
  p: { md: '0px 60px 60px', lg: '0px 80px 80px' },
  width: { md: '50%', lg: '55%' },
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexDirection: 'column',
}

export const DesktopRightSideWrapper = {
  p: { md: '50px 60px', lg: '70px 80px' },
  width: { md: '50%', lg: '45%' },
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
}

export const DesktopCardWrapper = {
  height: '75vh',
  width: { md: '35%', lg: '30%' },
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}
